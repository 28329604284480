import React from 'react'
import { FormattedMessage } from 'react-intl'

import Title from '../../components/basics/title'
import GestorList from '../../components/gestor-list'
import Search from '../../components/search'

import { Wrapper } from './styles.js'

const Home = props => (
  <>
    <Search {...props} />
    <Wrapper>
      <Title>
        <FormattedMessage id={'index.latestReleases'} />
      </Title>
    </Wrapper>
    <GestorList {...props} />
  </>
)

export default Home
