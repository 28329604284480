import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const MenuWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 30px;
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .active {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`

export const NavLinkStyled = styled(NavLink)`
  && {
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
`
