import english from '../../intl/en'
import spanish from '../../intl/es'
import deutsch from '../../intl/de'
import french from '../../intl/fr'
// import { setLang, getLang } from 'util';

const setIntl = (lang = 'en') => {
  switch (lang) {
    case 'es':
      return spanish
    case 'en':
      return english
    case 'de':
      return deutsch
    case 'fr':
      return french
    default:
      return english
  }
}

export const initialState = {
  lang: 'en',
  messages: setIntl('en')
}

export const locale = (state = initialState, action) => {
  switch (action.type) {
    case 'LOCALE/SPANISH':
      return {
        lang: 'es',
        messages: spanish
      }
    case 'LOCALE/ENGLISH':
      return {
        lang: 'en',
        messages: english
      }
    case 'LOCALE/DEUTSCH':
      return {
        lang: 'de',
        messages: deutsch
      }
    case 'LOCALE/FRENCH':
      return {
        lang: 'fr',
        messages: french
      }

    default:
      return state
  }
}
