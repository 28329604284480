import styled from 'styled-components'

import Button from '../../components/button'
import { Link } from 'react-router-dom'

export const ButtonStyled = styled(Button)`
  &&{
    margin: 0;
    padding: 0;
    text-transform: none 
    font-size: 15px;
    font-weight: normal;
    &:hover{
      background: none
    }
  }
}`

export const LinkStyled = styled(Link)`
  li {
    color: ${({ theme }) => theme.colors.white};
  }
  &&.admin {
    text-decoration: none;
  }
`
