import styled from 'styled-components'

export const Container = styled.div`
  @media (min-width: 900px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ImgContainer = styled.div``

export const ImgStyled = styled.img`

max-width: 100%
max-height: 100%;
`

export const ThumbContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const WrapperContainer = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 600px;
`

export const New = styled.div`
  position: absolute;
  top: 6px;
  left: 0px;
`

export const SpanNew = styled.span`
  background: ${({ theme }) => theme.colors.colorTekaRed};
  padding: 5px 10px 5px 10px;
  color: #fff;
`

export const WrapperProp = styled.div`
  margin: 20px 0
  display: flex;
`

export const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  min-width: 30%;
`

export const WrapperDescription = styled.div`
  min-width: 70%;
`
export const Label = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 5px 0;
`
