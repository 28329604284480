const initialState = {
  isFetching: false,
  roles: []
}

export const Roles = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'ROLES/DATA_REQUEST':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'ROLES/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        roles: []
      }

    // success
    case 'ROLES/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        roles: action.roles
      }
    default:
      return state
  }
}
