import { request } from '../../../utils/request'

const getNotificationsRequest = () => ({ type: 'NOTIFICATIONS/DATA_REQUEST' })
const getNotificationsSuccess = notifications => ({
  type: 'NOTIFICATIONS/DATA_SUCCESS',
  notifications
})
const getNotificationsFailure = error => ({
  type: 'NOTIFICATIONS/DATA_FAILURE',
  error
})

export const getNotifications = () => {
  return dispatch => {
    dispatch(getNotificationsRequest())
    // Notifications
    const requestOptions = {
      method: 'GET'
    }

    return request(`notifications`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getNotificationsFailure(res.error || res.message))
          return false
        }
        dispatch(getNotificationsSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getNotificationsFailure(err))
      })
  }
}

const deleteNotificationRequest = () => ({ type: 'NOTIFICATION/DATA_REQUEST_DELETE' })
const deleteNotificationSuccess = notifications => ({
  type: 'NOTIFICATION/DATA_SUCCESS_DELETE',
  notifications
})
const deleteNotificationFailure = error => ({
  type: 'NOTIFICATION/DATA_FAILURE_DELETE',
  error
})

export const deleteNotifications = id => {
  return dispatch => {
    dispatch(deleteNotificationRequest())
    // Notifications-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`notifications`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteNotificationFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteNotificationSuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteNotificationFailure(err))
      })
  }
}
export const removeError = () => ({
  type: 'NOTIFICATION/REMOVE_ERROR'
})
