import styled from 'styled-components'

export const FooterContainer = styled.div`
  position: absolute;
  height: 30px;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  height: 130px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
`

export const Bloq = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: ${props => (props.right ? 'flex-end' : 'flex-start')};
  margin: 0 80px;
`
export const Text = styled.span`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14')};
  color: ${({ theme, color }) => theme.colors[color]};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.fontWeights.bold : theme.fontWeights.medium};
`
