const initialState = {
  isFetching: false,
  notifications: [],
  notification: null
}

export const Notifications = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'NOTIFICATIONS/DATA_REQUEST':
    case 'NOTIFICATION/DATA_REQUEST_DELETE':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'NOTIFICATIONS/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        notifications: []
      }

    case 'NOTIFICATION/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        notification: null,
        errorModal: action.error
      }
    }

    // success
    case 'NOTIFICATIONS/DATA_SUCCESS':
    case 'NOTIFICATION/DATA_SUCCESS_DELETE':
      return {
        ...state,
        isFetching: false,
        notifications: action.notifications,
        error: null
      }
    case 'NOTIFICATION/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
