const initialState = {
  isFetching: false,
  categories: [],
  category: null
}

export const Categories = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'CATEGORIES/DATA_REQUEST':
    case 'CATEGORY/DATA_REQUEST_DELETE':
    case 'CATEGORY_ID/DATA_REQUEST':
    case 'CATEGORY/DATA_REQUEST_EDIT':
    case 'CATEGORY/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'CATEGORIES/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        categories: []
      }

    case 'CATEGORY_ID/DATA_FAILURE':
    case 'CATEGORY/DATA_FAILURE_EDIT':
    case 'CATEGORY/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        category: null,
        error: action.error
      }
    }

    case 'CATEGORY/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        category: null,
        errorModal: action.error
      }
    }

    // success
    case 'CATEGORY_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        category: action.category,
        error: null
      }
    case 'CATEGORIES/DATA_SUCCESS':
    case 'CATEGORY/DATA_SUCCESS_DELETE':
    case 'CATEGORY/DATA_SUCCESS_EDIT':
    case 'CATEGORY/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        categories: action.categories,
        error: null
      }
    case 'CATEGORY/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
