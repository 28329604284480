const initialState = {
  data: {}
}

export const Search = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH/SET_DATA_SEARCH':
      return {
        ...state,
        data: {
          ...state.data,
          query: action.query
        }
      }
    case 'FILTER/SET_FILTER': {
      return {
        ...state,
        data: {
          ...state.data,
          filter: action.data
        }
      }
    }
    case 'LIST/GET_DOCUEMENTS_BY_CATEGORY': {
      return {
        ...state,
        data: {
          ...state.data,
          filter: {
            ...state.data.filter,
            category_id: action.category
          }
        }
      }
    }
    case 'FILTER/RESET_QUERY_FILTER': {
      return {
        ...state,
        data: {
          ...state.data,
          query: null
        }
      }
    }
    case '@@router/LOCATION_CHANGE': {
      if (
        action.payload.location.pathname.indexOf('list') === -1 &&
        action.payload.location.pathname.indexOf('file-card') === -1 &&
        action.payload.location.pathname.indexOf('results') === -1 &&
        action.payload.location.pathname.indexOf('upload') === -1
      ) {
        return {
          ...state,
          data: {}
        }
      }
      return {
        ...state
      }
    }
    case 'FILTER/REMOVE_FILTER': {
      return {
        ...state,
        data: {}
      }
    }
    default:
      return state
  }
}
