import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import { addUser, getUsers, removeError } from '../../../../modules/admin/users'
import {
  getCountries,
  getCountriesByCountry
} from '../../../../modules/admin/countries'
import { getRoles, getRolesByRole } from '../../../../modules/roles'
import { goToRoute } from '../../../../modules/navigation'
import Button from '../../../../components/button'
import BackButton from '../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../components/form'
import ErrorSpan from '../../../../components/errorSpan'

import {
  IconVisibilityBlack,
  IconVisibilityOffBlack
} from '../../../../assets/img/index'

import { Form, Title, Icon, WrapperButton } from './styles'
import getError from '../../../../utils/errors'

class AddUser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      showPassword: false,
      isSubmit: false,
      user: {
        language: 'en',
        password: Math.random()
          .toString(36)
          .slice(-8)
      }
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.getData = this.getData.bind(this)
    this.changeData = this.changeData.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  componentDidMount () {
    this.props.getCountries()
    this.props.getUsers()
    this.props.getRoles()
    this.props.removeError()
  }

  changeData (e, data) {
    const { user } = this.state
    user[data] = e.target.value
    if (user[data]) {
      this.setState({
        user
      })
    }
  }

  handleClickShowPassword () {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.user.name &&
      this.state.user.surname &&
      this.state.user.email &&
      this.state.user.region_id &&
      this.state.user.username &&
      this.state.user.role &&
      this.state.user.password
    ) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.addUser(this.state.user)
    }
  }

  getData (datas) {
    if (this.props[datas]) {
      const newCountries = []
      newCountries.push({
        value: 0,
        label: '...select'
      })
      this.props[datas].forEach(data => {
        newCountries.push({
          value: data.id,
          label: data.name
        })
      })
      return newCountries
    }
  }

  render () {
    const { user, isSubmit } = this.state
    const { intl, error } = this.props
    return (
      <Form onSubmit={this.onSubmit}>
        <Flex column style={{ textAlign: 'center' }}>
          <Box mb={2} w={1}>
            <Title>
              <FormattedMessage id={'admin.users.add'} />
            </Title>
          </Box>

          {error ? (
            <ErrorSpan>{intl.formatMessage({ id: getError(error) })}</ErrorSpan>
          ) : (
            ''
          )}

          <WrapperButton>
            <BackButton
              to='/admin/users'
              text={intl.formatMessage({ id: 'admin.back' })}
            />
          </WrapperButton>
          <Flex justify='center' style={{ textAlign: 'left' }}>
            <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
              <Box mb={3} w={1}>
                <FormBoxInput
                  label='Name *'
                  onChange={event => this.changeData(event, 'name')}
                  placeholder='Ex. Juan'
                  type='text'
                  error={!user.name && isSubmit}
                  helperText={
                    !user.name && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxInput
                  label='Surname *'
                  onChange={event => this.changeData(event, 'surname')}
                  placeholder='Ex. García'
                  type='text'
                  error={!user.surname && isSubmit}
                  helperText={
                    !user.surname && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxInput
                  label='Email *'
                  onChange={event => this.changeData(event, 'email')}
                  placeholder='Ex. juan.garcia@teka.com'
                  type='email'
                  error={!user.email && isSubmit}
                  helperText={
                    !user.email && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  label='Countries *'
                  options={this.getData('countries')}
                  onChange={event => this.changeData(event, 'region_id')}
                  required
                  error={!user.region_id && isSubmit}
                />
              </Box>
              <Box mb={3}>
                <FormBoxInput
                  label='Username *'
                  autoComplete={'new-user'}
                  onChange={event => this.changeData(event, 'username')}
                  placeholder='Ex. juangar'
                  type='text'
                  error={!user.username && isSubmit}
                  helperText={
                    !user.username && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  label='Roles *'
                  options={this.getData('roles')}
                  onChange={event => this.changeData(event, 'role')}
                  required
                  error={!user.role && isSubmit}
                />
              </Box>
              <Box mb={3}>
                <FormBoxInput
                  label='Password'
                  onChange={event => this.changeData(event, 'password')}
                  defaultValue={this.state.user.password}
                  placeholder='Ex. 123df34sa567'
                  autoComplete={'new-password'}
                  type={this.state.showPassword ? 'text' : 'password'}
                  error={!user.password && isSubmit}
                  helperText={
                    !user.password && isSubmit ? 'El campo es obligatorio' : ''
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Toggle password visibility'
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Icon src={IconVisibilityOffBlack} />
                        ) : (
                          <Icon src={IconVisibilityBlack} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Flex justify='space-between'>
                <Button
                  secundary
                  onClick={() => this.props.goToRoute(`/admin/users`)}
                  padding='5px 40px'
                >
                  <FormattedMessage id={'admin.cancel'} />
                </Button>
                <Button primary='true' type='submit' padding='5px 40px'>
                  <FormattedMessage id={'admin.submit'} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    )
  }
}

export default connect(
  state => ({
    user: state.userReducer.user,
    users: state.usersReducer.users,
    countries: getCountriesByCountry(state),
    roles: getRolesByRole(state),
    isFetching: state.usersReducer.isFetching,
    error: state.usersReducer.error
  }),
  { addUser, getCountries, getRoles, goToRoute, getUsers, removeError }
)(injectIntl(AddUser))
