import styled from 'styled-components'
export const ThumbStyle = styled.div`
overflow: hidden;
background-size: cover;
background-position: center;
background-image: url("${({ src }) => src}");
width: 100%;
height: 100%;
`

export const WrapperThumb = styled.div`
  height: ${({ fullsize }) => (fullsize ? '100%' : '85px')};
  width: ${({ fullsize }) => (fullsize ? '100%' : '85px')};
  margin: ${({ fullsize }) => (fullsize ? '0' : '5px;')};
  background: white;
`
