import React from 'react'
import { ReactPlayerStyled } from './styles'
class Video extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <div style={{ width: '100%', height: '100%', paddingTop: '50px' }}>
        <ReactPlayerStyled
          url={this.props.src}
          controls
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    )
  }
}

export default Video
