import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { getCountries, deleteCountry } from '../../../modules/admin/countries'
import { goToRoute, openDialogTable } from '../../../modules/navigation'
import Table from '../../../components/table'
import Button from '../../../components/button'
import getColumns from '../../../models/countries'
import { IconPlus } from '../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'

class Countries extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getCountries()
  }
  render () {
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.countries' />
        </Title>
        <WrapperButton>
          <Button
            primary='true'
            onClick={() => this.props.goToRoute(`/admin/country/add`)}
            padding='7px 10px'
          >
            <Img src={IconPlus} />
            <FormattedMessage id={'admin.add.countries'} />
          </Button>
        </WrapperButton>
        <Table
          data={this.props.countries}
          columns={getColumns(
            this.props.deleteCountry,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          isFetching={this.props.isFetching}
          minWidth='400px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    countries: state.countriesReducer.countries,
    isFetching: state.countriesReducer.isFetching
  }),
  { getCountries, deleteCountry, goToRoute, openDialogTable }
)(injectIntl(Countries))
