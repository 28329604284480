import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Container,
  Form,
  TextFieldStyled,
  Span,
  ButtonStyled,
  ErrorSpanStyled,
  SpanLink,
  OKSpan
} from './styles'
import { login, forgetPassword, removeError } from '../../modules/user'
import getError from '../../utils/errors'
import { FormattedMessage, injectIntl } from 'react-intl'
class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      loginType: 0
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    this.props.removeError()
  }

  onSubmit (e) {
    e.preventDefault()
    if (this.state.loginType === 0) {
      const body = {
        username: this.state.username,
        password: this.state.password
      }
      this.props.login(body)
    } else if (this.state.loginType === 1) {
      this.props.forgetPassword(this.state.email)
    }
  }

  _handleTextFieldChange (field, e) {
    const state = this.state
    state[field] = e.target.value
    this.setState(state)
  }

  getError () {
    const { intl } = this.props
    const error = this.props.errorLogin
    const errorText = getError(error)

    return errorText ? intl.formatMessage({ id: errorText }) : ''
  }

  changeLoginType (loginType) {
    this.props.removeError()

    this.setState({ loginType })
  }

  render () {
    const { intl } = this.props
    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          <Span>
            <FormattedMessage
              id={this.state.loginType === 0 ? 'login.title' : 'forget.title'}
            />
          </Span>
          <ErrorSpanStyled>
            {this.props.errorLogin ? this.getError() : null}
          </ErrorSpanStyled>
          <OKSpan>
            {this.props.sendMail ? (
              <FormattedMessage id='login.mailSend' />
            ) : null}{' '}
          </OKSpan>

          {this.state.loginType === 0 ? (
            <Fragment>
              <TextFieldStyled
                id='outlined-name'
                label={intl.formatMessage({
                  id: 'login.input.username.placeholder'
                })}
                onChange={this._handleTextFieldChange.bind(this, 'username')}
                ref={username => {
                  this.username = username
                }}
                margin='normal'
                autoFocus
                value={this.state.username}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                required
              />
              <TextFieldStyled
                id='outlined-password'
                label={intl.formatMessage({
                  id: 'login.input.password.placeholder'
                })}
                ref={password => {
                  this.password = password
                }}
                type='password'
                value={this.state.password}
                onChange={this._handleTextFieldChange.bind(this, 'password')}
                margin='normal'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                required
              />
            </Fragment>
          ) : (
            <TextFieldStyled
              id='outlined-name'
              label={intl.formatMessage({
                id: 'login.input.email.placeholder'
              })}
              onChange={this._handleTextFieldChange.bind(this, 'email')}
              ref={email => {
                this.email = email
              }}
              margin='normal'
              value={this.state.email}
              variant='outlined'
            />
          )}

          {this.props.sendMail ? null : (
            <ButtonStyled type='submit'>
              <FormattedMessage
                id={
                  this.state.loginType === 0
                    ? 'login.button.submit.text'
                    : 'forget.button.submit.text'
                }
              />
            </ButtonStyled>
          )}
          <SpanLink
            onClick={() => {
              this.changeLoginType(this.state.loginType === 1 ? 0 : 1)
            }}
          >
            <FormattedMessage
              id={
                this.state.loginType === 0
                  ? 'login.link.forgot.text'
                  : 'login.link.login.text'
              }
            />{' '}
          </SpanLink>
        </Form>
      </Container>
    )
  }
}
export default withRouter(
  connect(
    state => ({
      user: state.userReducer.user,
      sendMail: state.userReducer.sendMail,
      firstLoadFinished: state.userReducer.isFinished,
      errorLogin: state.userReducer.error
    }),
    { login, forgetPassword, removeError }
  )(injectIntl(Login))
)
