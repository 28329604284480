import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  @media (min-width: 900px) {
    width: 50%;
    margin-left: 10%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const Label = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 5px 0;
`
export const NameProduct = styled(Label)`
  font-size: ${({ theme }) => theme.sizes.XL};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  display: block;
`
export const WrapperProp = styled.div`
  margin: 20px 0
  display: flex;
`

export const WrapperLabel = styled.div`
  min-width: 30%;
`

export const WrapperDescription = styled.div`
  min-width: 70%;
`

export const WrapperName = styled.div`
  margin-bottom: 30px;
`
export const Brand = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primaryColor};
  text-transform: uppercase;
  font-size: 18px;
  margin: 15px 0;
`

export const Ref = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.grey};
`
export const Code = styled.span`
  color: ${({ theme }) => theme.colors.black};
`

export const WrapperInfoProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const WrapperIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
  margin: 15px 0;
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  margin: 2px;
  img {
    width: 40px;
    height: 40px;
  }
`

export const ContainerIcon = styled.div`
  margin-left: 10px;

`

export const SpecWrapper = styled.div`
  display: flex;
`

export const Spec = styled.span`
  color: ${({ theme }) => theme.colors.superDarkGrey};
  margin-right: 15px;
  font-size: 14px;
`

export const Tag = styled.span`
  margin: 0 3px
  border-radius: 25px;
  padding: 4px 6px;
  background: ${({ theme }) => theme.colors.lightGrey};
`

export const InfoField = styled.div`
  margin: 10px 0;
  height: 56px;
  background: ${({ theme }) => theme.colors.warningBackground};
  color: ${({ theme }) => theme.colors.warningText};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  padding: 0px 16px;
`

export const CircularProgressStyled = styled(CircularProgress)`
  circle {
    color: ${({ theme }) => theme.colors.warningText};
  }
`
