import React from 'react'
import { Checkbox, Separator } from './styles'
import { Flex, Box } from 'reflexbox'

function compare (a, b) {
  if (a.value < b.value) { return -1 }
  if (a.value > b.value) { return 1 }
  return 0
}

export default ({ options, setOptions }) => {
  return <Box mt={1} style={{ overflowY: 'scroll', height: '30vh' }}>
    <Flex align='center'>
      <Checkbox color='default' value='all' onChange={(e, _checked) => {
        setOptions([
          ...options.map(o => ({
            ...o,
            checked: _checked
          }))
        ])
      }} />
      Check all
    </Flex>
    <Separator />
    <Flex column>
      {options && options.sort(compare).map(o => <Flex align='center' key={o.value}>
        <Checkbox color='default' checked={o.checked || false} onChange={(e, _checked) => {
          setOptions([
            ...options.filter(o2 => o2.value !== o.value),
            {
              ...o,
              checked: _checked
            }
          ])
        }} /> {o.label}
      </Flex>)}
    </Flex>
  </Box>
}
