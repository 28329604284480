import styled from 'styled-components'
import Button from '../../../components/button'
import { NavLink } from 'react-router-dom'

export const ButtonStyled = styled(Button)`
  &&{
    color: ${({ theme }) => theme.colors.white};
    text-transform: none 
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap;
    &:hover{
      background: none
    }
  }
}`

export const WrapperMenuItems = styled.div`
  background: blue;
  width: 100%;
  height: 100%;
`

export const SecondaryMenuItems = styled.li`
  position: relative;
  text-align: right;
  display: inline;
  color: ${({ theme }) => theme.colors.white};
`

export const PrimaryMenuItems = styled(SecondaryMenuItems)`
  &.headerItem {
    color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid #2E2E2E;
    padding: 0 30px;
  }
  &.headerFirstItem {
    border-left: 1px solid #2E2E2E;
  }
  ${({ disabled }) => {
    if (disabled) {
      return `opacity: 0.3; pointer-events: none;`
    }
    return ``
  }}

  height: 55px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`

export const NavLinkStyled = styled(NavLink)`
  text-align: center;
`
