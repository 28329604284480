import React from 'react'
import SimpleDialog from '../simpleDialog'
import { injectIntl, FormattedMessage } from 'react-intl'
import { history } from '../../store'
class PreventLeave extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: false,
      targetLocation: null
    }
  }
  componentDidMount () {
    this.unblock = history.block(targetLocation => {
      if (this.props.check && this.props.check()) {
        return true
      }
      this.setState({
        modalOpen: true,
        targetLocation: targetLocation.pathname
      })
      return false
    })
  }

  componentWillUnmount () {
    this.unblock()
  }
  render () {
    const intl = this.props.intl
    return (
      <SimpleDialog
        handleClose={() => {
          this.setState({ modalOpen: false })
        }}
        title={intl.formatMessage({ id: this.props.idIntlTitle })}
        handleOk={async () => {
          this.setState({ modalOpen: false })
          this.unblock()
          if (this.props.onOk) {
            await this.props.onOk()
          }
          history.push(this.state.targetLocation)
          // hacemos el reject
        }}
        textHandleOk={'Ok'}
        open={this.state.modalOpen}
      >
        <FormattedMessage id={this.props.idIntlText} />
      </SimpleDialog>
    )
    // component render here
  }
}

export default injectIntl(PreventLeave)
