import React from 'react'
import ChipInput from 'material-ui-chip-input'
import { Flex, Box } from 'reflexbox'
import { colors } from '../../theme'

export default ({ label, value, setValue }) => {
  return <Flex column>
    <Box>
      <span style={{ fontWeight: 'bold' }}>{label}</span>
    </Box>
    <Box mb={8}>
      <span style={{ fontSize: 14, color: colors.grey }}>Add multiple reference numbers separated by a space.</span>
    </Box>
    <ChipInput
      value={value}
      fullWidth
      fullWidthInput
      newChipKeyCodes={[13, 32]}
      blurBehavior='add'
      variant='outlined'
      onAdd={(chip) => setValue([
        ...value,
        chip
      ])}
      onDelete={(chip, index) => setValue(value.filter(v => v !== chip))}
    />
  </Flex>
}
