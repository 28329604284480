import React from 'react'
import { Span } from './styles'
import Select from '../../components/select'
import TextField from '../../components/textfield'
import CheckboxSelector from '../../components/checkboxGroup'

export const FormBoxSelect = props => (
  <>
    <Span bold>{props.label || 'Which brand is this document for?'}</Span>
    <Select
      {...{ ...props, label: null }}
      error={props.error}
      required={props.required}
      disabled={props.disabled}
    />
  </>
)

export const FormBoxInput = props => (
  <>
    <Span bold>{props.label || 'Which brand is this document for?'}</Span>
    <TextField
      {...{ ...props, label: null }}
      error={props.error}
      helperText={props.helperText}
      required={props.required}
    />
  </>
)

export const FormBoxCheckboxGroup = props => (
  <>
    <Span bold>{props.label || 'Language of the document'}</Span>
    <CheckboxSelector />
  </>
)
