import React from 'react'
import IButton from '../components/iconbutton'
import { IconVisibilityBlack, DeleteIcon } from '../assets/img/index'
import { Link } from 'react-router-dom'
import { Img, Wrapper } from './styles'

const getColumns = (deleteNotifications, goToRoute, openModal) => [
  {
    name: 'id',
    label: 'id',
    options: {
      sortDirection: 'desc'
    }
  },
  {
    name: 'sender',
    label: 'Email'
  },
  {
    name: 'document',
    label: 'Document uploaded'
  },
  {
    name: 'brand',
    label: 'Brand'
  },
  {
    name: 'public',
    label: 'Document Status',
    options: {
      filter: true,
      customBodyRender: (value, tableMeta) => {
        if (value === true) {
          return <p> Public </p>
        }
        return <p> No Approved </p>
      }
    }
  },
  {
    name: 'upload_date.date',
    label: 'Uploaded date',
    options: {
      filter: true,
      customBodyRender: (value, tableMeta) => {
        var date = value
        return <p> {new Date(date).toLocaleDateString()} </p>
      }
    }
  },
  {
    name: 'edit',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (tableMeta.rowData !== undefined) {
          return (
            <Wrapper>
              <Link to={`/upload/${tableMeta.rowData[0]}`}>
                <IButton>
                  <Img src={IconVisibilityBlack} alt='view' />
                </IButton>
              </Link>

              <IButton
                onClick={() => {
                  openModal(
                    true,
                    () => {
                      deleteNotifications(tableMeta.rowData[0])
                    },
                    `${tableMeta.rowData[2]} notification`
                  )
                }}
              >
                <Img src={DeleteIcon} alt='delete' />
              </IButton>
            </Wrapper>
          )
        }
        return null
      }
    }
  }
]
export default getColumns
