import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import { Spinner } from '../../../../../components/spinner'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import {
  getTypeById,
  editType,
  removeError
} from '../../../../../modules/admin/content/types'
import { getSubcategories } from '../../../../../modules/admin/content/subcategories'
import { getBrands } from '../../../../../modules/admin/brands'
import { getCategories } from '../../../../../modules/admin/content/categories'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class EditType extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      typec: null,
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.getOptionsBrands = this.getOptionsBrands.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getTypeId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getTypeById(this.getTypeId())
    this.props.getBrands()
    this.props.getCategories()
    this.props.getSubcategories()
    this.props.removeError()
  }

  setBrand () {
    const typec = this.state.typec
    if (typec) {
      const categoryId = typec.category.id
      const categoryFind = this.props.categories.find(x => x.id === categoryId)
      if (categoryFind) {
        this.setState({ brand: categoryFind.brand.id })
      }
    }
  }

  componentDidUpdate () {
    const {
      isFetching,
      typec,
      isFetchingCategories,
      categories,
      isFetchingSubcategories,
      subcategories
    } = this.props
    if (
      !this.state.typec &&
      typec &&
      subcategories &&
      categories &&
      (!isFetching && !isFetchingSubcategories && !isFetchingCategories)
    ) {
      this.setState({ typec }, () => {
        this.setBrand()
      })
    }
  }

  changeName (e) {
    const { typec } = this.state
    typec.name = e.target.value
    this.setState({
      typec
    })
  }

  changeBrand (e, value) {
    this.setState({
      brand: value
    })
  }

  getOptionsBrands () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  changeSelect (e, value, element) {
    const { typec } = this.state
    typec[element] = { id: value }
    this.setState({
      typec
    })
  }

  getOptions (elements) {
    if (this.props[elements]) {
      const newOptions = []
      let type = ''
      newOptions.push({
        value: 0,
        label: '...select'
      })
      type = elements === 'categories' ? 'brand' : 'category'
      this.props[elements]
        .filter(x =>
          type === 'brand'
            ? x[type].id === this.state.brand
            : x[type].id ===
              (this.state.typec.category && this.state.typec.category.id)
        )
        .forEach(e => {
          newOptions.push({
            value: e.id,
            label: e.name
          })
        })
      return newOptions
    }
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.typec.name &&
      this.state.typec.category.id &&
      this.state.typec.subcategory.id
    ) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editType(this.state.typec)
    }
  }

  render () {
    const { typec, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (typec !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.type.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/content/types'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {type.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={typec.name}
                    onChange={this.changeName}
                    placeholder='Ex. Logos'
                    type='text'
                    error={!typec.name && isSubmit}
                    helperText={
                      !typec.name && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptionsBrands()}
                    value={this.state.brand}
                    onChange={this.changeBrand}
                    required
                    label='Brand *'
                    error={!this.state.brand && isSubmit}
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions('categories')}
                    defaultValue={typec.category.id}
                    onChange={(e, value) =>
                      this.changeSelect(e, value, 'category')
                    }
                    required
                    label='Category *'
                    error={!typec.category.id && isSubmit}
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions('subcategories')}
                    defaultValue={typec.subcategory.id}
                    onChange={(e, value) =>
                      this.changeSelect(e, value, 'subcategory')
                    }
                    required
                    label='Subcategory *'
                    error={!typec.subcategory.id && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/content/types`)}
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay tipos seleccionados</p>
  }
}

export default connect(
  state => ({
    typec: state.typesReducer.typec,
    subcategories: state.subcategoriesReducer.subcategories,
    categories: state.categoriesReducer.categories,
    isFetchingCategories: state.categoriesReducer.isFetching,
    isFetchingSubcategories: state.subcategoriesReducer.isFetching,
    isFetching: state.typesReducer.isFetching,
    brands: state.brandsReducer.brands,
    error: state.typesReducer.error
  }),
  {
    getTypeById,
    editType,
    goToRoute,
    getSubcategories,
    getCategories,
    getBrands,
    removeError
  }
)(injectIntl(EditType))
