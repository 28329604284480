import React from 'react'
import { NoImage } from '../../../assets/img'
class ImageLazy extends React.Component {
  render () {
    const props = this.props
    const image = props.item.main_image || NoImage
    return (
      <div
        style={{ width: '100%', height: '100%' }}
        id={`wrapper_canvas_${props.id}`}
      >
        <img src={process.env.REACT_APP_ROOT_URL + '' + image} />
      </div>
    )
  }
}

export default ImageLazy
