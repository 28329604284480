export const LogoTeka = require('./logo.svg')
export const SearchIcon = require('./icon-search.svg')
export const SearchIconBlack = require('./icon-search-black.svg')
export const UploadIcon = require('./icon-upload.svg')
export const CloudUploadIcon = require('./icon-cloud-upload.svg')
export const UserIcon = require('./icon-user.svg')
export const EditIcon = require('./icon-edit.svg')
export const DeleteIcon = require('./icon-delete.svg')
export const DescriptionIcon = require('./icon-description.svg')
export const MovieIcon = require('./icon-movie.svg')
export const PhotoIcon = require('./icon-photo.svg')
export const PdfIcon = require('./icon-pdf.svg')
export const ZipIcon = require('./icon-zip.svg')
export const AddAvatar = require('./icon-addphoto.svg')
export const LogoNew = require('./LogoTekaNew.svg')
export const IconTick = require('./icon-tick.svg')
export const IconTickBig = require('./icon-tick-big.svg')
export const IconTickBlack = require('./icon-tick-black.svg')
export const IconReset = require('./icon-reset.svg')
export const IconNotifications = require('./icon-notifications.svg')
export const IconCopy = require('./icon-copy.svg')
export const IconVisibilityOff = require('./icon-visibility-off.svg')
export const IconVisibility = require('./icon-visibility.svg')
export const IconVisibilityBlack = require('./icon-visibility-black.svg')
export const IconVisibilityOffBlack = require('./icon-visibility-off-black.svg')
export const IconDelete = require('./icon-delete.svg')
export const NoImage = require('./teka-no-image-placeholder.png')
export const NoVideo = require('./video-not-found.svg')
export const NoDocument = require('./document-not-found.svg')
export const IconPlus = require('./icon-plus-white.svg')
export const IconArrow = require('./icon-arrow-left-red.svg')
export const IconArrowBlack = require('./icon-arrow-black.svg')
export const IconArrowRight = require('./arrow-drch.svg')
export const IconArrowLeft = require('./arrow-izq.svg')
export const IconInfo = require('./icon-info.svg')
export const IconInfoRed = require('./icon-info-red.svg')
export const IconShare = require('./icon-share.svg')
export const LockIcon = require('./icon-lock.svg')
export const HashtagImage = require('./hashtag.png')
