const initialState = {
  isFetching: false,
  dashboard: []
}

export const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'DASHBOARD/DATA_REQUEST':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'DASHBOARD/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        dashboard: []
      }

    // success
    case 'DASHBOARD/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        dashboard: action.dashboard
      }
    default:
      return state
  }
}
