import styled from 'styled-components'
import Button from '../../../components/button'

export const Title = styled.span`
  text-align: center;
  font-size: 2rem;
  margin: 0;
`

export const Subtitle = styled(Title)`
  text-align: center;
  font-weight: bold;
  margin: 0;
`

export const ButtonStyled = styled(Button)``
