import React, { Fragment } from 'react'
import {
  Wrapper,
  SelectStyled,
  WrapperSelect,
  Span,
  ButtonStyled,
  DateSelect
} from './styles'
import { injectIntl } from 'react-intl'
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import { connect } from 'react-redux'
import { setFilterData } from '../../modules/search'
import { getBrands } from '../../modules/admin/brands'
import { getCategories } from '../../modules/admin/content/categories'
import { getTypes } from '../../modules/admin/content/types'
import MenuOverlay from './date-range-menu'
import { selectFieldsAllows } from '../../modules/list'
import { getSubcategoriesByCategoryRequest } from '../../modules/admin/content/subcategories'
import { IconReset } from '../../assets/img'
import lang from '../../utils/languages'
class Filters extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {}
    }
    this.resetFilters = this.resetFilters.bind(this)
    this.handleDateRangeModal = this.handleDateRangeModal.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleApplyDateRange = this.handleApplyDateRange.bind(this)
    this.getCross = this.getCross.bind(this)
  }

  search (e) {
    e.preventDefault()
    this.props.setDataSearch(this.state.search)
  }
  _handleTextFieldChange (e) {
    const search = e.target.value
    this.setState({ search })
  }

  componentDidMount () {
    this.props.getBrands()
    this.props.getCategories()

    this.props.getTypes()
    this.setState({ data: this.props.data || {} })
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.category &&
      this.props.category !== this.state.data.category_id
    ) {
      this.setCategory()
      this.props.getSubcategoriesByCategoryRequest(this.props.category)
    }
    if (prevProps.category !== this.props.category) {
      const state = this.state
      state.data['subcategory_id'] = 0
      this.setState({ state })
      this.props.setFilterData(state.data)
    }
    if (prevProps.query !== this.props.query) {
      this.resetFilters()
    }
  }

  setCategory () {
    const currentCategory = this.props.category
    const state = this.state
    state.data['category_id'] = currentCategory
    this.setState({ state })
  }

  onChange (field, event, value) {
    const state = this.state
    state.data[field] = value
    if (field === 'category_id') {
      state.data['subcategory_id'] = 0
    } else if (field === 'brand_id') {
      state.data['category_id'] = 0
    }

    if (field === 'date') {
      state.data['category_id'] = 0
      state.data['subcategory_id'] = 0
      state.data['brand_id'] = 0
      state.data['typeId'] = 0
      state.data['language_id'] = 0
    }
    this.setState({ state })
    this.props.setFilterData(state.data)
  }

  getBrandsOptions () {
    const fieldsAllow = this.props.fieldsAllow
    const brands = this.props.brands
    return brands
      .filter(x => fieldsAllow['brand'].includes(x.id))
      .map(brand => {
        return { value: brand.id, label: brand.name }
      })
  }

  getCategoriesOptions () {
    const fieldsAllow = this.props.fieldsAllow
    const currentBrand = this.state.data.brand_id || ''
    const categories = this.props.categories
    return categories
      .filter(
        x =>
          x.brand.id.toString() === currentBrand.toString() &&
          fieldsAllow['category'].includes(x.id)
      )
      .map(category => {
        return { value: category.id, label: category.name }
      })
  }

  getSubcategoriesOptions (id) {
    const subCategories = this.props.subCategories
    return subCategories
      .map(brand => {
        return { value: brand.id, label: brand.name }
      })
  }

  getTypesOptions () {
    const fieldsAllow = this.props.fieldsAllow
    const types = this.props.types
    const currentTypes =
      this.state.data.subcategory_id || ''
    return types
      .filter(
        x =>
          x.subcategory.id.toString() === currentTypes.toString() && fieldsAllow['type'].includes(x.id)
      )
      .map(type => {
        return { value: type.id, label: type.name }
      })
  }

  resetFilters () {
    this.date.__wrappedInstance.resetFilter()
    this.setState({ data: {} })
    this.props.setFilterData({})
  }

  handleDateRangeModal (e) {
    this.setState({ dateRangeModal: true })
  }

  handleClose () {
    this.setState({ dateRangeModal: false })
  }

  handleApplyDateRange (date) {
    this.handleClose()
    this.onChange('date', null, date)
  }

  getCurrentDate () {
    const dateSelected = this.state.data.date
    if (dateSelected && dateSelected.from && dateSelected.to) {
      return `From ${new Date(
        dateSelected.from
      ).toLocaleDateString()} to ${new Date(
        dateSelected.to
      ).toLocaleDateString()}`
    }
    return null
  }

  getCross (data) {
    var properties = Object.keys(data)
    let existPropertyValue = false
    if (Object.keys(data).length === 0) {
      existPropertyValue = false
    } else {
      existPropertyValue = properties.find(param => {
        if (data[param]) {
          return true
        }
      })
    }

    if (existPropertyValue) {
      return (
        <img
          alt='reset'
          src={IconReset}
          onClick={this.resetFilters}
          style={{ marginLeft: 'auto' }}
        />
      )
    }
    return ''
  }
  render () {
    const { intl, fieldsAllow } = this.props
    const { data } = this.state

    return (
      <Wrapper>
        <Span>Filters</Span>
        <DateSelect>
          <ButtonStyled size='small' onClick={this.handleDateRangeModal}>
            {this.getCurrentDate() ||
              intl.formatMessage({ id: 'filters.datRange' })}
            <CalendarIcon style={{ marginLeft: 10, width: 15 }} />
          </ButtonStyled>

          <MenuOverlay
            ref={e => {
              this.date = e
            }}
            datesAllowed={fieldsAllow['date']}
            handleClose={this.handleClose}
            handleOk={this.handleApplyDateRange}
            open={this.state.dateRangeModal}
          />
        </DateSelect>
        {!this.props.categoryPage ? (
          <WrapperSelect>
            <SelectStyled
              value={data && data['brand_id']}
              onChange={this.onChange.bind(this, 'brand_id')}
              options={this.getBrandsOptions()}
              placeholder={intl.formatMessage({ id: 'filters.brands' })}
              ref={e => {
                this.brand = e
              }}
            />
          </WrapperSelect>
        ) : null}
        <Fragment>
          {/* {!this.props.categoryPage ? (
            <WrapperSelect>
              <SelectStyled
                value={data && data.type}
                onChange={this.onChange.bind(this, 'type')}
                options={[
                  { value: 'video', label: 'Video' },
                  { value: 'image', label: 'Image' },
                  { value: 'doc', label: 'Document' }
                ].filter(x => fieldsAllow['format'].includes(x.value))}
                placeholder={intl.formatMessage({ id: 'filters.formats' })}
                ref={e => {
                  this.type = e
                }}
              />
            </WrapperSelect>
          ) : null} */}
          <WrapperSelect>
            <SelectStyled
              value={data && data.language_id}
              onChange={this.onChange.bind(this, 'language_id')}
              options={lang.filter(x =>
                fieldsAllow['language'].includes(x.value)
              )}
              placeholder={intl.formatMessage({ id: 'filters.languages' })}
              ref={e => {
                this.language = e
              }}
            />
          </WrapperSelect>
          {!this.props.categoryPage ? (
            <WrapperSelect>
              <SelectStyled
                value={data && data.category_id}
                onChange={this.onChange.bind(this, 'category_id')}
                options={this.getCategoriesOptions()}
                placeholder={intl.formatMessage({ id: 'filters.categories' })}
                disabled={
                  !this.state.data.brand_id || this.state.data.brand_id === 0
                }
                ref={e => {
                  this.category = e
                }}
              />
            </WrapperSelect>
          ) : null}
          <WrapperSelect>
            <SelectStyled
              value={data && data.subcategory_id}
              onChange={this.onChange.bind(this, 'subcategory_id')}
              options={this.getSubcategoriesOptions(data.category_id)}
              placeholder={intl.formatMessage({
                id: 'filters.subCategories'
              })}
              disabled={
                !this.state.data.category_id ||
                this.state.data.category_id === 0
              }
              ref={e => {
                this.subCategory = e
              }}
            />
          </WrapperSelect>
          <WrapperSelect>
            <SelectStyled
              value={data && data.typeId}
              onChange={this.onChange.bind(this, 'typeId')}
              options={this.getTypesOptions()}
              placeholder={intl.formatMessage({
                id: 'filters.types'
              })}
              disabled={
                !this.state.data.subcategory_id ||
                this.state.data.subcategory_id === 0
              }
              ref={e => {
                this.types = e
              }}
            />
          </WrapperSelect>
          {this.getCross(data)}
        </Fragment>
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    brands: state.brandsReducer.brands,
    selectedBrand: state.brandsReducer.selectedBrand,
    categories: state.categoriesReducer.categories,
    category: state.listReducer.category,
    subCategories: state.subcategoriesReducer.subcategories,
    types: state.typesReducer.types,
    data: state.searchReducer.data && state.searchReducer.data.filter,
    fieldsAllow: selectFieldsAllows(state)
  }),
  { setFilterData, getBrands, getCategories, getSubcategoriesByCategoryRequest, getTypes }
)(injectIntl(Filters))
