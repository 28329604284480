import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  getHashtags,
  deleteHashtag,
  getFrattenHashtags
} from '../../../../modules/admin/content/hashtags'
import { goToRoute, openDialogTable } from '../../../../modules/navigation'
import Table from '../../../../components/table'
import Button from '../../../../components/button'
import getColumns from '../../../../models/content/hashtags'
import { IconPlus } from '../../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'

class Hashtags extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getHashtags()
  }

  render () {
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.content.hashtags' />
        </Title>
        <WrapperButton>
          <Button
            primary='true'
            onClick={() => this.props.goToRoute(`/admin/content/hashtag/add`)}
            padding='7px 10px'
          >
            <Img src={IconPlus} />
            <FormattedMessage id={'admin.add.hashtags'} />
          </Button>
        </WrapperButton>
        <Table
          data={this.props.hashtags}
          columns={getColumns(
            this.props.deleteHashtag,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          isFetching={this.props.isFetching}
          minWidth='450px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  (state) => ({
    hashtags: getFrattenHashtags(state),
    isFetching: state.hashtagsReducer.isFetching
  }),
  { getHashtags, deleteHashtag, goToRoute, openDialogTable }
)(injectIntl(Hashtags))
