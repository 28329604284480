import React from 'react'
import { connect } from 'react-redux'

import UnsubscribePage from './pageunsubscribe'
import ResubscribePage from './pageresubscribe'

const Unsubscribe = props => {
  return props.newsletter ? <UnsubscribePage /> : <ResubscribePage />
}

export default connect(
  state => ({
    newsletter: state.usersReducer.newsletter
  }),
  {}
)(Unsubscribe)
