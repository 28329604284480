import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import { addSubcategory, removeError } from '../../../../../modules/admin/content/subcategories'
import { getCategories } from '../../../../../modules/admin/content/categories'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class Addsubcategory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      subcategory: {},
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
  }
  componentDidMount () {
    this.props.getCategories()
    this.props.removeError()
  }
  changeName (e) {
    const { subcategory } = this.state
    subcategory.name = e.target.value
    this.setState({
      subcategory
    })
  }
  changeSelect (e, value) {
    const { subcategory } = this.state
    subcategory.category_id = value
    this.setState({
      subcategory
    })
  }

  chekedFiles () {
    let validate = false
    if (this.state.subcategory.name && this.state.subcategory.category_id) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.addSubcategory(this.state.subcategory)
    }
  }
  changeBrand (e, value) {
    this.setState({
      brand: value
    })
  }
  getOptionsBrands () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  getOptions () {
    if (this.props.categories) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.categories
        .filter(x => x.brand.id === this.state.brand)
        .forEach(category => {
          newOptions.push({
            value: category.id,
            label: category.name
          })
        })
      return newOptions
    }
  }
  render () {
    const { subcategory, isSubmit } = this.state
    const { intl, error } = this.props
    return (
      <Form onSubmit={this.onSubmit}>
        <Flex column style={{ textAlign: 'center' }}>
          <Title>
            <FormattedMessage id={'admin.subarea.add'} />
          </Title>
          {error ? (
            <ErrorSpan>{intl.formatMessage({ id: getError(error) })}</ErrorSpan>
          ) : (
            ''
          )}
          <WrapperButton>
            <BackButton
              to='/admin/content/subareas'
              text={intl.formatMessage({ id: 'admin.back' })}
            />
          </WrapperButton>
          <Flex justify='center' style={{ textAlign: 'left' }}>
            <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
              <Box mb={3} w={1}>
                <FormBoxInput
                  label='Name *'
                  onChange={this.changeName}
                  placeholder='Ex. Logos'
                  type='text'
                  error={!subcategory.name && isSubmit}
                  helperText={
                    !subcategory.name && isSubmit
                      ? 'El campo es obligatorio'
                      : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  options={this.getOptionsBrands()}
                  value={this.state.brand}
                  onChange={this.changeBrand}
                  required
                  label='Brand *'
                  error={!subcategory.brand_id && isSubmit}
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  options={this.getOptions()}
                  onChange={this.changeSelect}
                  required
                  label='Category *'
                  error={!subcategory.category_id && isSubmit}
                />
              </Box>
              <Flex justify='space-between'>
                <Button
                  secundary
                  onClick={() =>
                    this.props.goToRoute(`/admin/content/subareas`)
                  }
                  padding='5px 40px'
                >
                  <FormattedMessage id={'admin.cancel'} />
                </Button>
                <Button primary='true' type='submit' padding='5px 40px'>
                  <FormattedMessage id={'admin.submit'} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    )
  }
}

export default connect(
  state => ({
    subcategory: state.subcategoriesReducer.subcategory,
    categories: state.categoriesReducer.categories,
    isFetching: state.subcategoriesReducer.isFetching,
    brands: state.brandsReducer.brands,
    error: state.brandsReducer.error
  }),
  { addSubcategory, getCategories, goToRoute, removeError }
)(injectIntl(Addsubcategory))
