import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'

import { ButtonStyled, LinkStyled } from './styles'

class Menulist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.logout = this.logout.bind(this)
    this.adminZoneAccess = this.adminZoneAccess.bind(this)
  }

  handleToggle () {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose (event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  logout (e) {
    this.props.logout()
    this.handleClose(e)
  }

  adminZoneAccess (role) {
    if (role === 'ROLE_ADMIN_GEN') {
      return (
        <LinkStyled to='/admin' className='admin'>
          <MenuItem onClick={this.handleClose}>
            <FormattedMessage id={'menuList.admin'} />
          </MenuItem>
        </LinkStyled>
      )
    } else if (role === 'ROLE_ADMIN_REG') {
      return (
        <LinkStyled to='/admin/users'>
          <MenuItem onClick={this.handleClose}>
            <FormattedMessage id={'menuList.admin'} />
          </MenuItem>
        </LinkStyled>
      )
    } else {
      return null
    }
  }

  render () {
    const { open } = this.state
    const { user } = this.props
    if (user !== null) {
      return (
        <Fragment>
          <ButtonStyled
            buttonRef={node => {
              this.anchorEl = node
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={this.handleToggle}
            {...this.props}
          >
            {this.props.children}
            <ArrowIcon />
          </ButtonStyled>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id='menu-list-grow'
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList
                      style={{ background: '#383838', borderRadius: '4px' }}
                    >
                      <LinkStyled to='/user/edit' className='admin'>
                        <MenuItem onClick={this.handleClose}>
                          <FormattedMessage id={'menuList.account'} />
                        </MenuItem>
                      </LinkStyled>
                      {this.adminZoneAccess(user && user.roles[0])}
                      <MenuItem
                        onClick={this.logout}
                        style={{ color: 'white' }}
                      >
                        <FormattedMessage id={'menuList.logout'} />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Fragment>
      )
    }
    return null
  }
}

Menulist.propTypes = {
  padding: PropTypes.string
}

export default connect(
  state => ({
    user: state.userReducer.user,
    isFetching: state.userReducer.isFetching
  }),
  {}
)(injectIntl(Menulist))
