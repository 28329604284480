import React from 'react'
import { SearchIcon, UploadIcon, UserIcon } from '../../../assets/img'
import Menulist from '../../../components/menulist'
import IButton from '../../../components/iconbutton'
import { goToRoute } from '../../../modules/navigation'
import { Icon } from './styles'
import { connect } from 'react-redux'
import { SecondaryMenuList, PrimaryMenuItems, BottonMenu } from '../styles'

const SecondaryMenu = props => (
  <BottonMenu>
    <SecondaryMenuList>
      <PrimaryMenuItems>
        <IButton
          onClick={e => {
            props.goToRoute('/search')
          }}
          padding='0px'
          classes={{ root: 'reverse' }}
        >
          <Icon src={SearchIcon} />
        </IButton>
      </PrimaryMenuItems>
      <PrimaryMenuItems>
        <IButton
          onClick={e => {
            props.goToRoute('/upload')
          }}
          padding='0px'
          classes={{ root: 'reverse' }}
        >
          <Icon src={UploadIcon} />
        </IButton>
      </PrimaryMenuItems>
      <PrimaryMenuItems>
        <Menulist logout={props.logout} padding='0px'>
          <Icon src={UserIcon} />
        </Menulist>
      </PrimaryMenuItems>
    </SecondaryMenuList>
  </BottonMenu>
)

export default connect(
  state => ({}),
  { goToRoute }
)(SecondaryMenu)
