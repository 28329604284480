import { push } from 'react-router-redux'
const dataSearch = query => ({ type: 'SEARCH/SET_DATA_SEARCH', query })
const dataFilter = data => ({ type: 'FILTER/SET_FILTER', data })

export const resetQueryFilter = () => ({ type: 'FILTER/RESET_QUERY_FILTER' })

export const removeFilter = () => ({ type: 'FILTER/REMOVE_FILTER' })

export const setDataSearch = query => {
  return (dispatch, getState) => {
    dispatch(dataSearch(query))
    const category = getState().listReducer.category
    if (!category) {
      dispatch(push(`/results`))
    } else {
      dispatch(push(`/list/category/${category}`))
    }
  }
}
export const setFilterData = data => {
  return function (dispatch, getState) {
    dispatch(dataFilter(data))
    const category = getState().listReducer.category
    if (!category) {
      dispatch(push(`/results`))
    } else {
      dispatch(push(`/list/category/${category}`))
    }
  }
}
