import React from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Category,
  Title,
  WrapperCategories,
  PartTitle,
  WrapperTitle
} from './styles'
import PropTypes from 'prop-types'

import { removeFilter } from '../../../modules/search'

const PRODUCT_LIST_URL = '/list/category/'

const renderCategories = (categories, selectedBrand) => {
  const res = []
  categories &&
    selectedBrand &&
    categories
      .filter(x => x.brand.id === selectedBrand.id)
      .forEach(category => {
        res.push(
          <div key={`category_${category.id}`}>
            <Category
              to={`${PRODUCT_LIST_URL}${category.id}`}
              onClick={() => this.props.removeFilter()}
            >
              {category.name}
            </Category>
          </div>
        )
      })
  return res
}
const Categories = props => (
  <Container>
    <WrapperTitle>
      <PartTitle>Contents in</PartTitle>
      <Title>{props.selectedBrand.name}</Title>
    </WrapperTitle>
    <WrapperCategories>
      {renderCategories(props.categories, props.selectedBrand)}
    </WrapperCategories>
  </Container>
)

Categories.propTypes = {
  padding: PropTypes.string
}

export default connect(state => ({

}),
{ removeFilter }
)(Categories)
