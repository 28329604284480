import React from 'react'
import IButton from '../components/iconbutton'
import { EditIcon, DeleteIcon } from '../assets/img/index'
import { Link } from 'react-router-dom'
import { Img, Icon, Wrapper } from './styles'

const { REACT_APP_ROOT_URL } = process.env

const getColumns = (deleteBrands, goToRoute, openModal) => [
  {
    name: 'id',
    label: 'id',
    options: {
      sortDirection: 'desc'
    }
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'icon',
    label: 'ICon',
    options: {
      customBodyRender: value => {
        return <Icon src={`${REACT_APP_ROOT_URL}${value}`} alt='icon brand' />
      }
    }
  },
  {
    name: 'edit',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (tableMeta.rowData !== undefined) {
          return (
            <Wrapper>
              <Link to={`/admin/brand/edit/${tableMeta.rowData[0]}`}>
                <IButton>
                  <Img src={EditIcon} alt='edit' />
                </IButton>
              </Link>

              <IButton
                onClick={() => {
                  openModal(
                    true,
                    () => {
                      deleteBrands(tableMeta.rowData[0])
                    },
                    tableMeta.rowData[1]
                  )
                }}
              >
                <Img src={DeleteIcon} alt='delete' />
              </IButton>
            </Wrapper>
          )
        }
        return null
      }
    }
  }
]
export default getColumns
