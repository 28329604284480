import styled from 'styled-components'

export const Container = styled.div`
  @media (min-width: 900px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

export const ImgContainer = styled.div``

export const ImgStyled = styled.img`

max-width: 100%
max-height: 100%;
`

export const ThumbContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
`

export const WrapperContainer = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 600px;
`

export const New = styled.div`
  position: absolute;
  top: 6px;
  left: 0px;
`

export const SpanNew = styled.span`
  background: ${({ theme }) => theme.colors.colorTekaRed};
  padding: 6.5px 21.5px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`
