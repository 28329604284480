import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Title = styled.h1`
  text-align: left;
  margin: 0;
  margin-bottom: 40px;
`
export const Notifications = styled.div`
  display: flex
  text-align: left;
  padding: 20px 15px 15px 15px;
  margin: 0;
  margin-bottom: 25px;
  max-width: 325px;
  color: #C7102D;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 20px;
  border: 1px solid #C7102D;
  border-radius: 4px;
  background-color: rgba(199,16,45,0.05);
`

export const Text = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
`

export const Icon = styled.img`
  margin: 0;
  padding: 0;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`

export const IconNotification = styled.img`
  margin: 0;
  padding: 0;
  :hover {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  display: flex
  text-align: left;
  margin: 0;
  padding: 15px 0;
  border-top: 1px solid #E6E6E6;
`

export const Content = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-weight: bold;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
`
