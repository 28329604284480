import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '../../components/button'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  text-align: left;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
`

export const TextFieldStyled = styled(TextField)`
  width: 100%;
`

export const ButtonStyled = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryColor};
    :hover {
      background-color: ${({ theme }) => theme.colors.primaryColor};
    }
    text-transform: none;
    width: 100px;
    margin-left: 20px;
  }
`

export const Span = styled.span`
  ${({ bold, theme }) => bold && `font-weight ${theme.fontWeights.bold}`}
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
`

export const CheckboxStyled = styled(Checkbox)``
