import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  getNotifications,
  deleteNotifications,
  getFrattenNotifications,
  removeError
} from '../../../modules/admin/notifications'
import { goToRoute, openDialogTable } from '../../../modules/navigation'
import Table from '../../../components/table'
import ErrorSpan from '../../../components/errorSpan'
import getColumns from '../../../models/notifications'
import getError from '../../../utils/errors'

import { Title, Wrapper } from './styles'

class Notifications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getNotifications()
    this.props.removeError()
  }

  render () {
    const { intl, error } = this.props
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.notifications' />
        </Title>
        {error ? (
          <ErrorSpan>{intl.formatMessage({ id: getError(error) })} </ErrorSpan>
        ) : (
          ''
        )}
        <Table
          data={this.props.notifications}
          columns={getColumns(
            this.props.deleteNotifications,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          isFetching={this.props.isFetching}
          minWidth='1025px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    notifications: getFrattenNotifications(state),
    isFetching: state.notificationsReducer.isFetching,
    error: state.notificationsReducer.error
  }),
  { getNotifications, deleteNotifications, goToRoute, openDialogTable, removeError }
)(injectIntl(Notifications))
