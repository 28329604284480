import React from 'react'
import IButton from '../components/iconbutton'
import { EditIcon, DeleteIcon, LockIcon } from '../assets/img/index'
import { Link } from 'react-router-dom'

import { Img, Wrapper } from './styles'

const getColumns = (deleteUser, goToRoute, openModal, forgetPassword) => [
  {
    name: 'id',
    label: 'id',
    options: {
      sortDirection: 'desc'
    }
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'surname',
    label: 'Surname'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'username',
    label: 'Username'
  },
  {
    name: 'role',
    label: 'Role'
  },
  {
    name: 'region.name',
    label: 'Country'
  },
  {
    name: 'edit',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (tableMeta.rowData !== undefined) {
          return (
            <Wrapper>
              <Link to={`/admin/user/edit/${tableMeta.rowData[0]}`}>
                <IButton>
                  <Img src={EditIcon} alt='edit' />
                </IButton>
              </Link>
              <IButton
                onClick={() => {
                  openModal(
                    true,
                    () => {
                      forgetPassword(tableMeta.rowData[3])
                    },
                    tableMeta.rowData[3],
                    true
                  )
                }}
              >
                <Img src={LockIcon} alt='delete' />
              </IButton>
              <IButton
                onClick={() => {
                  openModal(
                    true,
                    () => {
                      deleteUser(tableMeta.rowData[0])
                    },
                    tableMeta.rowData[1],
                    false
                  )
                }}
              >
                <Img src={DeleteIcon} alt='delete' />
              </IButton>
            </Wrapper>
          )
        }
        return null
      }
    }
  }

]
export default getColumns
