import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import RouterBrands from '../../routes/routerbrands'
import RouterContentList from '../../routes/routercontentlist'
import RouterCategories from '../../routes/routercategories'
import RouterSubcategories from '../../routes/routersubcategories'
import RouterTypes from '../../routes/routertypes'
import RouterHashtags from '../../routes/routerhashtags'
import RouterCountries from '../../routes/routercountries'
import RouterUsers from '../../routes/routerusers'
import Notifications from './notifications'
import Dashboard from './dashboard'

class Admin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <RouterUsers />
        <RouterBrands />
        <RouterContentList />
        <RouterCategories />
        <RouterSubcategories />
        <RouterTypes />
        <RouterHashtags />
        <RouterCountries />
        <Route
          path={`${this.props.match.path}/notifications`}
          component={Notifications}
        />
        <Route exact path='/admin' component={Dashboard} />
      </Fragment>
    )
  }
}

export default withRouter(Admin)
