const initialState = {
  isFetching: false,
  subcategories: [],
  subcategory: null
}

export const Subcategories = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'SUBCATEGORIES/DATA_REQUEST':
    case 'SUBCATEGORIESBYCATEGORY/DATA_REQUEST':
    case 'SUBCATEGORY/DATA_REQUEST_DELETE':
    case 'SUBCATEGORY_ID/DATA_REQUEST':
    case 'SUBCATEGORY/DATA_REQUEST_EDIT':
    case 'SUBCATEGORY/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'SUBCATEGORIES/DATA_FAILURE':
    case 'SUBCATEGORIESBYCATEGORY/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        subcategories: []
      }

    case 'SUBCATEGORY_ID/DATA_FAILURE':
    case 'SUBCATEGORY/DATA_FAILURE_EDIT':
    case 'SUBCATEGORY/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        subcategory: null,
        error: action.error
      }
    }

    case 'SUBCATEGORY/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        subcategory: null,
        errorModal: action.error
      }
    }

    // success
    case 'SUBCATEGORY_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        subcategory: action.subcategory,
        error: null
      }
    case 'SUBCATEGORIES/DATA_SUCCESS':
    case 'SUBCATEGORIESBYCATEGORY/DATA_SUCCESS':
    case 'SUBCATEGORY/DATA_SUCCESS_DELETE':
    case 'SUBCATEGORY/DATA_SUCCESS_EDIT':
    case 'SUBCATEGORY/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        subcategories: action.subcategories,
        error: null
      }
    case 'SUBCATEGORY/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
