import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

const padding = ({ padding }) => {
  if (padding) {
    return `padding: ${padding}`
  }
}
export const ButtonStyled = styled(Button)`
  && {
    border-radius: 0;
    min-width: 0;
    text-transform: none;
    color: ${({ theme }) => theme.colors.primaryColor};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    ${padding}
    &:hover {
      background: none;
    }
  }
`
export const LinkStyled = styled(Link)`
  text-decoration: none;
`
export const Img = styled.img`
  padding: 1px 5px 0 0;
`
