const initialState = {
  showMenu: false,
  isOpenDialogTable: false,
  rowsPerPage: 10
}

export const Navigation = (state = initialState, action) => {
  switch (action.type) {
    case 'NAVIGATION/HANDLE_MENU':
      return {
        ...state,
        showMenu: action.showMenu
      }
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        showMenu: false
      }
    }
    case 'NAVIGATION/OPEN_MODAL_TABLE': {
      return {
        ...state,
        isOpenDialogTable: action.open,
        cb: action.cb,
        currentItemName: action.name,
        forgetPassword: action.forgetPassword
      }
    }
    case 'NAVIGATION/CHANGE_ROWS': {
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      }
    }

    case 'BRAND/DATA_SUCCESS_DELETE':
    case 'CATEGORY/DATA_SUCCESS_DELETE':
    case 'CONTENT/DATA_SUCCESS_DELETE':
    case 'SUBCATEGORY/DATA_SUCCESS_DELETE':
    case 'COUNTRY/DATA_SUCCESS_DELETE':
    case 'NOTIFICATION/DATA_SUCCESS_DELETE':
    case 'USER/DATA_SUCCESS_DELETE':
    case 'USER/FORGET_SUCCESS':
    case 'TYPE/DATA_SUCCESS_DELETE': {
      return {
        ...state,
        isOpenDialogTable: false
      }
    }
    case 'BRAND/DATA_FAILURE_DELETE':
    case 'CATEGORY/DATA_FAILURE_DELETE':
    case 'CONTENT/DATA_FAILURE_DELETE':
    case 'SUBCATEGORY/DATA_FAILURE_DELETE':
    case 'COUNTRY/DATA_FAILURE_DELETE':
    case 'NOTIFICATION/DATA_FAILURE_DELETE':
    case 'USER/DATA_FAILURE_DELETE':
    case 'TYPE/DATA_FAILURE_DELETE': {
      return {
        ...state,
        errorModal: action.error
      }
    }
    case 'NAVIGATION/REMOVE_ERROR': {
      return {
        ...state,
        errorModal: null
      }
    }
    default:
      return state
  }
}
