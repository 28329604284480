import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const padding = ({ padding }) => {
  if (padding) {
    return `padding: ${padding}`
  }
}

const margin = ({ margin }) => {
  if (margin) {
    return `margin: ${margin}`
  }
}

const colorButton = ({ theme, secundary, primary }) => {
  if (secundary) {
    return `background-color: ${({ borderNone }) =>
      borderNone ? 'transparent' : 'white'};
    border: ${({ borderNone }) =>
    borderNone ? '1px solid transparent' : '1px solid rgba(0,0,0,0.12)'};
    color: ${theme.colors.primaryColor};`
  } else if (primary) {
    return `background-color: ${theme.colors.primaryColor};
    border: 1px solid ${theme.colors.primaryColor};
    color: white;`
  }
}
const disabled = ({ disabled }) => {
  if (disabled) {
    return `color: white;
    opacity: 0.5;
    border: none;`
  }
}

const handleHover = (props) => {
  if (props.nohover) {
    return `&:hover{
      ${colorButton(props)}
    }`
  }
}

export const ButtonStyled = styled(Button)`
  && {
    min-width: 0;
    border-radius: 0px;
    ${padding}
    ${colorButton}
    ${handleHover}
    ${margin}
    && {
      ${disabled}
    }
    &&:hover {
      opacity: 0.6
      ${colorButton}
    }

  }
`
