import styled from 'styled-components'
import ReactPlayer from 'react-player'

export const ReactPlayerStyled = styled(ReactPlayer)`
  && {
    width: 90% !important;
    height: 90% !important;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`
