import { idItem } from '../../utils/request'
const initialState = {
  isFetching: false,
  isFinished: false,
  sendMail: false,
  error: false,
  passwordSet: false
}

export const User = (state = initialState, action) => {
  switch (action.type) {
    case 'USER/PASSWORD_CHANGE_REQUEST':
    case 'USER/LOGIN_REQUEST':
    case 'USER/FORGET_REQUEST':
    case 'USER/VERIFY_TOKEN_FORGET_REQUEST':
    case 'USER/SET_PASSWORD_REQUEST':
      return {
        ...state,
        isFetching: true,
        sendMail: false,
        error: false,
        passwordSet: false
      }
    case 'USER/LOGIN_SUCCESS':
      const token = action.token
      window.localStorage.setItem(idItem, token || '')

      return {
        ...state,
        error: undefined,
        isFetching: false
      }
    case 'USER/LOGOUT':
      window.localStorage.removeItem(idItem)

      return {
        ...state,
        user: undefined
      }
    case 'USER/VERIFY_REQUEST':
      return {
        ...state,
        user: action.data,
        isFetching: true
      }
    case 'USER/VERIFY_SUCCESS':
      return {
        ...state,
        isFetching: false,
        user: action.data,
        isFinished: true
      }
    case 'USER/VERIFY_FAILURE':
      window.localStorage.removeItem(idItem)
      return {
        ...state,
        // error: action.error,
        isFinished: true,
        isFetching: false,
        user: undefined
      }
    case 'USER/PASSWORD_CHANGE_SUCCESS':
      return {
        ...state,
        message: action.data.message
      }
    case 'USER/PASSWORD_CHANGE_FAILURE':
    case 'USER/LOGIN_FAILURE':
    case 'USER/FORGET_FAILURE':
    case 'USER/VERIFY_TOKEN_FORGET_FAILURE':
    case 'USER/SET_PASSWORD_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    case 'USER/VERIFY_TOKEN_FORGET_SUCCESS': {
      return {
        ...state,
        user: action.user,
        isFetching: false
      }
    }
    case 'USER/FORGET_SUCCESS': {
      return {
        ...state,
        sendMail: true
      }
    }
    case 'USER/SET_PASSWORD_SUCCESS': {
      return {
        ...state,
        passwordSet: true
      }
    }
    case 'USER/REMOVE_ERROR': {
      return {
        ...state,
        error: null,
        sendMail: false
      }
    }
    default:
      return state
  }
}
