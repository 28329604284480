const errors = {
  'error.wrong_email_or_password': 'error.wrong_email_or_password',
  'error.duplicate_email': 'error.duplicate_email',
  'error.user.edit.password': 'error.user.edit.password',
  'error.user_not_found': 'error.user_not_found',
  'err.modal.user.delete': 'err.modal.user.delete',
  defaultError: `error.default.error`
}

const getError = error => {
  const keys = Object.keys(errors)
  const errorKey = keys.find(x => x === error)
  if (!errorKey) {
    return errors.defaultError
  }
  return errors[errorKey]
}

export default getError
