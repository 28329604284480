import React from 'react'
import {
  Wrapper,
  TextFieldStyled,
  ButtonStyled,
  WrapperSearch,
  WrapperMessage,
  PredictiveSearchWrapper,
  ResultsPredictive
} from './styles'
import ReactDOM from 'react-dom'
import { SearchIconBlack } from '../../assets/img'
import InputAdornment from '@material-ui/core/InputAdornment'
import Chip from '@material-ui/core/Chip'
import { connect } from 'react-redux'
import { setDataSearch } from '../../modules/search'
import { getPredictiveDocuments, addHashtag, deleteHashtag } from '../../modules/list'
import Filters from '../filters'
import { FormattedMessage } from 'react-intl'
import debounced from '../../utils/debounce'
import { goToRoute } from '../../modules/navigation'
import ItemPredictive from './item-predictive/index'

class Search extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: this.props.value || this.props.search
    }
    this.inputRef = React.createRef()
    this.search = this.search.bind(this)
    this._handleTextFieldChange = this._handleTextFieldChange.bind(this)
    this.searchPredictive = this.searchPredictive.bind(this)
  }

  search (e) {
    e.preventDefault()
    const a = ReactDOM.findDOMNode(this.inputRef.current)
    a.blur()
    this.handleState(false)

    this.props.setDataSearch(this.state.search)
  }
  _handleTextFieldChange (e) {
    const search = e.target.value
    this.setState({ search }, () => {
      this.searchPredictive(this.searchPredictive, 200)
    })
  }

  searchPredictive = debounced(this._searchPredictive, 200)

  currentSearch = () => this.state.search

  _searchPredictive () {
    (this.state.search.length > 0) && this.props.getPredictiveDocuments(this.state.search, this.currentSearch)
  }

  buildFilter () {
    if (
      (this.props.resultsPage && this.props.search) ||
      this.props.categoryPage
    ) {
      return <Filters categoryPage={this.props.categoryPage} {...this.props} />
    }
    return null
  }

  handleState (state) {
    if (!this.state.onFocusPredictive) {
      this.setState({ statePredictive: state })
    }
  }

  buildItemsPredictiveResults (items) {
    const res = []
    items.forEach((item, i) => {
      res.push(
        <ItemPredictive
          currentQuery={this.state.search}
          onClick={() => {
            this.props.goToRoute(`/file-card/${item.id}`)
          }}
          item={item}
          i={item.id}
          key={item.id}
          id={item.id}
        />
      )
    })
    return res
  }

  buildHashtags () {
    if (this.props.hashtags.length > 0) {
      return this.props.hashtags.map((hashtag, i) => (
        <Chip
          key={`${hashtag.name}-${i}`}
          label={`#${hashtag.name}`}
          onDelete={() => this.props.deleteHashtag(hashtag)}
          style={{ marginLeft: 5 }} />
      ))
    }
  }

  buildItemsPredictiveHashtags () {
    const { resultsPredictive, search } = this.props
    if (
      !resultsPredictive ||
      (!search && !this.state.search) ||
      !this.state.statePredictive
    ) {
      return null
    }
    const res = []
    resultsPredictive.hashtags.forEach((hashtag, i) => {
      res.push(
        <ItemPredictive
          hashtag
          currentQuery={this.state.search}
          onClick={() => {
            this.setState({ search: '' })
            this.inputRef.current.value = ''
            this.props.addHashtag(hashtag)
          }}
          item={hashtag}
          i={hashtag.id}
          key={`${hashtag.name}_${i}`}
          id={hashtag.id}
        />
      )
    })
    return res
  }

  buildItemsPredictive () {
    const { resultsPredictive, search } = this.props
    if (
      !resultsPredictive ||
      (!search && !this.state.search) ||
      !this.state.statePredictive
    ) {
      return null
    }
    const res = []
    resultsPredictive.documents.forEach((group, i) => {
      res.push(
        <div>
          <div
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
              margin: '16px 7px 4px 12px'
            }}
          >
            {group.category}
          </div>
          {this.buildItemsPredictiveResults(group.documents)}
        </div>
      )
    })
    return res
  }

  render () {
    return (
      <div>
        <Wrapper onSubmit={this.search} className={'Wrapper-search'}>
          <PredictiveSearchWrapper>
            <WrapperSearch>
              <TextFieldStyled
                onFocus={() => {
                  this.handleState(true)
                }}
                onBlur={() => {
                  this.handleState(false)
                }}
                placeholder='Search contents...'
                onChange={this._handleTextFieldChange}
                inputRef={this.inputRef}
                defaultValue={this.props.value}
                classInputRoot={'search-input'}
                startAdornment={
                  <InputAdornment
                    position='start'
                    classes={{ positionStart: 'start' }}
                    style={{ maxWidth: '70%' }}
                  >
                    <img src={SearchIconBlack} alt='search' />
                    <div style={{ overflow: 'hidden' }}>
                      {this.buildHashtags()}
                    </div>
                  </InputAdornment>
                }
              />
              <ButtonStyled
                primary
                disabled={
                  this.props.isFetching ||
                  ((this.state.search && (this.state.search.length > 0)) && (this.props.hashtags && (this.props.hashtags.length > 0)))
                }
                type='submit'
                variant='contained'
              >
                Search
              </ButtonStyled>
            </WrapperSearch>
            <ResultsPredictive
              onMouseEnter={() => {
                this.setState({ onFocusPredictive: true })
              }}
              onMouseLeave={() => {
                this.setState({ onFocusPredictive: false })
              }}
              results={this.props.results}
            >
              {this.buildItemsPredictiveHashtags()}
              {this.buildItemsPredictive()}
            </ResultsPredictive>
          </PredictiveSearchWrapper>

          {this.props.results && (
            <WrapperMessage>
              <FormattedMessage id='search.text' />
            </WrapperMessage>
          )}
        </Wrapper>
        {this.buildFilter()}
        {this.props.value && this.props.totalResults ? (
          <p style={{ float: 'left' }}>
            {this.props.totalResults} search results for {'  '}
            <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
              "{this.props.value}"
            </span>
          </p>
        ) : null}
      </div>
    )
  }
}

export default connect(
  state => ({
    search: state.searchReducer.data.query,
    isFetching: state.listReducer.isFetching,
    totalResults: state.listReducer.totalResults,
    resultsPredictive: state.listReducer.resultsPredictive,
    hashtags: state.listReducer.hashtags
  }),
  { setDataSearch, getPredictiveDocuments, goToRoute, addHashtag, deleteHashtag }
)(Search)
