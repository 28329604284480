import styled from 'styled-components'
import TextField from '../textfield'
import FilterSelect from '../filter-select'
import Button from '../../components/button'

export const TextFieldStyled = styled(TextField)`
  width: 100%;
  && {
    background: red;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.superlightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.superlightGrey};
  padding: 10px 0;
`

export const SelectStyled = styled(FilterSelect)``

export const Select = styled.div`
  margin: 5px;
`
export const WrapperSelect = styled(Select)`
  width: 150px;
`

export const Span = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  padding-right: 16px;
`

export const ButtonLink = styled.button`
  background: none !important;
  color: ${({ theme }) => theme.colors.primaryColor};
  border: none;
  padding: 0 !important;
  font: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryColor};
  cursor: pointer;
`

export const ButtonStyled = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.superlightGrey};
    color: ${({ theme }) => theme.colors.primaryColor};
    text-transform: none;
  }
`

export const DateSelect = styled(Select)`
  max-width: 235px;
`
