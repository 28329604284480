import * as theme from '../theme'

const muiTheme = {
  overrides: {
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: '0px'
        },
        paddingLeft: '0px'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.colors.primaryColor
      }
    },
    MuiCircularProgress: {
      circle: {
        color: theme.colors.primaryColor
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: theme.colors.primaryColor
      }
    },
    MuiPickersDay: {
      day: {
        color: theme.colors.primaryColor
      },
      isSelected: {
        backgroundColor: theme.colors.primaryColor
      },
      current: {
        color: theme.colors.primaryColor
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: theme.colors.primaryColor
      }
    },
    MuiInput: {
      underline: {
        borderBottom: '1px solid #888',
        '&:after': {
          borderBottom: '1px solid transparent'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: 'red'
      }
    },
    MuiTableSortLabel: {
      active: {
        paddingBottom: '4px'
      }
    },
    MUIDataTable: {
      root: {},
      paper: {
        boxShadow: 'none'
      },
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableSearch: {
      main: {
        flex: 'none'
      },
      clearIcon: {
        '&:hover': {
          color: '#c7102d'
        }
      }
    },
    MUIDataTableToolbar: {
      actions: {
        flex: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'absolute'
      },
      iconActive: {
        color: 'transparent',
        pointerEvents: 'none'
      },
      icon: {
        '&:hover': {
          color: 'black'
        }
      },
      left: {
        flex: 'none'
      }
    },
    MuiTableCell: {
      body: {
        maxWidth: '200px'
      },
      footer: {
        padding: '0',
        display: 'flex',
        justifyContent: 'center'
      },
      root: {
        padding: '0',
        marginLeft: '10px'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {},
      data: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black'
      },
      sortAction: {
        verticalAlign: 'none'
      }
    },
    MuiTypography: {
      h6: {
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '-0.64px',
        lineHeight: '32px'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        color: 'white',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      contained: {
        boxShadow: 'none'
      },
      textPrimary: {
        color: 'black'
      }
    },
    MuiIconButton: {
      root: {
        marginLeft: '-12px',
        opacity: '0.6',
        '&:hover': {
          opacity: '1'
        },
        '&.reverse': {
          opacity: '1 !important',
          '&:hover.reverse': {
            opacity: '0.6 !important'
          }
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: theme.colors.lightGrey
        },
        '& $notchedOutline': {
          borderColor: theme.colors.lightGrey,
          borderWidth: 2
        },
        '&$focused $notchedOutline': {
          borderColor: theme.colors.grey,
          borderWidth: 2
        }
      }
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
        borderBottom: '1px solid #e8e8e8'
      }
    },
    MuiTabs: {
      root: {
        flexGrow: 1,
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        textTransform: `capitalize`,
        '&$selected': {
          color: 'rgba(0, 0, 0, 0.54) !important'
        }
      }
    },
    MUIDataTableBodyCell: {},
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: theme.colors.primaryColor
      }
    },
    MuiDialogActions: {
      action: {
        color: theme.colors.primaryColor
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'black',
        fontSize: '13px',
        letterSpacing: '0.4px',
        lineHeight: '16px'
      }
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: `'Exo 2', sans-serif`,
    useNextVariants: true
  }
}
export default muiTheme
