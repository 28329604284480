export const idItem = 'teka-user-id'
export const getIdToken = () => window.localStorage.getItem(idItem) || ''
const { REACT_APP_API_URL, REACT_APP_ROOT_URL } = process.env

export const headers = (contentType = 'application/json') => {
  const token = getIdToken()
  const h = {}
  if (contentType) {
    h['Content-Type'] = contentType
  }
  h.Accept = 'application/json'
  if (token) {
    h.Authorization = `Bearer ${token}`
  }

  return h
}
export const request = async (
  path,
  options = {},
  requestType = 'application/json',
  responseType = 'json'
) => {
  const url = `${REACT_APP_API_URL}/${path}`
  const settings = {
    headers: headers(requestType),
    responseType,
    ...options
  }
  return window.fetch(url, settings).then(response => {
    return response.json()
  })
}

export const requestDownload = async path => {
  const url = `${REACT_APP_ROOT_URL}${path}`
  window.location.href = url
}
