import styled from 'styled-components'
import Button from '../../../components/button'

export const ButtonStyled = styled(Button)`
  &&{
    text-transform: none 
    font-size: 15px;
    font-weight: normal;
    &:hover{
      background: none
    }
  }
}`

export const Icon = styled.img``
