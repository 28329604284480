import { request } from '../../../utils/request'
import { push } from 'react-router-redux'

const getUsersRequest = () => ({ type: 'USERS/DATA_REQUEST' })
const getUsersSuccess = users => ({
  type: 'USERS/DATA_SUCCESS',
  users
})
const getUsersFailure = error => ({
  type: 'USERS/DATA_FAILURE',
  error
})

export const getUsers = () => {
  return dispatch => {
    dispatch(getUsersRequest())
    // Users
    const requestOptions = {
      method: 'GET'
    }

    return request(`users`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getUsersFailure(res.error || res.message))
          return false
        }
        dispatch(getUsersSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getUsersFailure(err))
      })
  }
}

const deleteUserRequest = () => ({ type: 'USER/DATA_REQUEST_DELETE' })
const deleteUserSuccess = users => ({
  type: 'USER/DATA_SUCCESS_DELETE',
  users
})
const deleteUserFailure = error => ({
  type: 'USER/DATA_FAILURE_DELETE',
  error
})

export const deleteUser = id => {
  return dispatch => {
    dispatch(deleteUserRequest())
    // User-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`users`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteUserFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteUserSuccess(res))
          return true
        }
      })
      .catch(() => {
        dispatch(deleteUserFailure('err.modal.user.delete'))
      })
  }
}

const getUserByIdRequest = () => ({ type: 'USER_ID/DATA_REQUEST' })
const getUserByIdSuccess = user => ({
  type: 'USER_ID/DATA_SUCCESS',
  user
})
const getUserByIdFailure = error => ({
  type: 'USER_ID/DATA_FAILURE',
  error
})

export const getUserById = id => {
  return dispatch => {
    dispatch(getUserByIdRequest())
    // User-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`users/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getUserByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getUserByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getUserByIdFailure(err))
      })
  }
}

const editUserRequest = user => ({ type: 'USER/DATA_REQUEST_EDIT', user })
const editUserSuccess = users => ({
  type: 'USER/DATA_SUCCESS_EDIT',
  users
})
const editUserFailure = error => ({
  type: 'USER/DATA_FAILURE_EDIT',
  error
})

export const editUser = (user, me) => {
  return dispatch => {
    dispatch(editUserRequest(user))
    // User-Edit
    const body = {
      id: user.id,
      name: user.name,
      surname: user.surname,
      username: user.username,
      password: user.password,
      email: user.email,
      region_id: user.region.id,
      language: user.language,
      role: user.role
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`users`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401 || res.code === 400) {
          dispatch(editUserFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editUserSuccess(res))
          if (!me) {
            dispatch(push(`/admin/users`))
          }

          return true
        }
      })
      .catch(err => {
        dispatch(editUserFailure(err))
      })
  }
}

const addUserRequest = user => ({ type: 'USER/DATA_REQUEST_ADD', user })
const addUserSuccess = users => ({
  type: 'USER/DATA_SUCCESS_ADD',
  users
})
const addUserFailure = error => ({
  type: 'USER/DATA_FAILURE_ADD',
  error
})

export const addUser = user => {
  return dispatch => {
    dispatch(addUserRequest())
    // User-Add
    const body = {
      name: user.name,
      surname: user.surname,
      username: user.username,
      password: user.password,
      email: user.email,
      region_id: user.region_id,
      language: user.language,
      role: user.role
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`users`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401 || res.code === 400) {
          dispatch(addUserFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addUserSuccess(res))
          dispatch(push(`/admin/users`))
          return true
        }
      })
      .catch(err => {
        dispatch(addUserFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'USER/REMOVE_ERROR'
})

const userUnsubscribeRequest = newsletter => ({
  type: 'USER/UNSUBSCRIBE_REQUEST',
  newsletter
})
const userUnsubscribeSuccess = ({ newsletter }) => ({
  type: 'USER/UNSUBSCRIBE_SUCCESS',
  newsletter
})
const userUnsubscribeFailure = error => ({
  type: 'USER/UNSUBSCRIBE_FAILURE',
  error
})

export const userUnsubscribe = (newsletter, id) => {
  return dispatch => {
    dispatch(userUnsubscribeRequest())
    const body = {
      newsletter: newsletter,
      id: id
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`users/newsletter/unsubscribe`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(userUnsubscribeFailure(res.error || res.message))
          return false
        }
        dispatch(userUnsubscribeSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(userUnsubscribeFailure(err))
      })
  }
}
