import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

export const FormControlStyled = styled(FormControl)`
  width: 100%;
`
export const TextFieldStyled = styled(TextField)`
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
