import styled from 'styled-components'
import MuiCheckbox from '@material-ui/core/Checkbox'

export const Separator = styled.hr`
  opacity: 0.5;
`

export const Checkbox = styled(MuiCheckbox)`
  && {
    padding: 2px;
    margin-right: 6px;
    padding-left: 15px;
    opacity: 0.6;
    svg {
      height: 30px;
      width: auto;
    }
  }
`
