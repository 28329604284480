import { request, headers } from '../../utils/request'
import axios from 'axios'
const { REACT_APP_API_URL } = process.env

export async function getFirstStepData (id) {
  const res = await request('documents/' + id)
  return {
    id: res.id,
    brand: res.brand.id,
    brandName: res.brand.name,
    userId: res.user_id,
    category: res.category.id,
    subcategory: res.subcategory.id,
    categoryName: res.category.name,
    subcategoryName: res.subcategory.name,
    name: res.name,
    reference: res.reference,
    hashtags: res.hashtags,
    description: res.description,
    languages: res.languages && res.languages.map((l) => l.id),
    selectedTags: res.tags && res.tags.map((l) => l.id),
    files: res.files,
    date: new Date(res.created.date).toLocaleDateString(),
    uploadedBy: res.uploaded_by,
    mainImage: res.main_image,
    status: res.status,
    types: res.types ? res.types.map((l) => l.id) : []
  }
}

export function setStatus ({ id, status, reason }) {
  return request('documents/change/status', {
    method: 'POST',
    body: JSON.stringify({
      id,
      status,
      reason
    })
  })
}

export async function sendFirstStep ({
  userId,
  category,
  subcategory,
  id,
  name,
  created,
  description,
  languages,
  tags,
  references,
  hashtags,
  types
}) {
  const data = {
    id: id,
    user_id: userId,
    category,
    subcategory,
    name,
    created,
    description,
    languages,
    tags,
    reference: references,
    hashtags: hashtags,
    types: types,
    completed: false
  }

  try {
    const res = await request('documents', {
      method: 'POST',
      body: JSON.stringify(data)
    })

    return res.id
  } catch (e) {
    throw e
  }
}

export async function sendSecondStep ({ uploadId, needProcess, userId }) {
  const data = {
    id: uploadId,
    completed: false,
    user_id: userId,
    need_process: needProcess
  }

  try {
    const res = await request('documents', {
      method: 'POST',
      body: JSON.stringify(data)
    })

    return res
  } catch (e) {
    throw e
  }
}

export async function sendThirdStep ({
  uploadId,
  previewId,
  id,
  userId,
  preview,
  completed
}) {
  let data = {
    id: uploadId || id,
    completed,
    user_id: userId,
    preview_id: previewId,
    preview
  }

  // Remove null values (FormData will send them andserver don't like them)
  Object.keys(data).forEach((key) => data[key] == null && delete data[key])

  // documents/add/file
  const formData = new window.FormData()
  for (let name in data) {
    formData.append(name, data[name])
  }
  const res = await axios.post(`${REACT_APP_API_URL}/documents`, formData, {
    headers: headers('multipart/form-data')
  })

  return res.data
}

export async function removeFile (id) {
  const res = await axios.delete(`${REACT_APP_API_URL}/documents/delete/file`, {
    headers: headers('application/json'),
    data: { id }
  })

  return res.data
}

export async function uploadFile (data, onUploadProgress, onFinish) {
  // documents/add/file
  const formData = new window.FormData()

  for (let name in data) {
    formData.append(name, data[name])
  }

  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/documents/add/file`,
      formData,
      {
        headers: headers('multipart/form-data'),
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onUploadProgress(percentCompleted)
        }
      }
    )
    onFinish(res.data)
  } catch (e) {
    onUploadProgress(100, e.message)
  }
}
