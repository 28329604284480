const initialState = {
  isFetching: false,
  contentlist: [],
  content: null
}

export const ContentList = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'CONTENTLIST/DATA_REQUEST':
    case 'CONTENT/DATA_REQUEST_DELETE':
    case 'CONTENT_ID/DATA_REQUEST':
    case 'CONTENT/DATA_REQUEST_EDIT':
    case 'CONTENT/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'CONTENTLIST/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        contentlist: []
      }

    case 'CONTENT_ID/DATA_FAILURE':
    case 'CONTENT/DATA_FAILURE_EDIT':
    case 'CONTENT/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        content: null,
        error: action.error
      }
    }
    case 'CONTENT/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        content: null,
        errorModal: action.error
      }
    }
    // success
    case 'CONTENT_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        content: action.content,
        error: null
      }
    case 'CONTENTLIST/DATA_SUCCESS':
    case 'CONTENT/DATA_SUCCESS_EDIT':
    case 'CONTENT/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        contentlist: action.contentlist,
        error: null
      }
    case 'CONTENT/DATA_SUCCESS_DELETE': {
      return {
        ...state,
        isFetching: false,
        contentlist: state.contentlist.filter(x => x.id !== action.id)
      }
    }
    case 'CONTENT/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
