import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  getSubcategories,
  deleteSubcategory,
  getFrattenSubCategories
} from '../../../../modules/admin/content/subcategories'
import { goToRoute, openDialogTable } from '../../../../modules/navigation'
import Table from '../../../../components/table'
import Button from '../../../../components/button'
import getColumns from '../../../../models/content/subcategories'
import { IconPlus } from '../../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'

class Subcategories extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getSubcategories()
  }

  render () {
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.content.subareas' />
        </Title>
        <WrapperButton>
          <Button
            primary='true'
            onClick={() => this.props.goToRoute(`/admin/content/subarea/add`)}
            padding='7px 10px'
          >
            <Img src={IconPlus} />
            <FormattedMessage id={'admin.add.subcategories'} />
          </Button>
        </WrapperButton>
        <Table
          data={this.props.subcategories}
          columns={getColumns(
            this.props.deleteSubcategory,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          isFetching={this.props.isFetching}
          minWidth='450px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    subcategories: getFrattenSubCategories(state),
    isFetching: state.subcategoriesReducer.isFetching
  }),
  { getSubcategories, deleteSubcategory, goToRoute, openDialogTable }
)(injectIntl(Subcategories))
