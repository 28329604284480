import { request } from '../../../utils/request'
import { push } from 'react-router-redux'

const getBrandRequest = () => ({ type: 'BRANDS/DATA_REQUEST' })
const getBrandSuccess = brands => ({
  type: 'BRANDS/DATA_SUCCESS',
  brands
})
const getBrandFailure = error => ({
  type: 'BRANDS/DATA_FAILURE',
  error
})

export const getBrands = () => {
  return dispatch => {
    dispatch(getBrandRequest())
    // Brands
    const requestOptions = {
      method: 'GET'
    }

    return request(`brands`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getBrandFailure(res.error || res.message))
          return false
        }
        dispatch(getBrandSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getBrandFailure(err))
      })
  }
}

const deleteBrandRequest = () => ({ type: 'BRAND/DATA_REQUEST_DELETE' })
const deleteBrandSuccess = brands => ({
  type: 'BRAND/DATA_SUCCESS_DELETE',
  brands
})
const deleteBrandFailure = error => ({
  type: 'BRAND/DATA_FAILURE_DELETE',
  error
})

export const deleteBrands = id => {
  return dispatch => {
    dispatch(deleteBrandRequest())
    // Brands-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`brands`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteBrandFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteBrandSuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteBrandFailure(err))
      })
  }
}

const getBrandByIdRequest = () => ({ type: 'BRAND_ID/DATA_REQUEST' })
const getBrandByIdSuccess = brand => ({
  type: 'BRAND_ID/DATA_SUCCESS',
  brand
})
const getBrandByIdFailure = error => ({
  type: 'BRAND_ID/DATA_FAILURE',
  error
})

export const getBrandById = id => {
  return dispatch => {
    dispatch(getBrandByIdRequest())
    // Brands-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`brands/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getBrandByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getBrandByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getBrandByIdFailure(err))
      })
  }
}

const editBrandRequest = brand => ({ type: 'BRAND/DATA_REQUEST_EDIT', brand })
const editBrandSuccess = brands => ({
  type: 'BRAND/DATA_SUCCESS_EDIT',
  brands
})
const editBrandFailure = error => ({
  type: 'BRAND/DATA_FAILURE_EDIT',
  error
})

export const editBrand = brand => {
  return dispatch => {
    dispatch(editBrandRequest(brand))
    // Brands-Edit
    const body = {
      ...brand
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`brands`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editBrandFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editBrandSuccess(res))
          dispatch(push(`/admin/brands`))
          return true
        }
      })
      .catch(err => {
        dispatch(editBrandFailure(err))
      })
  }
}

const addBrandRequest = brand => ({ type: 'BRAND/DATA_REQUEST_ADD', brand })
const addBrandSuccess = brands => ({
  type: 'BRAND/DATA_SUCCESS_ADD',
  brands
})
const addBrandFailure = error => ({
  type: 'BRAND/DATA_FAILURE_ADD',
  error
})

export const addBrand = brand => {
  return dispatch => {
    dispatch(addBrandRequest())
    // Brands-Add
    const body = {
      ...brand
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`brands`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addBrandFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addBrandSuccess(res))
          dispatch(push(`/admin/brands`))
          return true
        }
      })
      .catch(err => {
        dispatch(addBrandFailure(err))
      })
  }
}

export const selectBrand = brand => ({
  type: 'BRAND/SELECT_BRAND',
  brand
})

export const removeError = () => ({
  type: 'BRAND/REMOVE_ERROR'
})
