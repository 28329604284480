import styled from 'styled-components'

export const H1Styled = styled.h1`
  margin: 0;
  padding: 0;
  margin-bottom: 65px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.64px;
  line-height: 32px;
  text-align: center;
  ${({ left }) => {
    if (left) {
      return `float: left`
    }
    return null
  }}
`
