import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import Content from '../../containers/admin/content/contentlist'

class RouterCategories extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          exact path={`${this.props.match.path}/content/list`}
          component={Content}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterCategories)
