import styled from 'styled-components'
export const MenuOverlay = styled.div`
  height: 100%;
  padding: 30px 50px 10px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.3);
`

export const ContentMenuOverlay = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1194px;
  margin: auto;
`
