import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { getBrands, deleteBrands } from '../../../modules/admin/brands'
import { goToRoute, openDialogTable } from '../../../modules/navigation'
import Table from '../../../components/table'
import Button from '../../../components/button'
import getColumns from '../../../models/brands'
import { IconPlus } from '../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'

class Brands extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getBrands()
  }

  render () {
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.brands' />
        </Title>
        <WrapperButton>
          <Button
            primary='true'
            onClick={() => this.props.goToRoute(`/admin/brand/add`)}
            padding='7px 10px'
          >
            <Img src={IconPlus} />
            <FormattedMessage id={'admin.add.brands'} />
          </Button>
        </WrapperButton>
        <Table
          data={this.props.brands}
          columns={getColumns(
            this.props.deleteBrands,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          minWidth='450px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    brands: state.brandsReducer.brands
  }),
  { getBrands, deleteBrands, goToRoute, openDialogTable }
)(injectIntl(Brands))
