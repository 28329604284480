const initialState = {
  isFetching: false,
  users: [],
  user: null,
  newsletter: true,
  id: 0
}

export const Users = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'USERS/DATA_REQUEST':
    case 'USER/DATA_REQUEST_DELETE':
    case 'USER_ID/DATA_REQUEST':
    case 'USER/DATA_REQUEST_EDIT':
    case 'USER/DATA_REQUEST_ADD':
    case 'USER/UNSUBSCRIBE_REQUEST':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'USERS/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        users: []
      }

    case 'USER_ID/DATA_FAILURE':
    case 'USER/DATA_FAILURE_EDIT':
    case 'USER/DATA_FAILURE_ADD':
    case 'USER/UNSUBSCRIBE_FAILURE': {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }

    case 'USER/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        errorModal: action.error
      }
    }

    // success
    case 'USER_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        user: action.user,
        error: null
      }
    case 'USERS/DATA_SUCCESS':
    case 'USER/DATA_SUCCESS_DELETE':
    case 'USER/DATA_SUCCESS_EDIT':
    case 'USER/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        users: action.users,
        error: null
      }
    case 'USER/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    case 'USER/UNSUBSCRIBE_SUCCESS': {
      return {
        ...state,
        newsletter: action.newsletter
      }
    }
    default:
      return state
  }
}
