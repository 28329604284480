import styled from 'styled-components'

export const Form = styled.form`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

export const Title = styled.h1`
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
`

export const Subtitle = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 0;
`

export const Icon = styled.img``
