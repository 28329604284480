import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'
import { NoImage } from '../../assets/img'
import {
  CardStyled,
  CardImageContent,
  CardActionAreaStyled,
  ButtonStyled,
  CardActions,
  SpanStyled,
  New,
  CardImage,
  SpanNew
} from './styles'
import LazyLoad from 'react-lazyload'
import { withRouter } from 'react-router-dom'
import ImageLazy from './imageLazy'
const isNewContent = (props) => {
  if (props.item.created) {
    const timestamp = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    const dateCreated = new Date(props.item.created.date)
    return dateCreated > timestamp
  }
  return false
}

const renderContent = ({ item, ...props }) => (
  <div>
    <p>
      <SpanStyled bold={props.weightOne} color={props.colorOne}>
        {item.brand ? item.brand.name : ''}
      </SpanStyled>
      <SpanStyled bold>
        {item.category ? `${item.category.name} >` : ''}
        <SpanStyled bold={props.weightTwo} inline color={props.colorSecond}>
          {item.subcategory ? `${item.subcategory.name}` : ''}
        </SpanStyled>
      </SpanStyled>
    </p>
    <p>
      <SpanStyled bold={props.weightThird} color={props.colorThird}>
        {item.name || ''}
      </SpanStyled>
    </p>
  </div>
)
class Card extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    const props = this.props
    return (
      <CardStyled
        to={`/file-card/${props.item.id}`}
        state={{ prevPath: this.props.location.pathname }}
        slide={props.slide}
      >
        {isNewContent(props) ? (
          <New>
            <SpanNew>
              <FormattedMessage id={'card.new'} />
            </SpanNew>
          </New>
        ) : null}
        <CardActionAreaStyled>
          <CardImageContent>
            <LazyLoad
              placeholder={<CardImage src={NoImage} />}
              offset={100}
              height={250}
            >
              <ImageLazy item={props.item} id={`canvas_${props.item.id}`} />
            </LazyLoad>
          </CardImageContent>
          {renderContent(props)}
          <CardContent />
        </CardActionAreaStyled>
        {!props.slide && (
          <CardActions>
            <ButtonStyled primary className='buttonView' fullWidth>
              <FormattedMessage id={'card.view'} />
            </ButtonStyled>
          </CardActions>
        )}
      </CardStyled>
    )
  }
}

Card.propTypes = {
  content: PropTypes.shape({
    colorOne: PropTypes.string,
    colorSecond: PropTypes.string,
    colorThird: PropTypes.string,
    weightOne: PropTypes.bool,
    weightTwo: PropTypes.bool,
    weightThird: PropTypes.bool,
    fontSize: PropTypes.number,
    isNew: PropTypes.number,
    src: PropTypes.string
  })
}

export default withRouter(Card)
