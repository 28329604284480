import React from 'react'
import { FooterContainer, Text, Bloq } from './styles'
import moment from 'moment'

const Year = moment().year()

const Footer = () => (
  <FooterContainer>
    <Bloq>
      <Text color='grey' fontSize='25'>
        Brand
      </Text>{' '}
      <Text bold color='lightGrey' fontSize='25'>
        HUB
      </Text>
    </Bloq>

    <Bloq right>
      <Text color='grey' fontSize='13'>
        © Copyright {Year}. Teka Group
      </Text>
    </Bloq>
  </FooterContainer>
)

export default Footer
