import React from 'react'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import { Menu, PrimaryMenuList } from '../styles'
import { FormattedMessage } from 'react-intl'
import { ButtonStyled, PrimaryMenuItems } from './styles'

const PrimaryMenu = props => (
  <Menu id='menu'>
    <PrimaryMenuList>
      {/* <PrimaryMenuItems className='headerItem headerFirstItem'>
        {
          <NavLinkStyled activeClassName='active' to='/' exact>
            <FormattedMessage id={'header.item.latestRealeases'} />
          </NavLinkStyled>
        }
      </PrimaryMenuItems> */}
      <PrimaryMenuItems
        className='headerItem headerFirstItem'
        onMouseEnter={() => {
          props.enterButton(() => { props.showMenu(2) })
        }}
        onMouseLeave={() => {
          props.leaveButton(() => { props.closeMenu() })
        }}
      >
        <ButtonStyled>
          {props.selectedBrand ? (
            props.selectedBrand.name
          ) : (
            <FormattedMessage id={'header.item.brand'} />
          )}

          <ArrowIcon />
        </ButtonStyled>
      </PrimaryMenuItems>
      <PrimaryMenuItems
        disabled={!props.selectedBrand}
        className='headerItem'
        onMouseEnter={() => {
          props.enterButton(() => { props.showMenu(1) })
        }}
        onMouseLeave={() => {
          props.leaveButton(() => { props.closeMenu() })
        }}
      >
        <ButtonStyled>
          <FormattedMessage id={'header.item.categories'} />
          <ArrowIcon />
        </ButtonStyled>
      </PrimaryMenuItems>
    </PrimaryMenuList>
  </Menu>
)

export default PrimaryMenu
