import styled from 'styled-components'

export const Img = styled.img`
  height: 100%;
  max-height: 32px;
  opacity: 0.6;
  padding: 0px;
  :hover {
    opacity: 1;
  }
  .brands {
    opacity: 1;
  }
`

export const Icon = styled.img`
  height: 100%;
  max-height: 24px;
  padding: 4px 0px;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
