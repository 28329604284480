import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import Grid from '../../components/grid'
import InfiniteScroll from 'react-infinite-scroller'
import Search from '../../components/search'
import { selectBrand } from '../../modules/admin/brands'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  getDocumentsByCategory,
  getLatestReleases,
  getDocumentsBySearch
} from '../../modules/list'

const PAGE_SIZE = 60
class GestorList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      hasMoreItems: true,
      category: null,
      currentPage: 0
    }
    this.loadMore = this.loadMore.bind(this)
  }

  componentDidUpdate () {
    this.setBrand()
  }
  setBrand () {
    // Set brand if page reload in one category
    const { selectedBrand, brands, categories } = this.props
    const currentCategory = this.getCategory()
    if (currentCategory && !selectedBrand && brands) {
      const category = (categories || []).find(
        (x) => x.id + '' === currentCategory
      )

      if (category) {
        const currentBrand = (brands || []).find(
          (x) => x.name + '' === category.brand.name
        )

        if (currentBrand) {
          this.props.selectBrand(currentBrand)
        }
      }
    }
  }

  getCategory () {
    const { match } = this.props
    return match.params.id_category
  }

  async loadMore () {
    const { hashtags } = this.props
    if (!this.props.isFetching) {
      const page = this.props.currentPage
      if (this.props.results) {
        await this.props.getDocumentsBySearch({
          skip: page * PAGE_SIZE,
          limit: PAGE_SIZE,
          category: this.getCategory(),
          hashtags: hashtags.length > 0 ? hashtags : false
        })
      } else if (this.props.category) {
        await this.props.getDocumentsByCategory({
          skip: page * PAGE_SIZE,
          limit: PAGE_SIZE,
          category: this.getCategory()
        })
      } else {
        await this.props.getLatestReleases({
          skip: page * PAGE_SIZE,
          limit: PAGE_SIZE
        })
      }
    }
  }
  render () {
    const { hasMore, documents, isFetching, selectedBrand } = this.props
    let selectedBrandStorage = {}
    if (!selectedBrand) {
      selectedBrandStorage = JSON.parse(
        window.localStorage.getItem('selectedBrand')
      )
    }
    const documentsSelectedBrand = documents.filter(
      (document) =>
        document.brand.id ===
        (selectedBrand ? selectedBrand.id : selectedBrandStorage.id)
    )
    return (
      <div key={`infinite`}>
        {this.props.results || this.props.category ? (
          <Search
            categoryPage={this.props.category}
            resultsPage={this.props.results}
            value={this.props.query}
            {...this.props}
          />
        ) : null}
        <InfiniteScroll
          loadMore={this.loadMore}
          hasMore={hasMore}
          threshold={100}
          loader={<CircularProgress key='loading_gestor' />}
        >
          {documents && documentsSelectedBrand.length ? (
            <Grid items={documentsSelectedBrand} {...this.props} />
          ) : isFetching ? (
            ''
          ) : (
            <p>
              <FormattedMessage id='gestor.list.noResults' />
            </p>
          )}
        </InfiniteScroll>
      </div>
    )
  }
}

export default withRouter(
  connect(
    (state) => ({
      query: state.searchReducer.data.query,
      isFetching: state.listReducer.isFetching,
      skip: state.listReducer.currentSkip,
      documents: state.listReducer.documents,
      hasMore: state.listReducer.hasMore,
      currentPage: state.listReducer.currentPage,
      selectedBrand: state.brandsReducer.selectedBrand,
      categories: state.categoriesReducer.categories,
      brands: state.brandsReducer.brands,
      hashtags: state.listReducer.hashtags
    }),
    {
      getDocumentsByCategory,
      getLatestReleases,
      getDocumentsBySearch,
      selectBrand
    }
  )(injectIntl(GestorList))
)
