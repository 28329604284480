import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import { Flex, Box } from 'reflexbox'
import Previewer from 'react-previewer'

import SimpleDialog from '../../components/simpleDialog'
import Button from '../../components/button'
import Dropzone from '../../components/dropzone'
import { colors } from '../../theme'
import Crop from '../../components/crop'
import { Title, Subtitle } from './styles'

const { REACT_APP_ROOT_URL } = process.env
const VIDEOS = ['mp4', 'mov', 'webm']

export default ({ editMode, data, next, setpreview }) => {
  const [selected, setSelected] = useState(null)
  const [blobs, setBlobs] = useState({})
  const [loading, setLoading] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [thumbnailData, setThumbnailData] = useState(null)
  const [openAlert, setOpenAlert] = useState(false)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (editMode) {
        if (blobs[selected]) {
          setpreview([blobs[selected]])
        } else {
          next(selected)
        }
      }
    }
  }, [selected, blobs])

  useEffect(() => {
    if (selected) {
      setThumbnailData(data.files.filter((x) => x.id === selected))
    }
  }, [selected])

  const urlImage =
    selected &&
    thumbnailData &&
    `${REACT_APP_ROOT_URL}${thumbnailData[0].folder}${thumbnailData[0].preview}`

  return (
    <Flex column>
      {openAlert && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            top: 100,
            left: '50%',
            zIndex: 1301
          }}
        >
          <Alert severity='success'>Changes saved</Alert>
        </div>
      )}
      {!editMode && (
        <>
          <div>
            <Box mb={2}>
              <Title>Upload Documents in 3 steps</Title>
            </Box>
            <Box mb={4}>
              <Subtitle>[Step 3]</Subtitle>
            </Box>
          </div>
        </>
      )}
      <Flex w={1} column style={{ textAlign: 'left' }}>
        {!editMode && data.files.filter((f) => f.preview).length === 0 && (
          <Box style={{ flexGrow: 1 }}>
            <Box mb={3}>
              <span>
                None of the uploaded files can be previewed.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  You can upload an image thumbnail:
                </span>
              </span>
            </Box>
            <Flex justify='center'>
              {!loading ? (
                <Dropzone
                  accept='image/jpeg, image/png, image/svg+xml'
                  onChange={(data) => {
                    setLoading(true)
                    setpreview(data, () => {
                      setLoading(false)
                    })
                  }}
                >
                  <FormattedMessage id={'dropzone.text'} />
                  <u>
                    <FormattedMessage id={'dropzone.link'} />
                  </u>
                  <FormattedMessage id={'dropzone.second.text'} />
                </Dropzone>
              ) : (
                <CircularProgress />
              )}
            </Flex>
          </Box>
        )}

        {data.files.filter((f) => f.preview).length > 0 && (
          <>
            <Box mb={3}>
              <Flex justify='space-between' align='center'>
                <span style={{ fontWeight: 'bold' }}>
                  Select the thumbnail image:
                </span>
                {thumbnailData && !VIDEOS.includes(thumbnailData[0].extension) && (
                  <Button
                    primary='true'
                    padding='9px 21px'
                    onClick={() => setStatusModal(true)}
                    autoFocus
                    disabled={!selected}
                  >
                    Crop
                  </Button>
                )}
              </Flex>
            </Box>
            <Flex wrap>
              {data.files.map((f) => (
                <Flex
                  style={{
                    boxSizing: 'border-box',
                    padding: 20,
                    margin: 25,
                    borderRadius: 5,
                    border: '1px solid black',
                    transition: 'all 0.5s',
                    borderWidth: '3px',
                    borderColor:
                      selected === f.id
                        ? colors.primaryColor
                        : colors.superlightGrey
                  }}
                  column
                  align='center'
                  onClick={() => {
                    setSelected(f.id)
                  }}
                >
                  {VIDEOS.includes(f.extension) && (
                    <>
                      <span
                        style={{
                          width: '250px',
                          textAlign: 'center'
                        }}
                      >
                        Set the video on the frame you want to use as thumbnail
                      </span>
                      <Previewer
                        source={REACT_APP_ROOT_URL + f.folder + f.file}
                        style={{
                          width: 250,
                          height: 250
                        }}
                        onChange={async (imgbs64) => {
                          const res = await window.fetch(imgbs64)
                          const blob = await res.blob()
                          setBlobs((blobs) => ({
                            ...blobs,
                            [f.id]: blob
                          }))
                        }}
                      />
                    </>
                  )}
                  {!VIDEOS.includes(f.extension) && (
                    <img
                      src={`${REACT_APP_ROOT_URL}${f.folder}${f.preview}`}
                      alt='react'
                    />
                  )}

                  <span style={{ textAlign: 'center', margin: 10 }}>
                    {f.file}
                  </span>
                </Flex>
              ))}
            </Flex>
          </>
        )}

        {editMode && (
          <Box style={{ flexGrow: 1 }} mb={4}>
            <Box mb={3}>
              <span style={{ fontWeight: 'bold' }}>
                You can upload an image thumbnail:
              </span>
            </Box>
            <Flex justify='center'>
              {!loading ? (
                <Dropzone
                  text='Drag and drop in this area the files you want to upload or click here to select them from your device'
                  onChange={(data) => {
                    setLoading(true)
                    setpreview(data, () => {
                      setLoading(false)
                    })
                  }}
                >
                  <FormattedMessage id={'dropzone.text'} />
                  <u>
                    <FormattedMessage id={'dropzone.link'} />
                  </u>
                  <FormattedMessage id={'dropzone.second.text'} />
                </Dropzone>
              ) : (
                <CircularProgress />
              )}
            </Flex>
          </Box>
        )}
        <Flex justify='flex-end'>
          {!editMode && (
            <Button
              onClick={() => {
                if (blobs[selected]) {
                  setpreview([blobs[selected]])
                } else {
                  next(selected)
                }
              }}
              primary='true'
              autoFocus
              disabled={!selected}
            >
              Next
            </Button>
          )}
        </Flex>
      </Flex>
      <SimpleDialog open={statusModal} noCancel>
        <Crop
          upImgClick={urlImage}
          onFinish={(img64) => {
            setpreview([img64])
            setStatusModal(false)
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 2500)
          }}
          onClose={() => {
            setStatusModal(false)
          }}
        />
      </SimpleDialog>
    </Flex>
  )
}
