import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withLastLocation } from 'react-router-last-location'
import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'

import CardFileProduct from '../../components/card-file-product'
import BackButton from '../../components/back-button'
import Button from '../../components/button'
import { goToRoute } from '../../modules/navigation'
import { IconShare } from '../../assets/img'
import { getTokenPublicURL, getPublicURL } from './requests'

import {
  WrapperTitle,
  WrapperBackButton,
  WrapperHeader,
  Title,
  WrapperButtons,
  WrapperShare,
  Icon,
  ButtonStyled
} from './styles.js'

class FileCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      snackbar: false,
      vertical: 'bottom',
      horizontal: 'left',
      tokenURL: undefined,
      data: undefined
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.closeSnackBar = this.closeSnackBar.bind(this)
    this.handleLastLocation = this.handleLastLocation.bind(this)
    this.getTokenURLPublic = this.getTokenURLPublic.bind(this)
  }

  componentDidMount () {
    this.handleLastLocation()
  }

  async componentWillMount () {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const token = params.get('token')
    if (token) {
      this.setState({ isTemp: true })
      const data = await getPublicURL(token)
      this.setState({ data: data, tempReady: true })
    }
  }

  async getTokenURLPublic () {
    const tokenURL = await getTokenPublicURL(
      this.props.match.params.document_id
    )
    this.setState({
      tokenURL
    })
  }

  handleToggle () {
    this.setState((state) => ({ open: !state.open }))
    this.getTokenURLPublic()
  }

  handleClick () {
    if (this.state.tokenURL) {
      this.setState({ snackbar: true })
      setTimeout(this.closeSnackBar, 2500)
      this.handleClose()
    } else {
      console.error('Token is undefined')
    }
  }

  handleClose () {
    this.setState({ open: false })
  }

  closeSnackBar () {
    this.setState({ snackbar: false })
  }

  renderButton () {
    if (this.props.user && this.props.user.roles[0] === 'ROLE_ADMIN_GEN') {
      return (
        <ButtonStyled
          primary
          onClick={() => {
            this.props.history.push(
              `/upload/${this.props.match.params.document_id}`
            )
          }}
          padding='9px 21px'
        >
          <FormattedMessage id={'upload.edit.content'} />
        </ButtonStyled>
      )
    }
  }

  handleLastLocation () {
    const lastLocation = window.localStorage.getItem('lastLocation-fileCard')
    if (lastLocation) {
      this.setState({
        lastLocation
      })
    }
    if (
      this.props.lastLocation &&
      !this.props.lastLocation.pathname.includes('upload')
    ) {
      this.setState({
        lastLocation: this.props.lastLocation.pathname
      })
      window.localStorage.setItem(
        'lastLocation-fileCard',
        this.props.lastLocation.pathname
      )
    }
  }

  render () {
    const {
      vertical,
      horizontal,
      open,
      lastLocation,
      snackbar,
      tokenURL,
      data
    } = this.state
    const { REACT_APP_FRONT_URL } = process.env
    const hrefLocation = window.location.href
    const haveToken = hrefLocation.includes('?token=')
    const contentId = this.props.match.params.document_id
    return (
      <>
        <WrapperHeader>
          {!haveToken && (
            <WrapperBackButton>
              <BackButton
                button
                onClick={() => {
                  this.props.goToRoute(lastLocation)
                }}
              />
            </WrapperBackButton>
          )}
          <WrapperTitle>
            <Title>
              <FormattedMessage id={'index.title.contentDetails'} />
            </Title>
            {!haveToken && (
              <WrapperButtons>
                <WrapperShare>
                  <Tooltip
                    style={{ zIndex: '1' }}
                    title='Copy the content URL to the clipboard'
                    placement='top'
                  >
                    <Button
                      buttonRef={(node) => {
                        this.anchorEl = node
                      }}
                      secundary
                      padding='9px 21px'
                      aria-owns={open ? 'menu-list-grow' : undefined}
                      aria-haspopup='true'
                      onClick={this.handleToggle}
                      {...this.props}
                    >
                      <Icon src={IconShare} className='Share' />
                      <FormattedMessage id={'share.url'} />
                    </Button>
                  </Tooltip>
                  <Popper open={open} anchorEl={this.anchorEl} transition>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id='menu-list-grow'
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom'
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList
                              style={{
                                borderRadius: '4px',
                                zIndex: 20
                              }}
                            >
                              <MenuItem onClick={this.handleClick}>
                                <CopyToClipboard text={hrefLocation}>
                                  <Button secundary borderNone>
                                    <FormattedMessage id={'share.url'} />
                                  </Button>
                                </CopyToClipboard>
                              </MenuItem>
                              <MenuItem onClick={this.handleClick}>
                                <CopyToClipboard
                                  text={`${REACT_APP_FRONT_URL}/file-card/${contentId}?token=${tokenURL}`}
                                >
                                  <Button secundary borderNone>
                                    <FormattedMessage id={'share.url.public'} />
                                  </Button>
                                </CopyToClipboard>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </WrapperShare>
                {this.renderButton()}
              </WrapperButtons>
            )}
          </WrapperTitle>
        </WrapperHeader>
        <CardFileProduct {...this.props} tempDocument={data} />
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={snackbar}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id='message-id'>
              <FormattedMessage id={'copy.url.message'} />
            </span>
          }
        />
      </>
    )
  }
}

export default withLastLocation(
  connect(
    (state) => ({
      user: state.userReducer.user
    }),
    { goToRoute }
  )(FileCard)
)
