import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import { addCategory, removeError } from '../../../../../modules/admin/content/categories'
import { getBrands } from '../../../../../modules/admin/brands'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class AddCategory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      category: {},
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  componentDidMount () {
    this.props.getBrands()
    this.props.removeError()
  }

  changeName (e) {
    const { category } = this.state
    category.name = e.target.value
    this.setState({
      category
    })
  }

  changeSelect (e, value) {
    const { category } = this.state
    category.brand_id = value
    this.setState({
      category
    })
  }

  chekedFiles () {
    let validate = false
    if (this.state.category.name && this.state.category.brand_id) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.addCategory(this.state.category)
    }
  }

  getOptions () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  render () {
    const { category, isSubmit } = this.state
    const { intl, error } = this.props
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.area.add'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/content/areas'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    onChange={this.changeName}
                    placeholder='Ex. Social Media'
                    type='text'
                    error={!category.name && isSubmit}
                    helperText={
                      !category.name && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions()}
                    onChange={this.changeSelect}
                    required
                    label='Brand *'
                    error={!category.brand_id && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/content/areas`)}
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </div>
    )
  }
}

export default connect(
  state => ({
    category: state.categoriesReducer.category,
    brands: state.brandsReducer.brands,
    isFetching: state.categoriesReducer.isFetching,
    error: state.categoriesReducer.error
  }),
  { addCategory, getBrands, goToRoute, removeError }
)(injectIntl(AddCategory))
