import { request, requestDownload } from '../../utils/request'

export const documentRequest = id => ({
  type: 'DOCUMENT/GET_DOCUEMENT_REQUEST',
  id
})

export const documentSucces = document => ({
  type: 'DOCUMENT/GET_DOCUEMENT_SUCCESS',
  document
})

export const documentFailure = error => ({
  type: 'DOCUMENT/GET_DOCUEMENT_FAILURE',
  error
})

export const getDocument = id => {
  return function (dispatch) {
    dispatch(documentRequest(id))

    const requestOptions = {
      method: 'GET'
    }
    const root = 'documents'
    const route = `${root}/${id}`
    return request(route, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(documentFailure(res.error))
          return false
        }

        dispatch(documentSucces(res))
        return true
      })
      .catch(err => {
        dispatch(documentFailure(err))
      })
  }
}

export const downloadFilesRequest = docsId => ({
  type: 'DOCUMENT/DOWNLOADFILES_REQUEST',
  docsId
})

export const downloadFilesSucces = document => ({
  type: 'DOCUMENT/DOWNLOADFILES_SUCCESS',
  document
})

export const downloadFilesFailure = error => ({
  type: 'DOCUMENT/DOWNLOADFILES_FAILURE',
  error
})

export const downloadFiles = docsId => {
  return function (dispatch, getState) {
    dispatch(downloadFilesRequest(docsId))

    const body = {
      docId: getState().documentReducer.document.id,
      docsFiles: docsId
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    let root = 'documents/download/zip?'
    docsId.forEach(element => {
      root += `ids[]=${element}&`
    })
    return request(root, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(downloadFilesFailure(res.error))
          return false
        }
        dispatch(downloadFilesSucces(res.path))
        return requestDownload(res.path)
      })
      .catch(err => {
        dispatch(downloadFilesFailure(err))
      })
  }
}

export const removeFileRequest = docsId => ({
  type: 'DOCUMENT/REMOVE_FILE_REQUEST',
  docsId
})

export const removeFileSucces = document => ({
  type: 'DOCUMENT/REMOVE_FILE_SUCCESS',
  document
})

export const removeFileFailure = error => ({
  type: 'DOCUMENT/REMOVE_FILE_FAILURE',
  error
})

export const removeFiles = fileId => {
  return function (dispatch, getState) {
    dispatch(removeFileRequest(fileId))

    const body = {
      id: fileId
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    let root = 'documents/delete/file'

    return request(root, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(removeFileFailure(res.error))
          return false
        }
        dispatch(removeFileSucces(res.path))
      })
      .catch(err => {
        dispatch(removeFileFailure(err))
      })
  }
}
