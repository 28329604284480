import { request } from '../../utils/request'

const getRolesRequest = () => ({ type: 'ROLES/DATA_REQUEST' })
const getRolesSuccess = roles => ({
  type: 'ROLES/DATA_SUCCESS',
  roles
})
const getRolesFailure = error => ({
  type: 'ROLES/DATA_FAILURE',
  error
})

export const getRoles = () => {
  return dispatch => {
    dispatch(getRolesRequest())
    // Roles
    const requestOptions = {
      method: 'GET'
    }

    return request(`roles`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getRolesFailure(res.error))
          return false
        }
        dispatch(getRolesSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getRolesFailure(err))
      })
  }
}
