export default {
  'index.header': 'en header',
  'index.latestReleases': 'Latest releases in BrandHub',
  'index.title.contentDetails': 'Content details',
  'related.content': 'Related content',
  'share.url': 'Share URL',
  'share.url.public': 'Share public URL ',
  'copy.url.message':
    'The URL of the content has been correctly copied to your clipboard.',
  save: 'SAVE CHANGES',
  'modal.titleDefault': 'Delete item',
  'modal.titlePassword': 'Recover password',
  'modal.body.remove':
    "'{name}' is going to be deleted and this action cannot be undone.",
  'modal.body.password':
    'Send password modification email to the next user? {name}',
  // header
  'header.item.latestRealeases': 'Latest releases in BrandHub',
  'header.item.products': 'Products',
  'header.item.categories': 'Categories',
  'header.item.brand': 'Brand',
  // search
  'search.title': 'Document search',
  'search.textSearch': `Type in something (ej. "banner") - If you don't type anything all documents will be shown`,
  'search.buttonSearch': 'Search',
  'search.textInstructions': `If you want to search in specific Brand, Category, Product area or Language, deselect and choose`,
  'search.checkbox.allBrands': 'All Brands',
  'search.checkbox.allProducts': 'All Product areas',
  'search.checkbox.allLanguages': 'All Languages',
  'search.checkbox.allCategories': 'All Categories',
  'search.text':
    'Use the search engine to quickly find the content you need from the TEKA BrandHub',
  // menu list
  'menuList.profile': 'Profile',
  'menuList.account': 'My account',
  'menuList.admin': 'Admin area',
  'menuList.logout': 'Logout',
  // login
  'login.title': 'Please Sign In',
  'login.input.username.placeholder': 'Username',
  'login.input.email.placeholder': 'Email',
  'forget.title': 'Set your email',
  'login.input.password.placeholder': 'Password',
  'login.button.submit.text': 'Login',
  'login.link.forgot.text': 'Forgot Password?',
  'login.link.login.text': 'Back to login',
  'forget.button.submit.text': 'Recover password',
  'forgot.input.newPassword.placeholder': 'New password',
  'forgot.input.newPasswordRepeat.placeholder': 'Repeat the new password',
  'forget.container.title': 'Set a new password',
  'login.mailSend': 'Email has been send',
  // card
  'card.new': 'NEW',
  'card.view': 'View',
  // admin
  'admin.add': 'Add more content',
  'admin.add.brands': 'Add more brands',
  'admin.add.brands.label': 'Add a brand image:',
  'admin.edit.brands.label': 'Edit a brand image:',
  'admin.add.categories': 'Add more categories',
  'admin.add.subcategories': 'Add more subcategories',
  'admin.add.types': 'Add more types',
  'admin.add.hashtags': 'Add more hashtags',
  'admin.add.countries': 'Add more countries',
  'admin.add.users': 'Add more users',
  'admin.menu.user': 'Users',
  'admin.menu.brands': 'Brands',
  'admin.menu.content': 'Content',
  'admin.menu.content.areas': 'Content Categories',
  'admin.menu.content.subareas': 'Content Subcategories',
  'admin.menu.content.types': 'Content Types',
  'admin.menu.content.list': 'Content List',
  'admin.menu.content.hashtags': 'Content Hashtags',
  'admin.menu.countries': 'Countries',
  'admin.menu.notifications': 'Notifications',
  'admin.back': 'Back',
  'admin.back.content': 'Back to content',
  'admin.brand.add': 'Add Brand',
  'admin.brand.edit': 'Edit Brand',
  'admin.area.add': 'Add Category',
  'admin.area.edit': 'Edit Category',
  'admin.subarea.add': 'Add Subcategory',
  'admin.subarea.edit': 'Edit Subcategory',
  'admin.type.add': 'Add Type',
  'admin.type.edit': 'Edit Type',
  'admin.hashtag.add': 'Add Hashtag',
  'admin.hashtag.edit': 'Edit Hashtag',
  'admin.country.add': 'Add Country',
  'admin.country.edit': 'Edit Country',
  'admin.users.add': 'Add User',
  'admin.users.edit': 'Edit User',
  'admin.cancel': 'Cancel',
  'admin.submit': 'Submit',
  'your.user': 'Your User',
  // document
  'document.noFiles': 'No files uploaded',
  'document.name': 'Name:',
  'document.upload': 'Upload date',
  'document.video.resizing': 'Videos resizing is processing. Estimate time',
  'document.video.resizing.inminent': 'Video resizing is finishing',
  'document.user': 'User',
  'document.files': 'Files',
  'document.downloadAll': 'Download all files (zip)',
  'document.dounaloadSelected': 'Download selected files (zip)',
  'document.reference': 'References',
  'document.category': 'Type',
  'document.categoryupload': 'Category',
  'document.subcategory': 'Subcategory',
  'document.brand': 'Brand',
  'document.document': 'Document',
  'document.languages': 'Languages',
  'document.description': 'Description',
  'document.hashtags': 'Hashtags',
  'document.allLanguages': 'All languages',
  'document.area': 'Content area',
  'document.subarea': 'Subcontent area',
  'document.status': 'Status',
  'document.socialNetwork': 'Social Network',
  'document.download.preparing': 'Preparing your files',
  'document.download.finished':
    'Files prepared, download will start inmediately',
  // filters
  'filters.datRange': 'Date range',
  'filters.brands': 'All brands',
  'filters.formats': 'All formats',
  'filters.languages': 'All languages',
  'filters.categories': 'All categories',
  'filters.subCategories': 'All subcategories',
  'filters.types': 'All types',
  // upload
  'upload.edit.content': 'Edit Content',
  'upload.edit.tab.1': 'Summary',
  'upload.edit.tab.2': 'Main Info',
  'upload.edit.tab.3': 'Files',
  'upload.edit.tab.4': 'Thumbnail Image',
  'tooltip.text': `If checkbox is marked it'll resize any 16:9 video to the following resolutions: HD+ (2560x1440 - 2K), HD (1920x1080 - FHD), SD+ (1280x720 - Web /Mobile) and SD. Videos with lower resolution won't be resized to any higher version.`,
  'upload.success.upload': 'The content has been successfully uploaded',
  'upload.success.uploadPending':
    'Remember that the content is in pending status until an administrator approves it',
  'upload.leave.title': 'Leave content upload process?',
  'upload.leave.text':
    'If you leave the upload process all changes made so far will be lost',
  'edit.prevent.save.title': 'You need complete fields',
  'edit.prevent.save.text':
    'You have some required fields incomplete. You need complete them to continue',
  'edit.uploading': 'Saving changes',
  'edit.uploaded': 'Saved changes',
  // errors
  'error.wrong_email_or_password': `We can't log you in. Please check your account credentials!`,
  'error.default.error': 'Unexpected error has ocurred',
  'error.user.edit.password': `Passwords don't match`,
  'error.duplicate_email': `The email or the username are in use`,
  'forms.required': 'This field is required',
  'error.user_not_found':
    'This email does not correspond to any registered user',
  'err.modal.user.delete': 'This user can not be deleted',
  // gestor-list
  'gestor.list.noResults': 'No results found',
  'gestor.list.loading': 'loading...',
  // Dropzone
  'dropzone.text':
    'Drag and drop into this area the image you want to load or ',
  'dropzone.link': ' select it ',
  'dropzone.second.text': ' from your device',
  // Unsubscribe
  'unsubscribe.title': 'Unsubscribe to the Teka BrandHUB Weekly Status',
  'unsubscribe.email': 'The email',
  'unsubscribe.email2': 'will be unsubscribed.',
  'unsubscribe.cancel': 'Cancel',
  'unsubscribe.button': 'Unsubscribe',
  // Resubscribe
  'resubscribe.title':
    'Done! You are not going to receibe more Teka BrandHUB Weekly Status',
  'resubscribe.text': 'Opps! Was that a mistake?',
  'resubscribe.button': 'Resubscribe',
  'resubscribe.future.text':
    'If you want to come back in the future you have to get in touch with this email: abernal-external@teka.com'
}
