import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import SelectUI from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import {} from './styles'
import PropTypes from 'prop-types'

const MockOptions = [
  {
    value: 0,
    label: 'Select...'
  },
  {
    value: 10,
    label: 'ten'
  },
  {
    value: 20,
    label: 'twenty'
  },
  {
    value: 30,
    label: 'thirty'
  }
]

const buildOptions = props => {
  const res = []
  const options = props.options || MockOptions || []
  options.forEach(element => {
    res.push(<MenuItem key={element.value} value={element.value}>{element.label}</MenuItem>)
  })
  return res
}

class Select extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      value: this.props.defaultValue || 0
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    if (this.state.value !== (this.props.defaultValue || 0)) {
      this.setState({ selected: true })
    }
  }

  handleChange (event) {
    let selected = this.state.select
    const newValue = event.target.value
    if (!selected && newValue !== (this.props.defaultValue || 0)) {
      selected = true
    }
    this.setState({ value: newValue, selected })
    if (this.props.onChange) {
      this.props.onChange(event, newValue)
    }
  }
  render () {
    return (
      <>
        <FormControl
          styles={{ marginTop: '16px', marginBottom: '8px' }}
          fullWidth
          margin='dense'
          variant='outlined'
          error={this.props.error}
          required={this.props.required}
          disabled={this.props.disabled}
        >
          <SelectUI
            value={this.props.value || this.state.value}
            onChange={this.handleChange}
            required={this.props.required}
            input={
              <OutlinedInput
                labelWidth={0}
                name={this.props.label}
                id='outlined-age-simple'
              />
            }
          >
            {buildOptions(this.props)}
          </SelectUI>
        </FormControl>
      </>
    )
  }
}

Select.propTypes = {
  padding: PropTypes.string
}

export default Select
