import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import { addBrand, removeError } from '../../../../modules/admin/brands'
import { goToRoute } from '../../../../modules/navigation'

import Button from '../../../../components/button'
import BackButton from '../../../../components/back-button'
import { FormBoxInput } from '../../../../components/form'
import Dropzone from '../../../../components/dropzone'
import ErrorSpan from '../../../../components/errorSpan'
import getError from '../../../../utils/errors'
import getBase64 from '../../../../utils/base64'

import { AddAvatar } from '../../../../assets/img'
import { Form, Title, Icon, WrapperButton, Label } from './styles'

class AddBrand extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      brand: {},
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.changeAvatar = this.changeAvatar.bind(this)
    this.checkedFiles = this.checkedFiles.bind(this)
  }

  componentDidMount () {
    this.props.removeError()
  }

  changeName (e) {
    const { brand } = this.state
    brand.name = e.target.value
    this.setState({
      brand
    })
  }

  changeAvatar (acceptedFiles) {
    const { brand } = this.state
    brand.newIcon = URL.createObjectURL(acceptedFiles[0])
    if (acceptedFiles && acceptedFiles[0]) {
      this.setState({
        brand
      })
    }
    getBase64(acceptedFiles[0], result => {
      brand.icon = result
      this.setState({
        brand
      })
    })
  }

  checkedFiles () {
    let validate = false
    if (this.state.brand.name && this.state.brand.icon) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    this.checkedFiles()
    if (this.checkedFiles()) {
      this.props.addBrand(this.state.brand)
    }
  }

  render () {
    const { brand, isSubmit } = this.state
    const { intl, error } = this.props
    return (
      <Form onSubmit={this.onSubmit}>
        <Flex column style={{ textAlign: 'center' }}>
          <Box mb={2} w={1}>
            <Title>
              <FormattedMessage id={'admin.brand.add'} />
            </Title>
          </Box>
          {error ? (
            <ErrorSpan>{intl.formatMessage({ id: getError(error) })}</ErrorSpan>
          ) : (
            ''
          )}
          <WrapperButton>
            <BackButton
              to='/admin/brands'
              text={intl.formatMessage({ id: 'admin.back' })}
            />
          </WrapperButton>
          <Flex justify='center' style={{ textAlign: 'left' }}>
            <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
              <Box mb={3} w={1}>
                <FormBoxInput
                  label='Name *'
                  defaultValue={brand.name}
                  onChange={this.changeName}
                  placeholder='Ex. Teka'
                  type='text'
                  error={!brand.name && isSubmit}
                  helperText={
                    !brand.name && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Flex column>
                <Box mb={2} w={1}>
                  <Label>
                    <FormattedMessage id={'admin.add.brands.label'} />
                  </Label>
                </Box>
              </Flex>
              <Flex justify='space-between' style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <Icon
                    src={!brand.icon ? AddAvatar : brand.newIcon}
                    className='Image'
                  />
                </Box>
                <Box mb={3} w={1}>
                  <Dropzone
                    onChange={this.changeAvatar}
                    accept='image/jpeg, image/png, image/svg+xml'
                    multiple={false}
                  >
                    <FormattedMessage id={'dropzone.text'} />
                    <u>
                      <FormattedMessage id={'dropzone.link'} />
                    </u>
                    <FormattedMessage id={'dropzone.second.text'} />
                  </Dropzone>
                </Box>
              </Flex>
              <Flex justify='space-between'>
                <Button
                  secundary
                  onClick={() => this.props.goToRoute(`/admin/brands`)}
                  padding='5px 40px'
                >
                  <FormattedMessage id={'admin.cancel'} />
                </Button>
                <Button primary='true' type='submit' padding='5px 40px'>
                  <FormattedMessage id={'admin.submit'} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    )
  }
}

export default connect(
  state => ({
    brand: state.brandsReducer.brand,
    isFetching: state.brandsReducer.isFetching,
    error: state.brandsReducer.error
  }),
  { addBrand, goToRoute, removeError }
)(injectIntl(AddBrand))
