const initialState = {
  isFetching: false,
  documents: [],
  hasMore: true,
  currentPage: 0,
  category: null,
  subcategory: null,
  totalResults: 0,
  currentSearch: 0,
  hashtags: []
}

export const List = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST/SEARCH_HASHTAGS':
      return {
        ...state,
        hashtags: action.hashtags
      }
    case 'LIST/GET_DOCUMENT_SEARCH_REQUEST':
    case 'LIST/GET_LATEST_RELEASES_REQUEST':
      return {
        ...state,
        isFetching: true,
        hasMore: true,
        category: null
      }
    case 'LIST/GET_DOCUEMENTS_BY_CATEGORY': {
      return {
        ...state,
        isFetching: true,
        category: action.category,
        hasMore: true
      }
    }
    case 'LIST/GET_DOCUMENT_SEACH_SUCCESS':
    case 'LIST/GET_LATEST_RELEASES_SUCCESS':
    case 'LIST/GET_DOCUEMENTS_BY_CATEGORY_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        documents: [...state.documents, ...action.documents],
        hasMore: action.hasMore,
        currentPage: state.currentPage + 1
      }
    }
    case 'LIST/GET_DOCUMENT_SEARCH_FAILURE':
    case 'LIST/GET_LATEST_RELEASES_FAILURE':
    case 'LIST/GET_DOCUEMENTS_BY_CATEGORY_FAILURE': {
      return {
        ...state,
        isFetching: false,
        documents: [],
        hasMore: false,
        currentPage: 0,
        category: null
      }
    }
    case 'LIST/RESET_LIST': {
      return {
        ...state,
        documents: [],
        hasMore: true,
        currentPage: 0,
        totalResults: 0
      }
    }
    case 'LIST/GET_DOCUMENT_SEARCH_TOTAL_FAILURE': {
      return {
        ...state,
        totalResults: 0
      }
    }
    case 'LIST/GET_DOCUMENT_SEARCH_TOTAL_SUCCESS': {
      return {
        ...state,
        totalResults: action.total.length,
        totalResultsRaw: action.total
      }
    }
    case '@@router/LOCATION_CHANGE': {
      const res = {
        ...state
      }
      if (action.payload.location.pathname.indexOf('results') === -1) {
        // res.totalResults = 0
      }
      if (action.payload.location.pathname.indexOf('search') !== -1) {
        res.category = null
      }
      return {
        ...res
      }
    }
    case 'LIST/DOCUMENT_SEARCH_INC': {
      return {
        ...state,
        currentSearch: action.currentSearch
      }
    }
    case 'LIST/GET_DOCUMENT_PREDICTIVE_SUCCESS': {
      return {
        ...state,
        resultsPredictive: action.total
      }
    }
    case 'LIST/GET_DOCUMENT_PREDICTIVE_FAILURE': {
      return {
        ...state,
        resultsPredictive: null
      }
    }
    case 'LIST/REMOVE_PREDICTIVE':
      return {
        ...state,
        resultsPredictive: null
      }
    default:
      return state
  }
}
