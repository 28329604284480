import styled from 'styled-components'

export const Form = styled.form`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

export const Title = styled.h1`
  text-align: center;
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.64px;
  line-height: 32px;
`

export const Subtitle = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 0;
`

export const Icon = styled.img`
  width: 90%;
  max-height: 160px;
  border-radius: 1px solid grey;
  margin: 0;
  padding: 0;
`

export const WrapperButton = styled.div`
  position: absolute;
  top: 160px;
`

export const Label = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
`
