import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'

export const Span = styled.span`
  ${({ bold, theme }) => bold && `font-weight ${theme.fontWeights.bold}`}
`

export const Title = styled.h1`
  text-align: left;
  margin: 0;
`

export const Subtitle = styled.h2`
  text-align: left;
  font-weight: normal;
  margin: 0;
`

export const TableHeader = styled.span`
  display: table-cell;
  text-align: ${(p) => (p.right ? 'right' : p.center ? 'center' : 'left')};
  vertical-align: inherit;
  margin-right: ${(p) => p.mr || 0};
  ${(p) => (p.width ? `width: ${p.width};` : '')}
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`

export const TableCheckbox = styled(Checkbox)`
  self-align: right;
`

export const TableLinearProgress = styled(LinearProgress)`
  margin-right: 20px;
`

export const Icon = styled.img``

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
`

export const Text = styled.p`
  padding-right: 10px;
`

export const Ellipsis = styled.div`
  margin: 0 5px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
`
