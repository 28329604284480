import React from 'react'
import ReactDOM from 'react-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import './index.css'
import App from './containers/app'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { addLocaleData } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import store, { history } from './store'
import muiTheme from './muitheme'

import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import de from 'react-intl/locale-data/de'
import fr from 'react-intl/locale-data/fr'
import IntlProvider from './intl/intl'
import * as theme from './theme'
import 'typeface-exo-2'
addLocaleData([...en, ...es, ...de, ...fr])
const themeMui = createMuiTheme(muiTheme)

const target = document.querySelector('#root')
ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <MuiThemeProvider theme={themeMui}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>,
  target
)
