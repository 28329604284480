import styled from 'styled-components'
import SelectUI from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const SelectStyled = styled(SelectUI)`
  && {
    height: 32px;
    font-size: 14px;
    .outline {
      border: 1px solid;
      border-color: ${({ selected, theme }) =>
    selected
      ? `${theme.colors.black} !important`
      : 'transparent !important'};
      padding: 0;
    }
    .rooting > div:nth-of-type(1) {
      display: flex;
      justify-content: left;
      align-items: center;
      height: 32px;
      padding: 0 10px 0 10px;
      margin: 0;
    }
  }
`

export const MenuItemStyled = styled(MenuItem)``
