import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
  margin: 0 auto;
  padding-bottom: 20px;
`

export const WrapperTitle = styled.div`
  grid-column: 1;
  align-items: start;
  padding-right: 20px;
  border-right: 1px solid white;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 24px;
  margin: 0;
`

export const PartTitle = styled(Title)`
  margin-right: 10px;
`

export const WrapperCategories = styled.div`
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`
export const Category = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 16px;
  line-height: 19px;
  opacity: 1;
`
