import React from 'react'
import {} from './styles'
import SearchComponent from '../../components/search'
import { FormattedMessage } from 'react-intl'
import Title from '../../components/basics/title'

export default class Search extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <div>
        <Title>
          <FormattedMessage id={'search.title'} />
        </Title>
        <SearchComponent
          resultsPage={this.props.results}
          results
          value={this.props.query}
          {...this.props}
        />
      </div>
    )
  }
}
