import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import Countdown from 'react-countdown-now'

import FileList from './file-list'
import lang from '../../../utils/languages'

import {
  Container,
  Label,
  NameProduct,
  WrapperProp,
  WrapperLabel,
  WrapperDescription,
  WrapperName,
  Spec,
  SpecWrapper,
  Tag,
  InfoField,
  CircularProgressStyled
} from './styles'

const getValuesDocument = (document, intl) => {
  const reference = {
    label: intl.formatMessage({
      id: 'document.reference'
    }),
    value: document.reference ? document.reference : ''
  }

  const category = {
    label: intl.formatMessage({
      id: 'document.category'
    }),
    value:
      document.types && document.types.length
        ? document.types.map((x) => x.name).join(', ')
        : ''
  }

  const brand = {
    label: intl.formatMessage({
      id: 'document.brand'
    }),
    value: document.brand ? document.brand.name : ''
  }

  const changeDescription = () =>
    document.description.replace(/(\r\n|\n|\t)/g, '<br>')

  const description = {
    label: intl.formatMessage({
      id: 'document.description'
    }),
    value: document.description ? (
      <div dangerouslySetInnerHTML={{ __html: `${changeDescription()}` }} />
    ) : null
  }

  const hashtags = {
    label: intl.formatMessage({
      id: 'document.hashtags'
    }),
    value:
      document.hashtags && document.hashtags.length
        ? document.hashtags.map((x) => `#${x.name}`).join(', ')
        : ''
  }

  const languages = {
    label: intl.formatMessage({
      id: 'document.languages'
    }),
    value:
      document.languages && document.languages.length
        ? document.languages.length === lang.length
          ? intl.formatMessage({
            id: 'document.allLanguages'
          })
          : document.languages.map((lang) => lang.name).join(', ')
        : '-'
  }
  const area = {
    label: intl.formatMessage({
      id: 'document.area'
    }),
    value: `${document.category ? document.category.name : '-'} > ${
      document.subcategory ? document.subcategory.name : '-'
    } `
  }
  const socialNetwork = {
    label: intl.formatMessage({
      id: 'document.socialNetwork'
    }),
    value:
      document.tags && document.tags.length
        ? document.tags.map((x) => x.name).join(', ')
        : ''
  }
  return [
    reference,
    category,
    brand,
    description,
    hashtags,
    languages,
    area,
    socialNetwork
  ]
}

class RightSide extends React.Component {
  buildTags (tags) {
    const res = []
    tags.forEach((tag) => {
      if (!tag.value) {
        return null
      }
      res.push(<Tag>{tag.value}</Tag>)
    })
    return res
  }

  buildProperties () {
    const res = []
    const props = getValuesDocument(this.props.document, this.props.intl)
    props.forEach((prop, i) => {
      if (!prop.value) {
        return null
      }
      res.push(
        <WrapperProp key={`wrapper_props_${i}_${prop}`}>
          <WrapperLabel>
            <Label>{prop.label}: </Label>
          </WrapperLabel>
          <WrapperDescription>
            <label>{prop.value}</label>
          </WrapperDescription>
        </WrapperProp>
      )
    })
    return res
  }

  renderer ({ hours, minutes, completed }) {
    if (completed) {
      // Render a complete state
      return <FormattedMessage id={'document.video.resizing.inminent'} />
    } else {
      // Render a countdown
      return (
        <span>
          <FormattedMessage id={'document.video.resizing'} /> {hours}:
          {minutes < 10 ? '0' + minutes : minutes}
        </span>
      )
    }
  }

  renderDocumentInfo (document) {
    const showWarningResize = document.files.find(
      (x) => x.need_process && x.processed !== '1'
    )

    let currentTime = new Date()
    if (showWarningResize) {
      const currentHour = currentTime.getHours()
      currentTime.setHours(currentHour + (currentHour % 2 === 0 ? 2 : 1))
      currentTime.setMinutes(15)
    }

    return (
      <Container>
        <WrapperName>
          <Label>
            <FormattedMessage id={'document.name'} />
          </Label>
          <NameProduct>{document.name}</NameProduct>
          <SpecWrapper>
            <Spec>
              <FormattedMessage id={'document.upload'} />
              {': '}
              {new Date(document.created.date).toLocaleDateString()}
            </Spec>
            <Spec>
              <FormattedMessage id={'document.user'} />
              {': '}
              {document.uploaded_by || '-'}
            </Spec>
          </SpecWrapper>
        </WrapperName>
        <div>{this.buildProperties()}</div>

        <div>
          <Label>
            <FormattedMessage id={'document.files'} />:
            {showWarningResize ? (
              <InfoField>
                <div style={{ marginRight: '10px' }}>
                  <CircularProgressStyled
                    color={'blue'}
                    style={{ width: '20px', height: '20px' }}
                  />
                </div>

                <div style={{ marginLeft: '5px', fontWeight: 'normal' }}>
                  <Countdown date={currentTime} renderer={this.renderer} />
                </div>
              </InfoField>
            ) : null}
          </Label>
          <FileList
            downloadFiles={this.props.downloadFiles}
            files={document.files}
          />
        </div>
      </Container>
    )
  }

  render () {
    return this.renderDocumentInfo(this.props.document)
  }
}

export default injectIntl(RightSide)
