import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Flex, Box } from 'reflexbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'

import videoTypes from '../../utils/types/video.json'
import Button from '../../components/button'
import IButton from '../../components/iconbutton'
import Dropzone from '../../components/dropzone'
import { uploadFile, removeFile } from './requests'
import { IconTickBlack, IconInfo, DeleteIcon } from '../../assets/img'
import Table from '../../components/table'

import {
  Title,
  Subtitle,
  TableHeader,
  TableCheckbox,
  TableLinearProgress,
  Icon,
  Wrapper,
  Text,
  Ellipsis
} from './styles'

function parseSize (bytes) {
  if (bytes / 1000000000 >= 1) {
    return `${Math.round(bytes / 1024 / 1024 / 1024)} GB`
  }
  if (bytes / 1000000 >= 1) {
    return `${Math.round(bytes / 1024 / 1024)} MB`
  }
  if (bytes / 1000 >= 1) {
    return `${Math.round(bytes / 1024)} KB`
  }
  return `${bytes}B`
}

export default injectIntl(
  ({ intl, uploadId, back, next, userId, editMode, reload, onLoading }) => {
    const [files, setFiles] = useState([])
    const [alertOpen, setAlertOpen] = useState(false)

    useEffect(() => {
      for (let f of files) {
        // If there is a new file, start uploading it
        if (f.mustUpload) {
          onLoading && onLoading()
          setFiles((files) =>
            files.map((_f) => {
              if (_f.name === f.name) {
                return {
                  ..._f,
                  mustUpload: false
                }
              }
              return _f
            })
          )
          setTimeout(() => {
            uploadFile(
              {
                doc_id: uploadId,
                file: f.file,
                need_process: false
              },
              (c, err) => {
                // Update progress
                setFiles((files) =>
                  files.map((_f) => {
                    if (_f.name === f.name) {
                      return {
                        ..._f,
                        progress: c > _f.progress ? c : _f.progress,
                        status:
                          c === 100
                            ? err
                              ? 'Error: ' + err
                              : 'Procesing'
                            : 'Uploading'
                      }
                    }
                    return _f
                  })
                )
              },
              (res) => {
                // Save file ID for need_process
                setFiles((files) =>
                  files.map((_f) => {
                    if (_f.name === f.name) {
                      return {
                        ..._f,
                        status: 'Complete',
                        id: res.id,
                        aspectRatio: res.aspect_ratio,
                        resizeCopies: !!(
                          f.resizeCopies && res.aspect_ratio === '16:9'
                        )
                      }
                    }
                    return _f
                  })
                )
                if (editMode) {
                  reload()
                }
              }
            )
          }, 1000)
        }
      }
    }, [files])

    const addedFile = (_files) => {
      for (let file of _files) {
        setFiles((files) => {
          // Files cannot be repeated
          if (files.find((f) => file.name === f.name)) {
            setAlertOpen(true)
            return files
          }
          const isVideo = file.type.indexOf('video/') !== -1
          return [
            ...files,
            {
              key: file.name,
              name: file.name,
              size: parseSize(file.size),
              progress: 0,
              status: 'Uploading',
              resizeCopies: !!isVideo,
              file: file,
              mustUpload: true
            }
          ]
        })
      }
    }

    function getColumns () {
      return [
        {
          name: 'name',
          label: 'Name',
          options: {
            customHeadRender: (columnMeta) => (
              <TableHeader width='250px' key={columnMeta.label + 'name'}>
                {columnMeta.label}
              </TableHeader>
            ),
            customBodyRender: (value) => <Ellipsis>{value}</Ellipsis>
          }
        },
        {
          name: 'size',
          label: 'Size',
          options: {
            customHeadRender: (columnMeta) => (
              <TableHeader width='100px' key={columnMeta.label + 'size'}>
                {columnMeta.label}
              </TableHeader>
            )
          }
        },
        {
          name: 'progress',
          label: 'Progress',
          options: {
            customHeadRender: (columnMeta) => (
              <TableHeader width='200px' key={columnMeta.label + 'progress'}>
                {columnMeta.label}
              </TableHeader>
            ),
            customBodyRender: (value) => (
              <TableLinearProgress
                color='primary'
                variant='determinate'
                value={value}
                key={value + 'lp'}
              />
            )
          }
        },
        {
          name: 'status',
          label: 'Status',
          options: {
            customHeadRender: (columnMeta) => (
              <TableHeader width='120px' key={columnMeta.label + 'status'}>
                {columnMeta.label}
              </TableHeader>
            ),
            customBodyRender: (value) => (
              <Flex align='center' key={value + 'type'}>
                {value === 'Complete' ? (
                  <img src={IconTickBlack} alt='tick' />
                ) : (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                )}
                <span style={{ marginLeft: 5 }}>{value}</span>
              </Flex>
            )
          }
        },
        {
          name: 'resizeCopies',
          label: 'Resize copies',
          options: {
            customHeadRender: (columnMeta) => (
              <>
                <TableHeader
                  width='100px'
                  right
                  mr='20px'
                  key={columnMeta.label + 'resize copies'}
                >
                  <Wrapper>
                    <Text>{columnMeta.label}</Text>
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'tooltip.text'
                      })}
                      placement='top-end'
                    >
                      <IButton>
                        <Icon src={IconInfo} />
                      </IButton>
                    </Tooltip>
                  </Wrapper>
                </TableHeader>
              </>
            ),
            // TODO, show checkbox only if extesion is video
            customBodyRender: (value, tableData) => {
              const nameSplit =
                tableData.rowData[0] && tableData.rowData[0].split('.')
              const extension =
                nameSplit &&
                nameSplit.length &&
                nameSplit[nameSplit.length - 1].toLowerCase()
              const aspectRatio = tableData.rowData[5]
              const isVideoWithCorrectRatio = videoTypes.find(
                (x) => x === extension && aspectRatio === '16:9'
              )

              return (
                <Flex key={value + 'tabledata'}>
                  {isVideoWithCorrectRatio ? (
                    <TableCheckbox
                      color='default'
                      checked={value}
                      onChange={() => {
                        setFiles((files) =>
                          files.map((_f) => {
                            if (_f.name === tableData.rowData[0]) {
                              return {
                                ..._f,
                                resizeCopies: !value
                              }
                            }
                            return _f
                          })
                        )
                      }}
                    />
                  ) : null}
                </Flex>
              )
            }
          }
        },
        {
          name: 'aspectRatio',
          label: 'ratio',
          options: {
            display: 'false'
          }
        },
        {
          name: 'id',
          label: ' ',
          options: {
            customHeadRender: (columnMeta) => (
              <TableHeader width='40px' key={columnMeta.label + 'status'}>
                {columnMeta.label}
              </TableHeader>
            ),
            customBodyRender: (id) => (
              <Flex justify='flex-end'>
                <IButton
                  onClick={() => {
                    setFiles((files) => files.filter((f) => id !== f.id))
                    removeFile(id)
                  }}
                >
                  <Icon src={DeleteIcon} alt='delete' />
                </IButton>
              </Flex>
            )
          }
        }
      ]
    }

    return (
      <Flex column>
        {!editMode && (
          <>
            <Box mb={2}>
              <Title>Upload Documents in 3 steps</Title>
            </Box>
            <Box mb={4}>
              <Subtitle>[Step 2]</Subtitle>
            </Box>
          </>
        )}
        <Flex w={1} column style={{ textAlign: 'left' }}>
          <Box mb={2}>
            <span style={{ fontWeight: 'bold' }}>
              Add files for this content:
            </span>
          </Box>
          <Flex mb={4} justify='center'>
            {editMode && files.find((x) => x.status !== 'Complete') ? (
              <CircularProgress />
            ) : (
              <Dropzone
                text='Drag and drop in this area the files you want to upload or click here to select them from your device'
                onChange={(data) => {
                  // setLoading(true)
                  addedFile(data)
                }}
              >
                <FormattedMessage id={'dropzone.text'} />
                <u>
                  <FormattedMessage id={'dropzone.link'} />
                </u>
                <FormattedMessage id={'dropzone.second.text'} />
              </Dropzone>
            )}
          </Flex>
          {!editMode && (
            <>
              <Box mb={1}>
                <span style={{ fontWeight: 'bold' }}>Aggregate files:</span>
              </Box>
              <Box>
                <Table
                  noSearch
                  noPagination
                  data={files}
                  columns={getColumns()}
                />
              </Box>
            </>
          )}
          {!editMode && (
            <Flex justify='space-between' mt={10}>
              <Button onClick={back} primary='true' autoFocus>
                Previous
              </Button>
              <Button
                onClick={() => {
                  next({
                    uploadId,
                    userId,
                    needProcess: files.map((f) => ({
                      id: f.id,
                      need_process: f.resizeCopies
                    }))
                  })
                }}
                primary
                autoFocus
                disabled={
                  files.find((x) => x.status !== 'Complete') || !files.length
                }
              >
                Next
              </Button>
            </Flex>
          )}
        </Flex>
        <Dialog
          onClose={() => {
            setAlertOpen(false)
          }}
          open={alertOpen}
        >
          <DialogTitle>Invalid file</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can't upload two files with the same name!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAlertOpen(false)
              }}
              primary
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Flex>
    )
  }
)
