import { request } from '../../utils/request'
import _ from 'lodash'

export const documentsByCategory = (options) => ({
  type: 'LIST/GET_DOCUEMENTS_BY_CATEGORY',
  category: options.category
})

export const documentsByCategorySucces = (documents, hasMore) => ({
  type: 'LIST/GET_DOCUEMENTS_BY_CATEGORY_SUCCESS',
  documents,
  hasMore
})

export const documentsByCategoryFailure = (options) => ({
  type: 'LIST/GET_DOCUEMENTS_BY_CATEGORY_FAILURE',
  category: options.category
})

export const latestReleasesRequest = (options) => ({
  type: 'LIST/GET_LATEST_RELEASES_REQUEST',
  category: options.category
})

export const latestReleasesSuccess = (documents, hasMore) => ({
  type: 'LIST/GET_LATEST_RELEASES_SUCCESS',
  documents,
  hasMore
})

export const latestReleasesFailure = (options) => ({
  type: 'LIST/GET_LATEST_RELEASES_FAILURE',
  category: options.category
})

export const documentSearch = (options) => ({
  type: 'LIST/GET_DOCUMENT_SEARCH_REQUEST',
  category: options.category
})

export const documentSearchSuccess = (documents, hasMore) => ({
  type: 'LIST/GET_DOCUMENT_SEACH_SUCCESS',
  documents,
  hasMore
})

export const documentSearchFailure = (options) => ({
  type: 'LIST/GET_DOCUMENT_SEARCH_FAILURE',
  category: options.category
})

export const documentSearchTotalSuccess = (total) => ({
  type: 'LIST/GET_DOCUMENT_SEARCH_TOTAL_SUCCESS',
  total
})

export const documentSearchTotalFailure = (options) => ({
  type: 'LIST/GET_DOCUMENT_SEARCH_TOTAL_FAILURE'
})

export const documentPredictiveSuccess = (total) => ({
  type: 'LIST/GET_DOCUMENT_PREDICTIVE_SUCCESS',
  total
})

export const documentPredictiveFailure = (options) => ({
  type: 'LIST/GET_DOCUMENT_PREDICTIVE_FAILURE'
})

export const documentSearchInc = (currentSearch) => ({
  type: 'LIST/DOCUMENT_SEARCH_INC',
  currentSearch
})

export const updateHashtags = (hashtags) => ({
  type: 'LIST/SEARCH_HASHTAGS',
  hashtags
})

export const addHashtag = (hashtag) => {
  return function (dispach, getState) {
    const hashtags = getState().listReducer.hashtags
    const canAdd = !!_.find(hashtags, { id: hashtag.id })
    !canAdd && dispach(updateHashtags([...hashtags, hashtag]))
  }
}

export const deleteHashtag = (hashtag) => {
  return function (dispach, getState) {
    const hashtags = getState().listReducer.hashtags
    const newH = _.remove(hashtags, (h) => h.id !== hashtag.id)
    dispach(updateHashtags(newH))
  }
}

export const getLatestReleases = (options) => {
  return function (dispatch) {
    dispatch(documentsByCategory(options))
    const requestOptions = {
      method: 'GET'
    }
    const root = 'documents'
    const route = `${root}?skip=${options.skip}&limit=${options.limit}&date_limit=30`
    return request(route, requestOptions)
      .then((res) => {
        let hasMore = true
        if (res.error || res.code === 401) {
          dispatch(documentsByCategoryFailure(res.error))
          return false
        }
        if (
          (res && res.length && res.length < options.limit) ||
          !res ||
          !res.length
        ) {
          hasMore = false
        }
        dispatch(documentsByCategorySucces(res, hasMore))
        return true
      })
      .catch((err) => {
        dispatch(documentsByCategoryFailure(err))
      })
  }
}

export const getDocumentsByCategory = (options) => {
  return function (dispatch, getState) {
    dispatch(documentsByCategory(options))
    const requestOptions = {
      method: 'GET'
    }
    const root = 'documents'
    const route = `${root}?skip=${options.skip}&limit=${
      options.limit
    }&category_id=${options.category}&${getQueryFilter(getState(), true)}`
    dispatch(getTotalDocument(`${root}?${getQueryFilter(getState())}`))
    return request(route, requestOptions)
      .then((res) => {
        let hasMore = true
        if (res.error || res.code === 401) {
          dispatch(documentsByCategoryFailure(res.error))
          return false
        }
        if (
          (res && res.length && res.length < options.limit) ||
          !res ||
          !res.length
        ) {
          hasMore = false
        }
        dispatch(documentsByCategorySucces(res, hasMore))
        return true
      })
      .catch((err) => {
        dispatch(documentsByCategoryFailure(err))
      })
  }
}

export const getDocumentsBySearch = (options) => {
  return function (dispatch, getState) {
    const tags = options.hashtags
      ? _.join(
        options.hashtags.map((h) => `hashtags[]=${h.id}&`),
        ''
      )
      : ''
    dispatch(documentSearch(options))
    const requestOptions = {
      method: 'GET'
    }
    const root = 'documents'
    const route = tags
      ? `${root}?${tags}skip=${options.skip}&limit=${
        options.limit
      }&${getQueryFilter(getState())}`
      : `${root}?skip=${options.skip}&limit=${options.limit}&${getQueryFilter(
        getState()
      )}`

    dispatch(getTotalDocument(`${root}?${getQueryFilter(getState())}`))
    const currentSearch = getState().listReducer.currentSearch

    return request(route, requestOptions)
      .then((res) => {
        let hasMore = true

        if (res.error || res.code === 401) {
          dispatch(documentSearchFailure(res.error))
          return false
        }
        if (
          (res && res.length && res.length < options.limit) ||
          !res ||
          !res.length
        ) {
          hasMore = false
        }
        if (currentSearch === getState().listReducer.currentSearch) {
          dispatch(documentSearchSuccess(res, hasMore))
        }

        return true
      })
      .catch((err) => {
        dispatch(documentSearchFailure(err))
      })
  }
}

export const getTotalDocument = (url) => {
  return function (dispatch, getState) {
    const requestOptions = {
      method: 'GET'
    }
    const currentSearch = getState().listReducer.currentSearch + 1
    dispatch(documentSearchInc(currentSearch))
    return request(url, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(documentSearchTotalFailure(res.error))
          return false
        }
        if (currentSearch === getState().listReducer.currentSearch) {
          dispatch(documentSearchTotalSuccess(res))
        }

        return true
      })
      .catch((err) => {
        dispatch(documentSearchTotalFailure(err))
      })
  }
}

export const getPredictiveDocuments = (query, currentSearch) => {
  return function (dispatch, getState) {
    const requestOptions = {
      method: 'GET'
    }
    const categoryId =
      getState().searchReducer.data.filter &&
      getState().searchReducer.data.filter.category_id
    let url = `documents/predictive/search?query=${query}`
    if (categoryId) {
      url += `&category_id=${categoryId}`
    }
    return request(url, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(documentPredictiveFailure(res.error))
          return false
        }
        if (currentSearch() === query) {
          dispatch(documentPredictiveSuccess(res))
        }

        return true
      })
      .catch((err) => {
        dispatch(documentPredictiveFailure(err))
      })
  }
}

const getQueryFilter = (state, isCategory) => {
  let queryString = ''
  if (state.searchReducer.data.query) {
    queryString += `query=${state.searchReducer.data.query}&`
  }
  const filter = state.searchReducer.data.filter
  if (filter) {
    if (filter.date && filter.date.from && filter.date.to) {
      queryString += `date_start=${filter.date.from}&date_end=${filter.date.to}&`
    }
    if (filter.type) {
      queryString += `type=${filter.type}&`
    }
    if (filter.brand_id) {
      queryString += `brand_id=${filter.brand_id}&`
    }
    if (filter.language_id) {
      queryString += `language_id=${filter.language_id}&`
    }
    if (filter.category_id) {
      queryString += `category_id=${filter.category_id}&`
    }
    if (filter.subcategory_id) {
      queryString += `subcategory_id=${filter.subcategory_id}&`
    }
    if (filter.typeId) {
      queryString += `typeId=${filter.typeId}&`
    }
  }
  return queryString
}

export const resetList = () => ({
  type: 'LIST/RESET_LIST'
})

export const removeResultPredictive = () => ({
  type: 'LIST/REMOVE_PREDICTIVE'
})
