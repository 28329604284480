import React from 'react'
import { MenuOverlay as MenuOverlayStyled, ContentMenuOverlay } from './styles'

class MenuOverlay extends React.Component {
  render () {
    if (!this.props.opened) {
      return null
    }
    return (
      <MenuOverlayStyled onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        <ContentMenuOverlay>{this.props.children}</ContentMenuOverlay>
      </MenuOverlayStyled>
    )
  }
}

export default MenuOverlay
