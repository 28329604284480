import { request } from '../../utils/request'
import axios from 'axios'
const { REACT_APP_API_URL } = process.env

export async function getTokenPublicURL (id) {
  try {
    const res = await request('auth/temporary/token/' + id)
    return res.token
  } catch (error) {
    console.log('error getTokenPublicURL', error)
  }
}

export async function getPublicURL (token) {
  try {
    const res = await axios.get(`${REACT_APP_API_URL}/documents/temporary?token=${token}`)
    return res.data
  } catch (error) {
    console.log('error getPublicURL', error)
  }
}
