import React, { useState, useCallback, useRef, useEffect } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
import Button from '../button'

const pixelRatio = window.devicePixelRatio || 1

function getResizedCanvas (canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement('canvas')
  tmpCanvas.width = newWidth
  tmpCanvas.height = newHeight
  const ctx = tmpCanvas.getContext('2d')
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  )

  return tmpCanvas
}

function generateDownload (previewCanvas, crop, onFinish) {
  if (!crop || !previewCanvas) {
    return
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height)
  const url = canvas.toDataURL('image/jpeg', 1.0)

  window
    .fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new window.File([blob], 'Thumbnail', { type: 'image/png' })
      onFinish(file)
    })
}

export default function Crop ({ upImgClick, onFinish, onClose }) {
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: 1 / 1 })
  const [completedCrop, setCompletedCrop] = useState(null)

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    image.crossOrigin = 'anonymous'
    const canvas = previewCanvasRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = completedCrop.width * pixelRatio
    canvas.height = completedCrop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    )
  }, [completedCrop])

  return (
    <div className='Crop'>
      <ReactCrop
        src={upImgClick}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={setCrop}
        onComplete={setCompletedCrop}
      />
      <div style={{ display: 'none' }}>
        <p style={{ fontWeight: 'bold' }}>Preview Thumbnail</p>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: Math.round((completedCrop && 250) || 0),
            height: Math.round((completedCrop && 250) || 0)
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button primary='true' onClick={onClose}>
          Cancel
        </Button>
        <Button
          primary='true'
          disabled={completedCrop && !completedCrop.width}
          onClick={() =>
            generateDownload(previewCanvasRef.current, completedCrop, onFinish)
          }
        >
          Crop and finish
        </Button>
      </div>
    </div>
  )
}
