import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import MenuListAdmin from '../../../../components/menulistadmin'
import { MenuWrapper, Wrapper, NavLinkStyled } from './styles'

const content = [
  {
    name: 'Contents List',
    route: '/admin/content/list'
  },
  {
    name: 'Content Categories',
    route: '/admin/content/areas'
  },
  {
    name: 'Content Subcategories',
    route: '/admin/content/subareas'
  },
  {
    name: 'Content Types',
    route: '/admin/content/types'
  },
  {
    name: 'Content Tags',
    route: '/admin/content/hashtags'
  }
]

class LeftMenu extends React.Component {
  constructor (props) {
    super(props)
    this.adminZoneAccess = this.adminZoneAccess.bind(this)
  }

  adminZoneAccess (role) {
    if (role === 'ROLE_ADMIN_REG') {
      return (
        <Wrapper>
          <NavLinkStyled activeClassName='active' to='/admin/users' exact>
            <FormattedMessage id={'admin.menu.user'} />
          </NavLinkStyled>
        </Wrapper>
      )
    } else {
      return (
        <Fragment>
          <Wrapper>
            <NavLinkStyled activeClassName='active' to='/admin/users' exact>
              <FormattedMessage id={'admin.menu.user'} />
            </NavLinkStyled>
          </Wrapper>
          <Wrapper>
            <NavLinkStyled activeClassName='active' to='/admin/brands' exact>
              <FormattedMessage id={'admin.menu.brands'} />
            </NavLinkStyled>
          </Wrapper>
          <Wrapper>
            <MenuListAdmin options={content}>
              <FormattedMessage id={'admin.menu.content'} />
            </MenuListAdmin>
          </Wrapper>
          <Wrapper>
            <NavLinkStyled activeClassName='active' to='/admin/countries' exact>
              <FormattedMessage id={'admin.menu.countries'} />
            </NavLinkStyled>
          </Wrapper>
          <Wrapper>
            <NavLinkStyled
              activeClassName='active'
              to='/admin/notifications'
              exact
            >
              <FormattedMessage id={'admin.menu.notifications'} />
            </NavLinkStyled>
          </Wrapper>
        </Fragment>
      )
    }
  }

  render () {
    const { user } = this.props
    if (user != null) {
      return <MenuWrapper>{this.adminZoneAccess(user.roles[0])}</MenuWrapper>
    }
    return null
  }
}

export default connect(
  (state) => ({
    user: state.userReducer.user,
    isFetching: state.userReducer.isFetching
  }),
  {}
)(injectIntl(LeftMenu))
