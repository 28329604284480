import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditCategory from '../../containers/admin/content/categories/editcategory'
import AddCategory from '../../containers/admin/content/categories/addcategory'
import Categories from '../../containers/admin/content/categories'

class RouterCategories extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/content/areas`}
          component={Categories}
        />
        <Route
          path={`${this.props.match.path}/content/area/edit/:id`}
          component={EditCategory}
        />
        <Route
          path={`${this.props.match.path}/content/area/add`}
          component={AddCategory}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterCategories)
