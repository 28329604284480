import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  openDialogTable,
  navigationHandleRows,
  removeError
} from '../../modules/navigation'
import SimpleDialog from '../../components/simpleDialog'
import { Spinner } from '../../components/spinner'
import getError from '../../utils/errors'

import { TableStyled, Warnning } from './styles'

class Table extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalActive: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleRowPerpage = this.handleRowPerpage.bind(this)
  }

  handleClose () {
    this.props.openDialogTable(false)
    this.props.onClose && this.props.onClose()
    this.props.removeError()
  }

  handleOk () {
    this.props.cb && this.props.cb()
    this.props.onOk && this.props.onOk()
    this.props.openDialogTable(false)
  }

  handleRowPerpage (number) {
    this.props.navigationHandleRows(number)
  }

  getError () {
    const { intl, errorModal } = this.props
    const error = errorModal
    const errorText = getError(error)
    return errorText ? intl.formatMessage({ id: errorText }) : ''
  }

  render () {
    const {
      title,
      data,
      minWidth,
      columns,
      isFetching,
      rowsPerPage,
      errorModal,
      forgetPassword,
      ...props
    } = this.props
    const options = {
      filterType: 'checkbox',
      sort: true,
      search: !this.props.noSearch,
      print: false,
      download: false,
      viewColumns: false,
      pagination: !this.props.noPagination,
      filter: false,
      selectableRows: false,
      responsive: 'scroll',
      rowsPerPage,
      rowsPerPageOptions: [10, 50, 100],
      customSort: (data, colIndex, order) => {
        const dataFile = data.sort((a, b) => {
          a = a.data[colIndex] || ''
          b = b.data[colIndex] || ''
          if (order === 'asc') {
            return a.toString().localeCompare(b, undefined, { numeric: true })
          } else if (order === 'desc') {
            return b.toString().localeCompare(a, undefined, { numeric: true })
          }
          return null
        })
        return dataFile
      },

      onChangeRowsPerPage: this.handleRowPerpage,
      textLabels: {
        body: {
          noMatch: isFetching ? (
            <Spinner />
          ) : (
            'Sorry, there is no matching data to display'
          )
        }
      }
    }
    return (
      <>
        <TableStyled
          title={title}
          data={data}
          columns={columns}
          options={options}
          minWidth={minWidth}
        />
        <SimpleDialog
          ref={(e) => {
            this.dialog = e
          }}
          handleClose={this.handleClose}
          title={
            this.props.titleModal ||
            props.intl.formatMessage({
              id: forgetPassword ? 'modal.titlePassword' : 'modal.titleDefault'
            })
          }
          handleOk={this.handleOk}
          open={this.props.isOpenDialogTable}
          textHandleOk={forgetPassword ? 'Send' : 'Delete'}
        >
          <div>
            <FormattedMessage
              id={forgetPassword ? 'modal.body.password' : 'modal.body.remove'}
              values={{ name: this.props.currentItemName }}
            />
            {errorModal ? <Warnning>{this.getError()}</Warnning> : null}
          </div>
        </SimpleDialog>
      </>
    )
  }
}

export default connect(
  (state) => ({
    isOpenDialogTable: state.navigationReducer.isOpenDialogTable,
    cb: state.navigationReducer.cb,
    rowsPerPage: state.navigationReducer.rowsPerPage,
    currentItemName: state.navigationReducer.currentItemName,
    errorModal: state.navigationReducer.errorModal,
    forgetPassword: state.navigationReducer.forgetPassword
  }),
  { openDialogTable, navigationHandleRows, removeError }
)(injectIntl(Table))
