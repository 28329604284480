import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Title = styled.h1`
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
`
