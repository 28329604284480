import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Button from '../../../components/button'
import { IconTickBig } from '../../../assets/img'
import { goToRoute } from '../../../modules/navigation'
import { userUnsubscribe } from '../../../modules/admin/users'

import { Body, Title, WrapperText, WrapperButton, Wrapper } from './styles'

class ResubscribePage extends React.Component {
  render () {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('user_id')
    return (
      <Body>
        <Wrapper>
          <img src={IconTickBig} alt='tick' />
          <Title>
            <FormattedMessage id={'resubscribe.title'} />
          </Title>
          <WrapperText>
            <FormattedMessage id={'resubscribe.text'} />
          </WrapperText>
          <WrapperButton>
            <Button
              primary
              onClick={e => {
                this.props.userUnsubscribe(true, id)
                this.props.goToRoute('/')
              }}
            >
              <FormattedMessage id={'resubscribe.button'} />
            </Button>
          </WrapperButton>
          <WrapperText>
            <FormattedMessage id={'resubscribe.future.text'} />
          </WrapperText>
        </Wrapper>
      </Body>
    )
  }
}

export default connect(
  state => ({}),
  { goToRoute, userUnsubscribe }
)(ResubscribePage)
