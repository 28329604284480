import styled from 'styled-components'

export const FooterContainer = styled.div`
  position: absolute;
  height: 30px;
  bottom: 0;
  width: 100vw;
`
export const WrapperHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #383838;
`

export const TopHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  font-size: 13px;
  line-height: 14px;
  text-align: right;
  padding: 0 9px;
`

export const BottomHeader = styled.div`
  height: 55px;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  padding: 0 50px;
  border-top: 1px solid #2E2E2E;
`

export const ContainerTopMenu = styled.div`
  position: relative;
  width: 100%;
  max-width: 1194px;
  margin: auto;
  display: flex;
`

export const ContainerBottomMenu = styled(ContainerTopMenu)`
  background-color: ${({ theme }) => theme.colors.primaryColor};
`

export const ContainerBottomLogin = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  width: 100%;
`

export const SecondaryMenu = styled.div`
  height: 30px;
  align-items: center;
  width: 100%;
  display: flex;
  a {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const Menu = styled(SecondaryMenu)`
  height: 55px;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  a,
  button {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.superlightGrey}
  }
`

export const SecondaryMenuList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 100%;
  margin: 0;
`

export const SecondaryMenuItems = styled.li`
  position: relative;
  text-align: right;
  display: inline;
  color: ${({ theme }) => theme.colors.white};
`

export const PrimaryMenuList = styled(SecondaryMenuList)`
  justify-content: flex-start;
  padding-left: 0;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
`

export const PrimaryMenuItems = styled(SecondaryMenuItems)`
  &.headerFirstItem {
    border-right: 1px solid ${({ theme }) => theme.colors.superlightGrey};
  }
  ${({ disabled }) => {
    if (disabled) {
      return `opacity: 0.3; pointer-events: none;`
    }
    return ``
  }}

  height: 55px;
  padding: 0;
  margin: 0 5px;
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  height: 55px;
  width: 120px;
`

export const BottonMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.colors.primaryColor};
`

export const WrapperLogo = styled.div`
  display:flex;
  justify-content: center
  height: 55px;
  width: 165px;
`
