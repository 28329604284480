import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditUser from '../../containers/admin/users/edituser'
import AddUser from '../../containers/admin/users/adduser'
import Users from '../../containers/admin/users'

class RouterUsers extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/users`}
          component={Users}
        />
        <Route
          path={`${this.props.match.path}/user/edit/:id`}
          component={EditUser}
        />
        <Route
          path={`${this.props.match.path}/user/add`}
          component={AddUser}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterUsers)
