import React from 'react'
import GestorList from '../../components/gestor-list'
import Title from '../../components/basics/title'
import { FormattedMessage } from 'react-intl'

const Results = props => (
  <div>
    <Title>
      <FormattedMessage id={'search.title'} />
    </Title>
    <GestorList results {...props} />
  </div>
)

export default Results
