import { push } from 'react-router-redux'

export const navigationHandleMenu = showMenu => ({
  type: 'NAVIGATION/HANDLE_MENU',
  showMenu
})

export const openDialogTable = (open, cb, name, forgetPassword) => ({
  type: 'NAVIGATION/OPEN_MODAL_TABLE',
  open,
  cb,
  name,
  forgetPassword
})

export const navigationHandleRows = rowsPerPage => ({
  type: 'NAVIGATION/CHANGE_ROWS',
  rowsPerPage
})

export const removeError = () => ({
  type: 'NAVIGATION/REMOVE_ERROR'
})

export const goToRoute = route => {
  return function (dispatch) {
    dispatch(push(route))
  }
}
