import styled from 'styled-components'

export const StyledDiv = styled.div`
  border: 1px dashed #C2C2C2;
  background-color: #F2F2F2;
  border-radius: 4px;
  color: #5C5D5D;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  :focus {
    outline: transparent;
  }
`
export const Wrapper = styled.div`
  border: 4px solid white;
  padding: 35px 32px;
`

export const Icon = styled.img`
  width: 48px;
`
