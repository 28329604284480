import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditSubcategory from '../../containers/admin/content/subcategories/editsubcategory'
import AddSubcategory from '../../containers/admin/content/subcategories/addsubcategory'
import Subcategories from '../../containers/admin/content/subcategories'

class RouterSubcategories extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/content/subareas`}
          component={Subcategories}
        />
        <Route
          path={`${this.props.match.path}/content/subarea/edit/:id`}
          component={EditSubcategory}
        />
        <Route
          path={`${this.props.match.path}/content/subarea/add`}
          component={AddSubcategory}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterSubcategories)
