import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import { Spinner } from '../../../../../components/spinner'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import {
  getSubcategoryById,
  editSubcategory,
  removeError
} from '../../../../../modules/admin/content/subcategories'
import { getBrands } from '../../../../../modules/admin/brands'
import { getCategories } from '../../../../../modules/admin/content/categories'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class EditSubcategory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      subcategory: null,
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getSubcategoryId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getSubcategoryById(this.getSubcategoryId())
    this.props.getCategories()
    this.props.removeError()
  }

  setBrand () {
    const subcategory = this.state.subcategory
    if (subcategory) {
      const categoryId = subcategory.category.id
      const categoryFind = this.props.categories.find(x => x.id === categoryId)
      if (categoryFind) {
        this.setState({ brand: categoryFind.brand.id })
      }
    }
  }
  componentDidUpdate () {
    const {
      isFetching,
      subcategory,
      isFetchingCategories,
      categories
    } = this.props

    if (
      !this.state.subcategory &&
      subcategory &&
      categories &&
      (!isFetching && !isFetchingCategories)
    ) {
      this.setState({ subcategory }, () => {
        this.setBrand()
      })
    }
  }

  changeName (e) {
    const { subcategory } = this.state
    subcategory.name = e.target.value
    this.setState({
      subcategory
    })
  }

  changeSelect (e, value) {
    const { subcategory } = this.state
    subcategory.category = { id: value }
    this.setState({
      subcategory
    })
  }

  changeBrand (e, value) {
    this.setState({
      brand: value
    })
  }

  getOptionsBrands () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.subcategory.name &&
      this.state.brand &&
      this.state.subcategory.category.id
    ) {
      validate = true
    }
    return validate
  }

  getOptions () {
    if (this.props.categories) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.categories
        .filter(x => x.brand.id === this.state.brand)
        .forEach(category => {
          newOptions.push({
            value: category.id,
            label: category.name
          })
        })
      return newOptions
    }
  }
  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editSubcategory(this.state.subcategory)
    }
  }

  render () {
    const { subcategory, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (subcategory !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.subarea.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/content/subareas'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {subcategory.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={subcategory.name}
                    onChange={this.changeName}
                    placeholder='Ex. Logos'
                    type='text'
                    error={!subcategory.name && isSubmit}
                    helperText={
                      !subcategory.name && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptionsBrands()}
                    value={this.state.brand}
                    onChange={this.changeBrand}
                    required
                    label='Brand *'
                    error={!this.state.brand && isSubmit}
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions()}
                    defaultValue={subcategory.category.id}
                    onChange={this.changeSelect}
                    required
                    label='Category *'
                    error={!subcategory.category.id && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() =>
                      this.props.goToRoute(`/admin/content/subareas`)
                    }
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay subareas seleccionada</p>
  }
}

export default connect(
  state => ({
    subcategory: state.subcategoriesReducer.subcategory,
    categories: state.categoriesReducer.categories,
    isFetchingCategories: state.categoriesReducer.isFetching,
    isFetching: state.subcategoriesReducer.isFetching,
    brands: state.brandsReducer.brands,
    error: state.brandsReducer.error
  }),
  { getSubcategoryById, getCategories, getBrands, editSubcategory, goToRoute, removeError }
)(injectIntl(EditSubcategory))
