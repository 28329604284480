import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { LastLocationProvider } from 'react-router-last-location'

import Home from '../home'
import About from '../about'
import Footer from '../../layout/footer/index'
import Header from '../../layout/header/index'
import List from '../list'
import FileCard from '../file-card'
import Login from '../login'
import Search from '../search'
import Upload from '../upload'
import Results from '../results'
import Admin from '../admin'
import Users from '../admin/users'
import Useredit from '../admin/useredit'
import EditUser from '../admin/users/edituser'
import AddUser from '../admin/users/adduser'
import Unsubscribe from '../unsubscribe'
import { verify } from '../../modules/user'

import { GridContainer, GridItem, Root, Wrapper } from './styles'

class APP extends React.Component {
  componentDidMount () {
    if (window.location.pathname !== '/user/unsubscribe' && !window.location.href.includes('?token=')) {
      this.props.verify()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.props.verify()
    }
  }

  render () {
    const { user } = this.props
    if (window.location.pathname === '/user/unsubscribe') {
      return <Route path='/user/unsubscribe' component={Unsubscribe} />
    }
    if (window.location.href.includes('file-card') && window.location.href.includes('?token=')) {
      return (
        <Root>
          <Header noBrands />
          <GridContainer container>
            <GridItem item xs={12}>
              <Wrapper>
                <LastLocationProvider>
                  <Route path='/file-card/:document_id' component={FileCard} />
                </LastLocationProvider>
              </Wrapper>
            </GridItem>
          </GridContainer>
          <Footer />
        </Root>
      )
    }
    if (!user) {
      return (
        <Root>
          <Header />
          <GridContainer container>
            <GridItem item xs={12}>
              <Wrapper>
                <LastLocationProvider>
                  <Route exact path='/login' component={Login} />
                </LastLocationProvider>
              </Wrapper>
            </GridItem>
          </GridContainer>
          <Footer />
        </Root>
      )
    } else if (user.roles[0] === 'ROLE_CLIENT') {
      return (
        <Root>
          <Header user={user} />
          <GridContainer container>
            <GridItem item xs={12}>
              <Wrapper>
                <LastLocationProvider>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/about-us' component={About} />
                  <Route exact path='/list' component={List} />
                  <Route path='/file-card/:document_id' component={FileCard} />
                  <Route exact path='/search' component={Search} />
                  <Route exact path='/upload' component={Upload} />
                  <Route path='/results' component={Results} />
                  <Route exact path='/user/edit' component={Useredit} />
                  <Route path='/list/category/:id_category' component={List} />
                </LastLocationProvider>
              </Wrapper>
            </GridItem>
          </GridContainer>
          <Footer />
        </Root>
      )
    } else if (user.roles[0] === 'ROLE_ADMIN_REG') {
      return (
        <Root>
          <Header user={user} />
          <GridContainer container>
            <GridItem item xs={12}>
              <Wrapper>
                <LastLocationProvider>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/about-us' component={About} />
                  <Route exact path='/list' component={List} />
                  <Route path='/file-card/:document_id' component={FileCard} />
                  <Route exact path='/search' component={Search} />
                  <Route exact path='/upload' component={Upload} />
                  <Route path='/results' component={Results} />
                  <Route path='/admin/users' component={Users} />
                  <Route path='/admin/user/edit/:id' component={EditUser} />
                  <Route path='/admin/user/add' component={AddUser} />
                  <Route exact path='/user/edit' component={Useredit} />
                  <Route path='/list/category/:id_category' component={List} />
                </LastLocationProvider>
              </Wrapper>
            </GridItem>
          </GridContainer>
          <Footer />
        </Root>
      )
    }
    return (
      <Root>
        <Header user={user} />
        <GridContainer container>
          <GridItem item xs={12}>
            <Wrapper>
              <LastLocationProvider>
                <Route exact path='/' component={Home} />
                <Route exact path='/about-us' component={About} />
                <Route exact path='/list' component={List} />
                <Route path='/file-card/:document_id' component={FileCard} />
                <Route exact path='/search' component={Search} />
                <Route path='/upload/:id' component={Upload} />
                <Route exact path='/upload' component={Upload} />
                <Route path='/results' component={Results} />
                <Route path='/admin' component={Admin} />
                <Route exact path='/user/edit' component={Useredit} />
                <Route path='/list/category/:id_category' component={List} />
              </LastLocationProvider>
            </Wrapper>
          </GridItem>
        </GridContainer>
        <Footer />
      </Root>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      user: state.userReducer.user,
      isFetching: state.userReducer.isFetching,
      firstLoadFinished: state.userReducer.isFinished,
      pathname: state.router.location.pathname
    }),
    { verify }
  )(APP)
)
