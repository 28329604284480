import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

export const GridContainer = styled(Grid)`
  padding: 50px;
  margin-top: 85px;
  margin-bottom: 10px;
`

export const GridItem = styled(Grid)``

export const Root = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
`

export const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 130px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 1194px;
`
