import React from 'react'
import Slider from '../../slider'
import { FormattedMessage } from 'react-intl'
export default (props) => {
  const { document } = props
  return (
    <>
      <div
        style={{
          marginTop: 50,

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <p
          style={{
            fontSize: 32,
            letterSpacing: 0,
            marginBottom: 20,
            color: '#0E0E0E',
            fontWeight: 'bold'
          }}
        >
          <FormattedMessage id={'related.content'} />
        </p>
        <div
          style={{
            border: '1px solid #F5F5F5',
            width: '90vw'
          }}
        />
      </div>
      <Slider items={document.related} props={props} />
    </>
  )
}
