import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import { Flex, Box } from 'reflexbox'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { NoImage, EditIcon, IconInfoRed } from '../../../../assets/img'
import Button from '../../../../components/button'
import { setStatus } from '../../requests'
import SimpleDialog from '../../../../components/simpleDialog'
import IButton from '../../../../components/iconbutton'
import { goToRoute } from '../../../../modules/navigation'

import {
  ImgContainer,
  ImgStyled,
  WrapperContainer,
  WrapperProp,
  WrapperLabel,
  WrapperDescription,
  Label,
  Img,
  ImgInfo,
  WrapperWarning,
  StatusText,
  WarningText
} from './styles'

class Summary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgNotFound: false,
      openModal: false,
      reason: '',
      edit: false
    }
  }

  getValuesDocument (document, intl) {
    const uploadDate = {
      label: intl.formatMessage({
        id: 'document.upload'
      }),
      value: document ? document.date : '-'
    }
    const brand = {
      label: intl.formatMessage({
        id: 'document.brand'
      }),
      value: document ? document.brandName : '-'
    }
    const user = {
      label: intl.formatMessage({
        id: 'document.user'
      }),
      value: document ? document.uploaded_by : '-'
    }
    const area = {
      label: intl.formatMessage({
        id: 'document.area'
      }),
      value: document
        ? `${document.category ? document.categoryName : '-'}`
        : '-'
    }
    const subarea = {
      label: intl.formatMessage({
        id: 'document.subarea'
      }),
      value: document
        ? `${document.subcategory ? document.subcategoryName : '-'} `
        : '-'
    }

    const status = {
      label:
        intl.formatMessage({
          id: 'document.status'
        }) + ` (${this.getStatus(this.props.document.status)})`,
      value: this.getElementsStatus(this.props.document.status, this.state.edit)
    }

    return [uploadDate, brand, user, area, subarea, status]
  }

  getStatus (status) {
    switch (status) {
      case 0:
        return 'pending'
      case 1:
        return 'approved'
      case -1:
        return 'rejected'
      case 2:
        return 'hidden'
      default:
        return 'pending'
    }
  }

  warningStatus (status) {
    switch (status) {
      case 1:
      case -1:
        return ''
      default:
        return (
          <WrapperWarning>
            <ImgInfo src={IconInfoRed} />
            <WarningText>Action pending. Choose an option.</WarningText>
          </WrapperWarning>
        )
    }
  }

  getElementsStatus (status, edit) {
    const { user } = this.props
    switch (true) {
      case status === 1 && edit === false:
        return (
          <Flex justify='left'>
            <StatusText>Public</StatusText>
            <IButton
              onClick={() => {
                this.setState({ edit: true })
              }}
            >
              <Img src={EditIcon} alt='edit' />
            </IButton>
          </Flex>
        )
      case status === -1 && edit === false:
        return (
          <Flex justify='left'>
            <StatusText>Reject</StatusText>
            <IButton
              onClick={() => {
                this.setState({ edit: true })
              }}
            >
              <Img src={EditIcon} alt='edit' />
            </IButton>
          </Flex>
        )
      case status === 2 && edit === false:
        return (
          <Flex justify='left'>
            <StatusText>Privacy</StatusText>
            <IButton
              onClick={() => {
                this.setState({ edit: true })
              }}
            >
              <Img src={EditIcon} alt='edit' />
            </IButton>
          </Flex>
        )
      case edit === true:
      default:
        return (
          <>
            <Flex justify='left'>
              {this.warningStatus(this.props.document.status)}
            </Flex>
            <Flex justify='left'>
              {user.roles[0] === 'ROLE_ADMIN_GEN' && (
                <Box>
                  <Button
                    padding='9px 16px'
                    secundary='true'
                    onClick={async () => {
                      await setStatus({
                        id: this.props.document.id,
                        status: 2,
                        reason: '',
                        edit: false
                      })
                      window.location.reload()
                    }}
                  >
                    Hide
                  </Button>
                </Box>
              )}
              <Box mr={2}>
                <Button
                  padding='9px 16px'
                  secundary='true'
                  onClick={async () => {
                    this.setState({ openModal: true, edit: false })
                  }}
                >
                  Reject
                </Button>
              </Box>
              <Box>
                <Button
                  padding='9px 16px'
                  primary='true'
                  onClick={async () => {
                    await setStatus({
                      id: this.props.document.id,
                      status: 1,
                      reason: '',
                      edit: false
                    })
                    window.location.reload()
                  }}
                >
                  Approve
                </Button>
              </Box>
            </Flex>
          </>
        )
    }
  }

  buildProperties () {
    const res = []
    const props = this.getValuesDocument(this.props.document, this.props.intl)
    props.forEach((prop, i) => {
      if (!prop.value) {
        return null
      }
      res.push(
        <WrapperProp key={`wrapper_props_${i}_${prop}`}>
          <WrapperLabel>
            <Label>{prop.label}: </Label>
          </WrapperLabel>
          <WrapperDescription>{prop.value}</WrapperDescription>
        </WrapperProp>
      )
    })
    return res
  }

  componentDidMount () {
    this.getStateImage()
  }

  getStateImage () {
    var img = new window.Image()
    const imgUrl = this.getUrlMainImage()
    img.src = imgUrl
    img.onerror = () => {
      this.setState({
        imgNotFound: NoImage
      })
    }
  }

  getUrlMainImage () {
    // const imgs = this.getImgThumbnail()
    const document = this.props.document
    if (!document) {
      return null
    }
    return `${process.env.REACT_APP_ROOT_URL}${document.mainImage}`
  }

  getMainImage () {
    return this.state.imgNotFound || this.getUrlMainImage()
  }

  render () {
    return (
      <Flex w={1} justify='space-around'>
        <Box w={0.6}>
          <ImgContainer>
            <WrapperContainer>
              <ImgStyled src={this.getMainImage()} />
            </WrapperContainer>
          </ImgContainer>
        </Box>
        <Box w={0.5}>
          <div>{this.buildProperties()}</div>
        </Box>
        <SimpleDialog
          ref={e => {
            this.dialog = e
          }}
          handleClose={() => {
            this.setState({ openModal: false })
          }}
          handleOk={async () => {
            this.setState({ openModal: false })
            // hacemos el reject
            await setStatus({
              id: this.props.document.id,
              status: -1,
              reason: this.state.reason
            })
            window.location.reload()
          }}
          textHandleOk={'Done'}
          open={this.state.openModal}
        >
          <Box mb={2}>Comment on why you rejected this content.</Box>
          <MuiTextField
            onChange={e => {
              this.setState({
                reason: e.target.value
              })
            }}
            value={this.state.reason}
            multiline
            rows='5'
            variant='outlined'
          />
        </SimpleDialog>
      </Flex>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      user: state.userReducer.user
    }),
    { goToRoute }
  )(injectIntl(Summary))
)
