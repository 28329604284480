import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  getContentList,
  deleteContent,
  getFrattenContentList
} from '../../../../modules/admin/content/list'
import { goToRoute, openDialogTable } from '../../../../modules/navigation'
import Table from '../../../../components/table'
import Button from '../../../../components/button'
import getColumns from '../../../../models/content/list'
import { IconPlus } from '../../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'

class Categories extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }
  componentDidMount () {
    this.props.getContentList()
  }

  render () {
    const title = ' '
    return (
      <Wrapper>
        <Title>
          <FormattedMessage id='admin.menu.content.list' />
        </Title>
        <WrapperButton>
          <Button
            primary='true'
            onClick={() => this.props.goToRoute(`/upload`)}
            padding='7px 10px'
          >
            <Img src={IconPlus} />
            <FormattedMessage id={'admin.add'} />
          </Button>
        </WrapperButton>
        <Table
          data={this.props.contentlist}
          columns={getColumns(
            this.props.deleteContent,
            this.props.goToRoute,
            this.props.openDialogTable
          )}
          title={title}
          isFetching={this.props.isFetching}
          minWidth='1100px'
        />
      </Wrapper>
    )
  }
}

export default connect(
  state => ({
    contentlist: getFrattenContentList(state),
    isFetching: state.contentListReducer.isFetching
  }),
  { getContentList, deleteContent, goToRoute, openDialogTable }
)(injectIntl(Categories))
