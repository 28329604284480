import React from 'react'
import Card from '../card'
import { ListItems } from './styles'

const renderItems = props => {
  const { items } = props
  const res = []
  items.forEach(item => {
    res.push(
      <Card
        item={item}
        id={item.id}
        colorOne='black'
        colorSecond='black'
        colorThird='black'
        weightThird
        weightOne
        key={item.id}
        history={props.history}
      />
    )
  })
  return res
}

const Grid = props => <ListItems>{renderItems(props)}</ListItems>

export default Grid
