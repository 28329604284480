import styled from 'styled-components'
import TextField from '../textfield'
import Button from '../button'

export const TextFieldStyled = styled(TextField)``

export const Wrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 70px;
  height: 60px;
  flex-direction: column;
  .search-input {
    height: 56px;
    fieldset {
      background: ${({ theme }) => theme.colors.superlightGrey};
      z-index: -1;
    }
  }
`

export const WrapperSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  .search-input {
    height: 56px;
    fieldset {
      background: ${({ theme }) => theme.colors.superlightGrey};
      z-index: -1;
    }
  }
`
export const ButtonStyled = styled(Button)`
  && {
    width: 100px;
    margin-left: -2px;
    margin-top: 3px;
    height: 56px;
    border-radius: 0 4px 4px 0;
  }
`

export const WrapperMessage = styled.div`
  margin-top: 30px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey};
  width: 60%;
`

export const PredictiveSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`

export const ResultsPredictive = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: ${({ results }) => (results ? '60px' : '60px')};
  left: 0;
  z-index: 10;
  max-height: calc(100vh - 400px);
  overflow: auto;
  background: ${({ theme }) => theme.colors.superlightGrey};
`

export const ItemPredictive = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px 6px 12px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  background: ${({ theme, active }) =>
    active ? theme.colors.lightGrey : theme.colors.superlightGrey};
`

export const WrapperText = styled.div`
  padding: 0 10px;
  width: 70%;
`
export const Text = styled.p`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
