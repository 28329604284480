import { request } from '../../../../utils/request'
import { push } from 'react-router-redux'

const getContentListRequest = () => ({ type: 'CONTENTLIST/DATA_REQUEST' })
const getContentListSuccess = contentlist => ({
  type: 'CONTENTLIST/DATA_SUCCESS',
  contentlist
})
const getContentListFailure = error => ({
  type: 'CONTENTLIST/DATA_FAILURE',
  error
})

export const getContentList = () => {
  return dispatch => {
    dispatch(getContentListRequest())
    // Content List
    const requestOptions = {
      method: 'GET'
    }
    return request(`documents?hidden=true`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getContentListFailure(res.error || res.message))
          return false
        }
        dispatch(getContentListSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getContentListFailure(err))
      })
  }
}

const deleteContentRequest = () => ({ type: 'CONTENT/DATA_REQUEST_DELETE' })
const deleteContentSuccess = id => ({
  type: 'CONTENT/DATA_SUCCESS_DELETE',
  id
})
const deleteContentFailure = error => ({
  type: 'CONTENT/DATA_FAILURE_DELETE',
  error
})

export const deleteContent = id => {
  return dispatch => {
    dispatch(deleteContentRequest())
    // Content-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`documents`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteContentFailure(res.error || res.message))
          return false
        }
        dispatch(deleteContentSuccess(id))
        return true
      })
      .catch(err => {
        dispatch(deleteContentFailure(err))
      })
  }
}

const getContentByIdRequest = () => ({ type: 'CONTENT_ID/DATA_REQUEST' })
const getContentByIdSuccess = content => ({
  type: 'CONTENT_ID/DATA_SUCCESS',
  content
})
const getContentByIdFailure = error => ({
  type: 'CONTENT_ID/DATA_FAILURE',
  error
})

export const getContentById = id => {
  return dispatch => {
    dispatch(getContentByIdRequest())
    // Content-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`documents/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getContentByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getContentByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getContentByIdFailure(err))
      })
  }
}

const editContentRequest = content => ({
  type: 'CONTENT/DATA_REQUEST_EDIT',
  content
})
const editContentSuccess = contentlist => ({
  type: 'CONTENT/DATA_SUCCESS_EDIT',
  contentlist
})
const editContentFailure = error => ({
  type: 'CONTENT/DATA_FAILURE_EDIT',
  error
})

export const editContent = content => {
  return dispatch => {
    dispatch(editContentRequest(content))
    // Content-Edit
    const body = {
      content
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`documents`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editContentFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editContentSuccess(res))
          dispatch(push(`/admin/content/list`))
          return true
        }
      })
      .catch(err => {
        dispatch(editContentFailure(err))
      })
  }
}

const addContentRequest = content => ({
  type: 'CONTENT/DATA_REQUEST_ADD',
  content
})
const addContentSuccess = contentlist => ({
  type: 'CONTENT/DATA_SUCCESS_ADD',
  contentlist
})
const addContentFailure = error => ({
  type: 'CONTENT/DATA_FAILURE_ADD',
  error
})

export const addContent = content => {
  return dispatch => {
    dispatch(addContentRequest(content))
    // Content-Add
    const body = {
      content
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`documents`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addContentFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addContentSuccess(res))
          dispatch(push(`/admin/content/list`))
          return true
        }
      })
      .catch(err => {
        dispatch(addContentFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'CONTENT/REMOVE_ERROR'
})
