import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  getUsers,
  deleteUser,
  getUsersByRoleAndCountry
} from '../../../modules/admin/users'
import { goToRoute, openDialogTable } from '../../../modules/navigation'
import Table from '../../../components/table'
import Button from '../../../components/button'
import getColumns from '../../../models/users'
import { IconPlus } from '../../../assets/img'

import { Img, WrapperButton, Title, Wrapper } from './styles'
import { forgetPassword } from '../../../modules/user'

class Users extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  componentDidMount () {
    this.props.getUsers()
  }

  render () {
    const title = ' '
    const { users, user, isFetching } = this.props
    if (user !== null) {
      return (
        <Wrapper>
          <Title>
            <FormattedMessage id='admin.menu.user' />
          </Title>
          <WrapperButton>
            <Button
              primary='true'
              onClick={() => this.props.goToRoute(`/admin/user/add`)}
              padding='7px 10px'
            >
              <Img src={IconPlus} />
              <FormattedMessage id={'admin.add.users'} />
            </Button>
          </WrapperButton>
          <Table
            data={users}
            ref={e => {
              this.table = e
            }}
            columns={getColumns(
              this.props.deleteUser,
              this.props.goToRoute,
              this.props.openDialogTable,
              this.props.forgetPassword
            )}
            title={title}
            isFetching={isFetching}
            minWidth='1050px'
          />
        </Wrapper>
      )
    }
    return null
  }
}

export default connect(
  state => ({
    user: state.userReducer.user,
    users: getUsersByRoleAndCountry(state),
    isFetching: state.usersReducer.isFetching
  }),
  { getUsers, deleteUser, goToRoute, openDialogTable, forgetPassword }
)(injectIntl(Users))
