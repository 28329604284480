import { resetList, removeResultPredictive } from '../modules/list'
import { resetQueryFilter } from '../modules/search'

const listMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const prevRoute = store.getState().router.location.pathname
    const nextRoute = action.payload.location.pathname
    let prevCategory = store.getState().listReducer.category
    prevCategory = prevCategory ? prevCategory.toString() : null
    const nextRouteSplit = nextRoute.split('/')
    let nextCategory = nextRouteSplit[nextRouteSplit.length - 1]
    nextCategory = nextCategory ? nextCategory.toString() : null
    const currentSearch = store.getState().searchReducer.data.query

    let reset = false
    // si no voy a una ficha o si no vengo de una ficha
    if (!nextRoute.includes('file-card') && !prevRoute.includes('file-card')) {
      reset = true
    }
    // vengo de una ficha
    if (prevRoute.includes('file-card')) {
      // no voy al listado de resultados ni a la home
      if (!nextRoute.includes('results') && nextRoute !== '/') {
        // no es un listado
        if (!nextRoute.includes('list')) {
          reset = true
        }
        // no tengo categoría previa
        if (!prevCategory) {
          reset = true
        }
        // son diferentes categorías
        if (prevCategory !== nextCategory) {
          reset = true
        }
      }
      // voy a la home desde una ficha a la que he llegado haciendo búsqueda
      if (nextRoute === '/' && (prevCategory || !!currentSearch)) {
        reset = true
      }
    }
    if (nextRoute.includes('list') && !prevRoute.includes('list')) {
      store.dispatch(resetQueryFilter())
    }
    if (nextRoute !== prevRoute && prevRoute !== '/') {
      store.dispatch(removeResultPredictive())
    }
    reset && store.dispatch(resetList())
  }
  let result = next(action)

  return result
}

export default listMiddleware
