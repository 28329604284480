export const getRolesByRole = state => {
  if (state.usersReducer.users.length !== 0) {
    const idUserLogin = state.userReducer.user.id
    const userLogin = state.usersReducer.users.find(user => idUserLogin === user.id)
    let roles = state.rolesReducer.roles
    if (userLogin.role === 'ROLE_ADMIN_REG') {
      const filterRoles = roles.filter(role => role.id !== 'ROLE_ADMIN_GEN')
      roles = filterRoles
    }
    return roles
  }
  return []
}
