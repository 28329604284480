import { request } from '../../../../utils/request'
import { push } from 'react-router-redux'

const getTypesRequest = () => ({ type: 'TYPES/DATA_REQUEST' })
const getTypesSuccess = types => ({
  type: 'TYPES/DATA_SUCCESS',
  types
})
const getTypesFailure = error => ({
  type: 'TYPES/DATA_FAILURE',
  error
})

export const getTypes = () => {
  return dispatch => {
    dispatch(getTypesRequest())
    // Types
    const requestOptions = {
      method: 'GET'
    }

    return request(`types`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getTypesFailure(res.error || res.message))
          return false
        }
        dispatch(getTypesSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getTypesFailure(err))
      })
  }
}

const deleteTypeRequest = () => ({ type: 'TYPE/DATA_REQUEST_DELETE' })
const deleteTypeSuccess = types => ({
  type: 'TYPE/DATA_SUCCESS_DELETE',
  types
})
const deleteTypeFailure = error => ({
  type: 'TYPE/DATA_FAILURE_DELETE',
  error
})

export const deleteType = id => {
  return dispatch => {
    dispatch(deleteTypeRequest())
    // Type-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`types`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteTypeFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteTypeSuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteTypeFailure(err))
      })
  }
}

const getTypeByIdRequest = () => ({ type: 'TYPE_ID/DATA_REQUEST' })
const getTypeByIdSuccess = typec => ({
  type: 'TYPE_ID/DATA_SUCCESS',
  typec
})
const getTypeByIdFailure = error => ({
  type: 'TYPE_ID/DATA_FAILURE',
  error
})

export const getTypeById = id => {
  return dispatch => {
    dispatch(getTypeByIdRequest())
    // Type-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`types/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getTypeByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getTypeByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getTypeByIdFailure(err))
      })
  }
}

const editTypeRequest = typec => ({ type: 'TYPE/DATA_REQUEST_EDIT', typec })
const editTypeSuccess = types => ({
  type: 'TYPE/DATA_SUCCESS_EDIT',
  types
})
const editTypeFailure = error => ({
  type: 'TYPE/DATA_FAILURE_EDIT',
  error
})

export const editType = typec => {
  return dispatch => {
    dispatch(editTypeRequest(typec))
    // Type-Edit
    const body = {
      id: typec.id,
      name: typec.name,
      subcategoryId: typec.subcategory.id
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`types`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editTypeFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editTypeSuccess(res))
          dispatch(push(`/admin/content/types`))
          return true
        }
      })
      .catch(err => {
        dispatch(editTypeFailure(err))
      })
  }
}

const addTypeRequest = typec => ({ type: 'TYPE/DATA_REQUEST_ADD', typec })
const addTypeSuccess = types => ({
  type: 'TYPE/DATA_SUCCESS_ADD',
  types
})
const addTypeFailure = error => ({
  type: 'TYPE/DATA_FAILURE_ADD',
  error
})

export const addType = typec => {
  return dispatch => {
    dispatch(addTypeRequest())
    // Type-Add
    const body = {
      name: typec.name,
      subcategoryId: typec.subcategory.id
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`types`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addTypeSuccess(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addTypeSuccess(res))
          dispatch(push(`/admin/content/types`))
          return true
        }
      })
      .catch(err => {
        dispatch(addTypeFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'TYPE/REMOVE_ERROR'
})
