import React from 'react'
import { CardFileWrapper } from './styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LeftSide from './left-side'
import RightSide from './right-side'
import Carrousel from './carrousel'
import { getDocument, downloadFiles } from '../../modules/document'
import CircularProgress from '@material-ui/core/CircularProgress'

class CardFileProduct extends React.Component {
  componentDidMount () {
    this.props.getDocument(this.props.match.params.document_id)
  }
  render () {
    const { document, isFetching, tempDocument } = this.props
    const data = tempDocument || document
    if (!data || isFetching) {
      return <CircularProgress />
    }

    return (
      <>
        <CardFileWrapper {...this.props} document={data}>
          <LeftSide {...this.props} document={data} />
          <RightSide {...this.props} document={data} />
        </CardFileWrapper>
        {data && data.related.length > 0 && (
          <Carrousel {...this.props} document={data} />
        )}
      </>
    )
  }
}

export default withRouter(
  connect(
    (state) => ({
      document: state.documentReducer.document,
      isFetching: state.documentReducer.isFetching
    }),
    { getDocument, downloadFiles }
  )(CardFileProduct)
)
