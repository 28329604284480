const initialState = {
  isFetching: false,
  brands: [],
  brand: null,
  selectedBrand: {
    id: 2,
    name: 'Teka',
    icon: '/uploads/brands/TEKA.png'
  }
}

export const Brands = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'BRANDS/DATA_REQUEST':
    case 'BRAND/DATA_REQUEST_DELETE':
    case 'BRAND_ID/DATA_REQUEST':
    case 'BRAND/DATA_REQUEST_EDIT':
    case 'BRAND/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'BRANDS/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        brands: []
      }

    case 'BRAND_ID/DATA_FAILURE':
    case 'BRAND/DATA_FAILURE_EDIT':
    case 'BRAND/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        brand: null,
        error: action.error
      }
    }
    case 'BRAND/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        brand: null,
        errorModal: action.error
      }
    }
    // success
    case 'BRAND_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        brand: action.brand,
        error: null
      }
    case 'BRANDS/DATA_SUCCESS':
    case 'BRAND/DATA_SUCCESS_DELETE':
    case 'BRAND/DATA_SUCCESS_EDIT':
    case 'BRAND/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        brands: action.brands,
        error: null
      }
    case 'BRAND/SELECT_BRAND':
      return {
        ...state,
        selectedBrand: action.brand
      }
    case 'BRAND/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    case '@@router/LOCATION_CHANGE': {
      if (
        action.payload.location.pathname.indexOf('list/category') === -1 &&
        action.payload.location.pathname.indexOf('file-card') === -1
      ) {
        return {
          ...state,
          selectedBrand:
            JSON.parse(window.localStorage.getItem('selectedBrand')) ||
            initialState.selectedBrand
        }
      }
      return {
        ...state,
        selectedBrand: JSON.parse(window.localStorage.getItem('selectedBrand')),
        brand: JSON.parse(window.localStorage.getItem('selectedBrand'))
      }
    }
    default:
      return state
  }
}
