import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { CloudUploadIcon } from '../../assets/img/index'
import { StyledDiv, Icon, Wrapper } from './styles'

const Dropzone = (props) => {
  const onDrop = useCallback((acceptedFiles) => {
    props.onChange(acceptedFiles)
  }, [])
  const accept = props.accept
  const multiple = props.multiple
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple
  })
  return (
    <StyledDiv {...getRootProps()}>
      <Wrapper>
        <input {...getInputProps()} />
        <Icon src={CloudUploadIcon} />
        <p>{props.children}</p>
      </Wrapper>
    </StyledDiv>
  )
}
export default Dropzone
