import React from 'react'
import { ThumbStyle, WrapperThumb } from './styles'
import { NoImage, NoVideo, NoDocument } from '../../assets/img'
import formats from '../../utils/formats'
export default class Thumb extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount () {
    var img = new window.Image()
    const imgUrl = this.props.src
    img.src = imgUrl
    img.onload = () => {
      this.setState({ loaded: true })
    }
    img.onerror = () => {
      let image
      if (formats.img.includes(this.props.extension.toString())) {
        image = NoImage
      } else if (formats.video.includes(this.props.extension.toString())) {
        image = NoVideo
      } else {
        image = NoDocument
      }
      this.setState({ notFound: image })
    }
  }

  render () {
    const { onClick, src, index } = this.props
    if (!this.state.loaded && !this.state.notFound) {
      return null
    }
    return (
      <WrapperThumb fullsize={this.props.fullsize} key={`element.id_${index}`}>
        <ThumbStyle
          src={this.state.notFound || src}
          index={index}
          onClick={onClick}
        />
      </WrapperThumb>
    )
  }
}
