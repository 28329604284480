import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditCountries from '../../containers/admin/countries/editcountry'
import AddCountries from '../../containers/admin/countries/addcountry'
import Countries from '../../containers/admin/countries'

class RouterCountries extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/countries`}
          component={Countries}
        />
        <Route
          path={`${this.props.match.path}/country/edit/:id`}
          component={EditCountries}
        />
        <Route
          path={`${this.props.match.path}/country/add`}
          component={AddCountries}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterCountries)
