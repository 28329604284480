import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Card from '../card'
import { IconArrowLeft, IconArrowRight } from '../../assets/img/index'

export default (props) => {
  const { items } = props
  const NextArrow = ({ onClick }) => (
    <img
      src={IconArrowRight}
      alt='arrow right'
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: -30,
        right: 10,
        width: 28,
        height: 28,
        zIndex: 1
      }}
    />
  )
  const PrevArrow = ({ onClick }) => (
    <img
      src={IconArrowLeft}
      alt='arrow left'
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: -30,
        left: 10,
        width: 28,
        height: 28,
        zIndex: 1
      }}
    />
  )
  const settings = {
    initialSlide: 0,
    slidesToScroll: items.length < 4 ? items.length : 4,
    slidesToShow: items.length < 4 ? items.length : 4,
    draggable: true,
    infinite: true,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div
      style={{
        position: 'relative',
        height: 500
      }}
    >
      <Slider {...settings}>
        {items.map((item, i) => {
          return (
            <div key={`card_${i}`}>
              <Card
                item={item}
                id={item.id}
                colorOne='black'
                colorSecond='black'
                colorThird='black'
                weightThird
                weightOne
                key={item.id}
                history={props.history}
                slide
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
