import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import { Spinner } from '../../../../../components/spinner'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import {
  getHashtagById,
  editHashtag,
  removeError
} from '../../../../../modules/admin/content/hashtags'
import { getBrands } from '../../../../../modules/admin/brands'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class EditHashtag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      hashtag: null,
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getHashtagId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getHashtagById(this.getHashtagId())
    this.props.getBrands()
  }

  componentDidUpdate () {
    const { isFetching, hashtag } = this.props
    if (!this.state.hashtag && hashtag && !isFetching) {
      this.setState({ hashtag })
    }
    this.props.removeError()
  }

  changeName (e) {
    const { hashtag } = this.state
    hashtag.name = e.target.value
    this.setState({
      hashtag
    })
  }

  changeSelect (e, value) {
    const { hashtag } = this.state
    hashtag.brand = { id: value }
    this.setState({
      hashtag
    })
  }

  chekedFiles () {
    let validate = false
    if (this.state.hashtag.name && this.state.hashtag.brand.id) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editHashtag(this.state.hashtag)
    }
  }

  getOptions () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach((brand) => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  render () {
    const { hashtag, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (hashtag !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.hashtag.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/content/hashtags'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={hashtag.name}
                    onChange={this.changeName}
                    placeholder='Ex. Logos'
                    type='text'
                    error={!hashtag.name && isSubmit}
                    helperText={
                      !hashtag.name && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions()}
                    defaultValue={hashtag.brand.id}
                    onChange={this.changeSelect}
                    required
                    label='Brand *'
                    error={!hashtag.brand.id && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() =>
                      this.props.goToRoute(`/admin/content/hashtags`)
                    }
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay tag seleccionada</p>
  }
}

export default connect(
  (state) => ({
    brands: state.brandsReducer.brands,
    hashtag: state.hashtagsReducer.hashtag,
    isFetching: state.hashtagsReducer.isFetching,
    error: state.hashtagsReducer.error
  }),
  { getHashtagById, editHashtag, goToRoute, getBrands, removeError }
)(injectIntl(EditHashtag))
