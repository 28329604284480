import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Button from '../../../components/button'
import { goToRoute } from '../../../modules/navigation'
import { userUnsubscribe } from '../../../modules/admin/users'

import { Body, Title, WrapperText, WrapperButton, Wrapper } from './styles'

class UnsubscribePage extends React.Component {
  render () {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('user_id')
    const email = url.searchParams.get('email')
    return (
      <Body>
        <Wrapper>
          <Title>
            <FormattedMessage id={'unsubscribe.title'} />
          </Title>
          <WrapperText>
            <FormattedMessage id={'unsubscribe.email'} />
            <span> {email} </span>
            <FormattedMessage id={'unsubscribe.email2'} />
          </WrapperText>
          <WrapperButton>
            <Button
              secundary
              padding='0 25px'
              margin='0 8px 0 0'
              onClick={e => {
                this.props.goToRoute('/')
              }}
            >
              <FormattedMessage id={'unsubscribe.cancel'} />
            </Button>
            <Button
              primary
              onClick={e => {
                this.props.userUnsubscribe(false, id)
              }}
            >
              <FormattedMessage id={'unsubscribe.button'} />
            </Button>
          </WrapperButton>
        </Wrapper>
      </Body>
    )
  }
}

export default connect(
  state => ({}),
  { goToRoute, userUnsubscribe }
)(UnsubscribePage)
