const initialState = {
  isFetching: false,
  document: null,
  downloaded: false,
  isDownloading: false
}

export const Document = (state = initialState, action) => {
  switch (action.type) {
    case 'DOCUMENT/GET_DOCUEMENT_REQUEST':
      return {
        ...state,
        isFetching: true
      }
    case 'DOCUMENT/GET_DOCUEMENT_SUCCESS': {
      return {
        ...state,
        document: action.document,
        isFetching: false
      }
    }
    case 'DOCUMENT/GET_DOCUEMENT_FAILURE': {
      return {
        ...state,
        isFetching: false,
        document: null
      }
    }
    case 'DOCUMENT/DOWNLOADFILES_REQUEST':
      return {
        ...state,
        isDownloading: true,
        downloaded: false
      }
    case 'DOCUMENT/DOWNLOADFILES_SUCCESS': {
      return {
        ...state,
        route: action.route,
        isDownloading: false,
        downloaded: true
      }
    }
    case 'DOCUMENT/DOWNLOADFILES_FAILURE': {
      return {
        ...state,
        isDownloading: false,
        downloaded: false
      }
    }
    case 'DOCUMENT/REMOVE_FILE_REQUEST': {
      return {
        ...state,
        isDeleting: true
      }
    }
    case 'DOCUMENT/REMOVE_FILE_FAILURE':
    case 'DOCUMENT/REMOVE_FILE_SUCCESS': {
      return {
        ...state,
        isDeleting: false
      }
    }
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        isFetching: false,
        document: null,
        downloaded: false,
        isDownloading: false
      }
    }

    default:
      return state
  }
}
