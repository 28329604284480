import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Img = styled.img`
  padding-right: 10px;
`

export const WrapperButton = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 1;
`

export const Title = styled.h1`
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
`
