import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import BackButton from '../../../components/back-button'
import Summary from './summary'
import Files from './files'
import { Title, Subtitle } from './styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import { getFirstStepData, sendThirdStep, sendFirstStep } from '../requests'
import SimpleDialog from '../../../components/simpleDialog'
import PreventLeave from '../../../components/preventLeave'
import StepOne from '../StepOne'
import StepThree from '../StepThree'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IconTickBlack } from '../../../assets/img'
import { withRouter } from 'react-router-dom'
class Edit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: 0,
      randomKey: '',
      prevent: false,
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const data = await getFirstStepData(this.props.id)
    this.setState({ document: data })
  }

  handleChange (event, value) {
    if (this.stepOne && !this.stepOne.checkedFiles()) {
      this.setState({ prevent: true })
      return
    }
    this.setState({ value })
  }
  renderModalExit () {
    return (
      <PreventLeave
        check={() => {
          if (this.state.loading) {
            return false
          } else if (this.stepOne) {
            const validate = this.stepOne.checkedFiles()
            return validate
          } else {
            return true
          }
        }}
        idIntlTitle='upload.leave.title'
        idIntlText='upload.leave.text'
      />
    )
  }

  renderContent () {
    switch (this.state.value) {
      case 0: {
        return (
          <>
            {this.renderModalExit()}
            <Summary document={this.state.document} />
          </>
        )
      }
      case 1: {
        return (
          <div key={this.state.randomKey}>
            {this.renderModalExit()}
            <StepOne
              editMode
              userId={this.props.userId}
              edit={this.props.id}
              intl={this.props.intl}
              ref={(e) => {
                this.stepOne = e
              }}
              next={async (data) => {
                this.setState({ loading: true })
                await sendFirstStep({
                  id: this.props.id,
                  ...data
                })
                this.setState({ loading: false })
                this.getData()
              }}
            />
          </div>
        )
      }
      case 2: {
        return (
          <div key={this.state.randomKey}>
            {this.renderModalExit()}
            <Files
              document={this.state.document}
              userId={this.props.userId}
              onLoading={() => {
                this.setState({ loading: true })
              }}
              reload={async () => {
                this.setState({ loading: true })
                await this.getData()

                setTimeout(() => {
                  this.setState({
                    randomKey: makeid(6),
                    loading: false
                  })
                }, 1000)
              }}
            />
          </div>
        )
      }
      case 3: {
        return (
          <div key={this.state.randomKey}>
            {this.renderModalExit()}
            <StepThree
              editMode
              data={this.state.document}
              next={async (previewId) => {
                this.setState({ loading: true })
                await sendThirdStep({
                  ...this.props.document,
                  id: this.props.id,
                  previewId,
                  userId: this.props.userId
                })
                this.getData()
                this.setState({ loading: false })
              }}
              setpreview={async (files, cb) => {
                this.setState({ loading: true })
                await sendThirdStep({
                  ...this.props.document,
                  id: this.props.id,
                  userId: this.props.userId,
                  preview: files[0]
                })
                this.getData()
                cb && cb()
                this.setState({ loading: false })
              }}
            />
          </div>
        )
      }
      default: {
        return 'default'
      }
    }
  }

  render () {
    const intl = this.props.intl
    if (!this.state.document) {
      return <CircularProgress />
    }
    return (
      <Flex column style={{ textAlign: 'center' }}>
        <SimpleDialog
          handleClose={() => {
            this.setState({ prevent: false })
          }}
          handleOk={async () => {
            this.setState({ prevent: false })
            // hacemos el reject
          }}
          textHandleOk={'Ok'}
          noCancel
          open={this.state.prevent}
        >
          <div>
            <span style={{ display: 'block', fontSize: '17px' }}>
              <FormattedMessage id='edit.prevent.save.title' />
            </span>
            <span style={{ display: 'block', fontSize: '13px' }}>
              <FormattedMessage id='edit.prevent.save.text' />
            </span>
          </div>
        </SimpleDialog>
        <Flex row='true' style={{ textAlign: 'center' }}>
          <Box w={300}>
            <BackButton
              button
              onClick={() => {
                this.props.history.goBack()
              }}
              text={intl.formatMessage({ id: 'admin.back.content' })}
            />
          </Box>
          <Box mb={2} w={1}>
            <Title>{`${intl.formatMessage({
              id: 'upload.edit.content'
            })} `}</Title>{' '}
            <Subtitle>/ {this.state.document.name} </Subtitle>
          </Box>
          <Flex w={300} justify='center' align='center'>
            {this.state.loading ? (
              <CircularProgress style={{ height: 20, width: 20, margin: 5 }} />
            ) : (
              <img src={IconTickBlack} alt='tick' />
            )}
            <div style={{ marginLeft: '5px' }}>
              {this.state.loading ? (
                <FormattedMessage id='edit.uploading' />
              ) : (
                <FormattedMessage id='edit.uploaded' />
              )}
            </div>
          </Flex>
        </Flex>
        <Box mt={3}>
          <Paper square>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab label={intl.formatMessage({ id: 'upload.edit.tab.1' })} />
              <Tab label={intl.formatMessage({ id: 'upload.edit.tab.2' })} />
              <Tab label={intl.formatMessage({ id: 'upload.edit.tab.3' })} />
              <Tab label={intl.formatMessage({ id: 'upload.edit.tab.4' })} />
            </Tabs>
          </Paper>
          <Box mt={4}>{this.renderContent()}</Box>
        </Box>
      </Flex>
    )
  }
}

export default withRouter(injectIntl(Edit))

function makeid (length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
