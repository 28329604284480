import { request } from '../../../../utils/request'
import { push } from 'react-router-redux'

const getHashtagsRequest = () => ({ type: 'HASHTAGS/DATA_REQUEST' })
const getHashtagsSuccess = (hashtags) => ({
  type: 'HASHTAGS/DATA_SUCCESS',
  hashtags
})
const getHashtagsFailure = (error) => ({
  type: 'HASHTAGS/DATA_FAILURE',
  error
})

export const getHashtags = () => {
  return (dispatch) => {
    dispatch(getHashtagsRequest())
    // Hashtags
    const requestOptions = {
      method: 'GET'
    }
    return request(`hashtags`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(getHashtagsFailure(res.error || res.message))
          return false
        }
        dispatch(getHashtagsSuccess(res))
        return true
      })
      .catch((err) => {
        dispatch(getHashtagsFailure(err))
      })
  }
}

const getHashtagsByBrandRequest = () => ({
  type: 'HASHTAGS_BRAND/DATA_REQUEST'
})
const getHashtagsByBrandSuccess = (hashtagsBrand) => ({
  type: 'HASHTAGS_BRAND/DATA_SUCCESS',
  hashtagsBrand
})
const getHashtagsByBrandFailure = (error) => ({
  type: 'HASHTAGS_BRAND/DATA_FAILURE',
  error
})

export const getHashtagsByBrand = (id) => {
  return (dispatch) => {
    dispatch(getHashtagsByBrandRequest())
    // Hashtags by Brand
    const requestOptions = {
      method: 'GET'
    }
    return request(`hashtags/brand/${id}`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(getHashtagsByBrandFailure(res.error || res.message))
          return false
        }
        dispatch(getHashtagsByBrandSuccess(res))
        return true
      })
      .catch((err) => {
        dispatch(getHashtagsByBrandFailure(err))
      })
  }
}

const deleteHashtagRequest = () => ({ type: 'HASHTAG/DATA_REQUEST_DELETE' })
const deleteHashtagSuccess = (hashtags) => ({
  type: 'HASHTAG/DATA_SUCCESS_DELETE',
  hashtags
})
const deleteHashtagFailure = (error) => ({
  type: 'HASHTAG/DATA_FAILURE_DELETE',
  error
})

export const deleteHashtag = (id) => {
  return (dispatch) => {
    dispatch(deleteHashtagRequest())
    // Hashtag-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`hashtags`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(deleteHashtagFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteHashtagSuccess(res))
          return true
        }
      })
      .catch((err) => {
        dispatch(deleteHashtagFailure(err))
      })
  }
}

const getHashtagByIdRequest = () => ({ type: 'HASHTAG_ID/DATA_REQUEST' })
const getHashtagByIdSuccess = (hashtag) => ({
  type: 'HASHTAG_ID/DATA_SUCCESS',
  hashtag
})
const getHashtagByIdFailure = (error) => ({
  type: 'HASHTAG_ID/DATA_FAILURE',
  error
})

export const getHashtagById = (id) => {
  return (dispatch) => {
    dispatch(getHashtagByIdRequest())
    // Hashtag-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`hashtags/${id}`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(getHashtagByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getHashtagByIdSuccess(res))
        return true
      })
      .catch((err) => {
        dispatch(getHashtagByIdFailure(err))
      })
  }
}

const editHashtagRequest = (hashtag) => ({
  type: 'HASHTAG/DATA_REQUEST_EDIT',
  hashtag
})
const editHashtagSuccess = (hashtags) => ({
  type: 'HASHTAG/DATA_SUCCESS_EDIT',
  hashtags
})
const editHashtagFailure = (error) => ({
  type: 'HASHTAG/DATA_FAILURE_EDIT',
  error
})

export const editHashtag = (hashtag) => {
  return (dispatch) => {
    dispatch(editHashtagRequest(hashtag))
    // Hashtag-Edit
    const body = {
      id: hashtag.id,
      name: hashtag.name,
      brand_id: hashtag.brand.id
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`hashtags`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(editHashtagFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editHashtagSuccess(res))
          dispatch(push(`/admin/content/hashtags`))
          return true
        }
      })
      .catch((err) => {
        dispatch(editHashtagFailure(err))
      })
  }
}

const addHashtagRequest = (hashtag) => ({
  type: 'HASHTAG/DATA_REQUEST_ADD',
  hashtag
})
const addHashtagSuccess = (hashtags) => ({
  type: 'HASHTAG/DATA_SUCCESS_ADD',
  hashtags
})
const addHashtagFailure = (error) => ({
  type: 'HASHTAG/DATA_FAILURE_ADD',
  error
})

export const addHashtag = (hashtag) => {
  return (dispatch) => {
    dispatch(addHashtagRequest())
    // Hashtag-Add
    const body = {
      name: hashtag.name,
      brand_id: hashtag.brand_id
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`hashtags`, requestOptions)
      .then((res) => {
        if (res.error || res.code === 401) {
          dispatch(addHashtagFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addHashtagSuccess(res))
          dispatch(push(`/admin/content/hashtags`))
          return true
        }
      })
      .catch((err) => {
        dispatch(addHashtagFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'HASHTAG/REMOVE_ERROR'
})
