import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '../button'
import { WrapperContent, Row } from './styles'
class SimpleDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>
        <DialogContent>
          <WrapperContent>
            <Row />
            <WrapperContent>{this.props.children}</WrapperContent>
          </WrapperContent>
        </DialogContent>
        <DialogActions>
          {!this.props.noCancel ? (
            <Button onClick={this.props.handleClose} color='primary'>
              Cancel
            </Button>
          ) : null}
          {this.props.handleOk ? (
            <Button onClick={this.props.handleOk} color='primary' autoFocus>
              {this.props.textHandleOk || 'Delete'}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    )
  }
}

export default enhanceWithClickOutside(SimpleDialog)
