const initialState = {
  isFetching: false,
  hashtags: [],
  hashtag: null
}

export const Hashtags = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'HASHTAGS/DATA_REQUEST':
    case 'HASHTAGS_BRAND/DATA_REQUEST':
    case 'HASHTAG/DATA_REQUEST_DELETE':
    case 'HASHTAG_ID/DATA_REQUEST':
    case 'HASHTAG/DATA_REQUEST_EDIT':
    case 'HASHTAG/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'HASHTAGS/DATA_FAILURE':
    case 'HASHTAGS_BRAND/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        hashtags: []
      }

    case 'HASHTAG_ID/DATA_FAILURE':
    case 'HASHTAG/DATA_FAILURE_EDIT':
    case 'HASHTAG/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        hashtag: null,
        error: action.error
      }
    }

    case 'HASHTAG/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        hashtag: null,
        errorModal: action.error
      }
    }

    // success
    case 'HASHTAG_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        hashtag: action.hashtag,
        error: null
      }
    case 'HASHTAGS/DATA_SUCCESS':
    case 'HASHTAGS_BRAND/DATA_SUCCESS':
    case 'HASHTAG/DATA_SUCCESS_DELETE':
    case 'HASHTAG/DATA_SUCCESS_EDIT':
    case 'HASHTAG/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        hashtags: action.hashtags,
        error: null
      }
    case 'HASHTAG/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
