import styled from 'styled-components'
import Button from '../button'
import { Link } from 'react-router-dom'
export const CardStyled = styled(Link)`
  text-align: left;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  min-width: 150px;
  height: 420px;
  padding: 20px;
  position: relative;
  text-decoration: none;
  :hover {
    -webkit-box-shadow: ${({ slide }) =>
    slide ? 'none' : 'inset 0px 0px 0px 2px #ececec'};
    -moz-box-shadow: ${({ slide }) =>
    slide ? 'none' : 'inset 0px 0px 0px 2px #ececec'};
    box-shadow: ${({ slide }) =>
    slide ? 'none' : 'inset 0px 0px 0px 2px #ececec'};
    .buttonView {
      display: block;
    }
  }
`

export const CardImageContent = styled.div`
  display: flex;
  height: 150px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 250px;
`

export const CardImage = styled.div`
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url('${({ src }) => src}');
  width: 100%;
  height: 100%;
`

export const CardActionAreaStyled = styled.div`
  && {
    height: 380px;
  }
`

export const CardActions = styled.div``

export const ButtonStyled = styled(Button)`
  && {
    display: none;
  }
`

export const SpanStyled = styled.span`
  display: ${({ inline }) => (inline ? 'inline' : 'block')}
  color: ${({ theme, color }) => color || theme.colors.primaryColor};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.fontWeights.bold : theme.fontWeights.medium};
`
export const SpanInlineStyled = styled(SpanStyled)`
  display: inline;
`

export const New = styled.div`
  position: absolute;
  top: 6px;
  left: 0px;
`

export const SpanNew = styled.span`
  background: ${({ theme }) => theme.colors.colorTekaRed};
  padding: 6.5px 21.5px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`
