import { request } from '../../../../utils/request'
import { push } from 'react-router-redux'

const getCategoriesRequest = () => ({ type: 'CATEGORIES/DATA_REQUEST' })
const getCategoriesSuccess = categories => ({
  type: 'CATEGORIES/DATA_SUCCESS',
  categories
})
const getCategoriesFailure = error => ({
  type: 'CATEGORIES/DATA_FAILURE',
  error
})

export const getCategories = () => {
  return dispatch => {
    dispatch(getCategoriesRequest())
    // Categories
    const requestOptions = {
      method: 'GET'
    }
    return request(`categories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getCategoriesFailure(res.error || res.message))
          return false
        }
        dispatch(getCategoriesSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getCategoriesFailure(err))
      })
  }
}

const deleteCategoryRequest = () => ({ type: 'CATEGORY/DATA_REQUEST_DELETE' })
const deleteCategorySuccess = categories => ({
  type: 'CATEGORY/DATA_SUCCESS_DELETE',
  categories
})
const deleteCategoryFailure = error => ({
  type: 'CATEGORY/DATA_FAILURE_DELETE',
  error
})

export const deleteCategory = id => {
  return dispatch => {
    dispatch(deleteCategoryRequest())
    // Category-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`categories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteCategoryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteCategorySuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteCategoryFailure(err))
      })
  }
}

const getCategoryByIdRequest = () => ({ type: 'CATEGORY_ID/DATA_REQUEST' })
const getCategoryByIdSuccess = category => ({
  type: 'CATEGORY_ID/DATA_SUCCESS',
  category
})
const getCategoryByIdFailure = error => ({
  type: 'CATEGORY_ID/DATA_FAILURE',
  error
})

export const getCategoryById = id => {
  return dispatch => {
    dispatch(getCategoryByIdRequest())
    // Category-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`categories/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getCategoryByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getCategoryByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getCategoryByIdFailure(err))
      })
  }
}

const editCategoryRequest = category => ({
  type: 'CATEGORY/DATA_REQUEST_EDIT',
  category
})
const editCategorySuccess = categories => ({
  type: 'CATEGORY/DATA_SUCCESS_EDIT',
  categories
})
const editCategoryFailure = error => ({
  type: 'CATEGORY/DATA_FAILURE_EDIT',
  error
})

export const editCategory = category => {
  return dispatch => {
    dispatch(editCategoryRequest(category))
    // Category-Edit
    const body = {
      id: category.id,
      name: category.name,
      brand_id: category.brand.id
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`categories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editCategoryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editCategorySuccess(res))
          dispatch(push(`/admin/content/areas`))
          return true
        }
      })
      .catch(err => {
        dispatch(editCategoryFailure(err))
      })
  }
}

const addCategoryRequest = category => ({
  type: 'CATEGORY/DATA_REQUEST_ADD',
  category
})
const addCategorySuccess = categories => ({
  type: 'CATEGORY/DATA_SUCCESS_ADD',
  categories
})
const addCategoryFailure = error => ({
  type: 'CATEGORY/DATA_FAILURE_ADD',
  error
})

export const addCategory = category => {
  return dispatch => {
    dispatch(addCategoryRequest())
    // Category-Add
    const body = {
      name: category.name,
      brand_id: category.brand_id
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`categories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addCategoryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addCategorySuccess(res))
          dispatch(push(`/admin/content/areas`))
          return true
        }
      })
      .catch(err => {
        dispatch(addCategoryFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'CATEGORY/REMOVE_ERROR'
})
