export const colors = {
  superLightBlue: '#C7EFFF',
  primaryColor: '#121212',
  colorTekaRed: '#c7102d',
  lightBlue: '#8AD4F3',
  blue: '#0099D8',
  darkBlue: '#008BC4',
  superDarkBlue: '#026BA1',
  megaLightGrey: '#FAFAFA',
  superlightGrey: '#F2F2F2',
  warningBackground: '#fcf3f4',
  warningText: '#cb203a',
  lightGrey: '#E8E8E8',
  grey: '#AAAAAA',
  darkGrey: '#BCBCBC',
  superDarkGrey: '#75838F',
  hiperDarkGrey: '#4d5963',
  megaDarkGrey: '#000435',
  lightRed: 'rgba(255,58,0,0.3)',
  red: '#FF3900',
  darkRed: '#E83400',
  superDarkRed: '#B42A01',
  white: '#FFFFFF',
  alertLightBlue: '#99E1FF',
  alertSoftOrange: '#FCCA83',
  alertPaleYellow: '#EDFF9B',
  alertLightGreen: '#97FFDD',
  black: '#000000',
  borderShadow: '#EAEAEA'
}

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

export const sizes = {
  XL: '24px',
  L: '16px',
  M: '14px',
  S: '12px',
  XS: '10px'
}

export const layout = {
  maxWidth: 1024
}
