import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  padding-left: 330px;
`

export const Brand = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  ${({ disabled }) => {
    if (disabled) {
      return `opacity: 0.3; pointer-events: none;`
    }
    return ``
  }}
`
