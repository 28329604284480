import React from 'react'
import Thumb from '../../thumb'
import Chip from '@material-ui/core/Chip'
import { ItemPredictive as ItemPredictiveStyled, Text, WrapperText } from '../styles'
import { HashtagImage } from '../../../assets/img'
class ItemPredictive extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
  }

  processItem (name) {
    const { currentQuery } = this.props
    const queryAll = currentQuery.split(' ').filter(x => x !== '' && x !== '.')
    const escapeRegExp = queryAll.map(text => text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&'))
    var re = new RegExp('(' + escapeRegExp.join('|') + ')(?!([^<]+)?>)', 'gi')
    name = name.replace(re, '<text style="font-weight:normal">$1</text>')
    return name
  }

  render () {
    const { item, i, onClick, hashtag } = this.props
    return (
      <ItemPredictiveStyled
        onClick={onClick}
        onMouseEnter={() => {
          this.setState({ active: true })
        }}
        onMouseLeave={() => {
          this.setState({ active: false })
        }}
        active={this.state.active}
      >
        <div
          style={{
            height: '40px',
            width: '40px'
          }}
        >
          <Thumb
            src={hashtag ? HashtagImage : `${process.env.REACT_APP_ROOT_URL}${item.main_image}`}
            index={i}
            key={i}
            extension={'png'}
            fullsize
          />
        </div>
        <WrapperText>
          <Text
            dangerouslySetInnerHTML={{ __html: this.processItem(item.name) }}
          />
        </WrapperText>
        <Chip
          style={{ height: '15px', margin: '5px', marginLeft: 'auto' }}
          label={hashtag ? item.brand.name : item.category.name}
        />
      </ItemPredictiveStyled>
    )
  }
}

export default ItemPredictive
