import flatten from '../../../utils/flatten'

export const getFrattenUsers = state =>
  state.usersReducer.users &&
  state.usersReducer.users.map(user => {
    return flatten(user)
  })

export const getUsersByRoleAndCountry = state => {
  if (state.usersReducer.users.length !== 0) {
    const idUserLogin = state.userReducer.user.id
    const userLogin = state.usersReducer.users.find(user => idUserLogin === user.id)
    const countryUser = userLogin.region.name
    let users = state.usersReducer.users
    if (userLogin.role === 'ROLE_ADMIN_REG') {
      const filterUsers = users.filter(user => user.role !== 'ROLE_ADMIN_GEN' && user.region.name === countryUser)
      users = filterUsers
    }
    return users.map(user => {
      return flatten(user)
    })
  }
  return []
}
