import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { intlReducer as intl } from 'react-intl-redux'
import { Navigation as navigationReducer } from './navigation/index'
import { locale } from './locale/reducer'
import { User as userReducer } from './user/index'
import { List as listReducer } from './list/index'
import { Search as searchReducer } from './search'
import { Brands as brandsReducer } from './admin/brands/index'
import { Categories as categoriesReducer } from './admin/content/categories/index'
import { Subcategories as subcategoriesReducer } from './admin/content/subcategories/index'
import { Types as typesReducer } from './admin/content/types/index'
import { Hashtags as hashtagsReducer } from './admin/content/hashtags/index'
import { Users as usersReducer } from './admin/users/index'
import { Document as documentReducer } from './document/index'
import { Countries as countriesReducer } from './admin/countries/index'
import { Roles as rolesReducer } from './roles/index'
import { ContentList as contentListReducer } from './admin/content/list/index'
import { Notifications as notificationsReducer } from './admin/notifications/index'
import { Dashboard as dashboardReducer } from './admin/dashboard/index'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    navigationReducer,
    userReducer,
    brandsReducer,
    intl,
    locale,
    listReducer,
    categoriesReducer,
    documentReducer,
    searchReducer,
    countriesReducer,
    rolesReducer,
    subcategoriesReducer,
    typesReducer,
    hashtagsReducer,
    usersReducer,
    contentListReducer,
    notificationsReducer,
    dashboardReducer
  })

export default rootReducer
