import React from 'react'
import IButton from '../../components/iconbutton'
import { EditIcon, DeleteIcon } from '../../assets/img/index'
import { Link } from 'react-router-dom'
import { Img, Wrapper } from '../styles'

const getColumns = (deleteSubcategory, goToRoute, openModal) => [
  {
    name: 'id',
    label: 'id',
    options: {
      sortDirection: 'desc'
    }
  },
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'category.name',
    label: 'Category'
  },
  {
    name: 'brand.name',
    label: 'Brand'
  },
  {
    name: 'edit',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if (tableMeta.rowData !== undefined) {
          return (
            <Wrapper>
              <Link to={`/admin/content/subarea/edit/${tableMeta.rowData[0]}`}>
                <IButton>
                  <Img src={EditIcon} alt='edit' />
                </IButton>
              </Link>
              <IButton
                onClick={() => {
                  openModal(
                    true,
                    () => {
                      deleteSubcategory(tableMeta.rowData[0])
                    },
                    tableMeta.rowData[1]
                  )
                }}
              >
                <Img src={DeleteIcon} alt='delete' />
              </IButton>
            </Wrapper>
          )
        }
        return null
      }
    }
  }
]
export default getColumns
