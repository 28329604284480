import { request } from '../../../utils/request'
import { push } from 'react-router-redux'

const getCountriesRequest = () => ({ type: 'COUNTRIES/DATA_REQUEST' })
const getCountriesSuccess = countries => ({
  type: 'COUNTRIES/DATA_SUCCESS',
  countries
})
const getCountriesFailure = error => ({
  type: 'COUNTRIES/DATA_FAILURE',
  error
})

export const getCountries = () => {
  return dispatch => {
    dispatch(getCountriesRequest())
    // Countries
    const requestOptions = {
      method: 'GET'
    }

    return request(`countries`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getCountriesFailure(res.error || res.message))
          return false
        }
        dispatch(getCountriesSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getCountriesFailure(err))
      })
  }
}

const deleteCountryRequest = () => ({ type: 'COUNTRY/DATA_REQUEST_DELETE' })
const deleteCountrySuccess = countries => ({
  type: 'COUNTRY/DATA_SUCCESS_DELETE',
  countries
})
const deleteCountryFailure = error => ({
  type: 'COUNTRY/DATA_FAILURE_DELETE',
  error
})

export const deleteCountry = id => {
  return dispatch => {
    dispatch(deleteCountryRequest())
    // Countries-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`countries`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteCountryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteCountrySuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteCountryFailure(err))
      })
  }
}

const getCountryByIdRequest = () => ({ type: 'COUNTRY_ID/DATA_REQUEST' })
const getCountryByIdSuccess = country => ({
  type: 'COUNTRY_ID/DATA_SUCCESS',
  country
})
const getCountryByIdFailure = error => ({
  type: 'COUNTRY_ID/DATA_FAILURE',
  error
})

export const getCountryById = id => {
  return dispatch => {
    dispatch(getCountryByIdRequest())
    // Country-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`countries/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getCountryByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getCountryByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getCountryByIdFailure(err))
      })
  }
}

const editCountryRequest = country => ({ type: 'COUNTRY/DATA_REQUEST_EDIT', country })
const editCountrySuccess = countries => ({
  type: 'COUNTRY/DATA_SUCCESS_EDIT',
  countries
})
const editCountryFailure = error => ({
  type: 'COUNTRY/DATA_FAILURE_EDIT',
  error
})

export const editCountry = country => {
  return dispatch => {
    dispatch(editCountryRequest(country))
    // Country-Edit
    const body = {
      ...country
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`countries`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editCountryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editCountrySuccess(res))
          dispatch(push(`/admin/countries`))
          return true
        }
      })
      .catch(err => {
        dispatch(editCountryFailure(err))
      })
  }
}

const addCountryRequest = country => ({ type: 'COUNTRY/DATA_REQUEST_ADD', country })
const addCountrySuccess = countries => ({
  type: 'COUNTRY/DATA_SUCCESS_ADD',
  countries
})
const addCountryFailure = error => ({
  type: 'COUNTRY/DATA_FAILURE_ADD',
  error
})

export const addCountry = country => {
  return dispatch => {
    dispatch(addCountryRequest())
    // Country-Add
    const body = {
      name: country.name
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`countries`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addCountryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addCountrySuccess(res))
          dispatch(push(`/admin/countries`))
          return true
        }
      })
      .catch(err => {
        dispatch(addCountryFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'COUNTRY/REMOVE_ERROR'
})
