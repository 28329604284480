import Button from '../../components/button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'

export const ButtonStyled = styled(Button)`
  && {
    padding: 0;
    margin: 0;
    text-transform: none;
    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    &:hover {
      font-size: 16px;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
    }
    &:active {
      font-weight: bold;
    }
  }
`
export const LinkStyled = styled(Link)`
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`

export const MenuListStyled = styled(MenuList)`
  background-color: rgb(56, 56, 56);
`
