import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import Calendar from '../../calendar'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Chip from '@material-ui/core/Chip'
import Button from '../../button'
import { WrapperContent, Row, CalendarWrapper, ChipWrapper } from './styles'
import moment from 'moment'
class MenuOverlay extends React.Component {
  constructor (props) {
    super(props)
    const today = new Date().toISOString().split('T')[0]
    this.state = {
      date: {
        from: today,
        to: today
      }
    }
    this.handleOk = this.handleOk.bind(this)
  }
  handleOk () {
    this.props.handleOk && this.props.handleOk(this.state.date)
  }

  onChangeDate (dateCalendar, label) {
    const date = dateCalendar
      .toDate()
      .toISOString()
      .split('T')[0]
    const dateState = this.state.date
    dateState[`${label}Origin`] = dateCalendar
    dateState[label] = date
    this.setState({ date: dateState })
  }

  selectDatePreset (value, type, e) {
    const from = moment().subtract(value, type)
    const to = moment()
    this.fromCalendar.handleDateChange(from)
    this.toCalendar.handleDateChange(to)
    this.handleOk()
  }

  resetFilter () {
    const today = new Date().toISOString().split('T')[0]
    this.setState({ date: { from: today, to: today } })
  }
  render () {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Date range'}</DialogTitle>
        <DialogContent>
          <WrapperContent>
            <Row>
              <CalendarWrapper>
                <Calendar
                  disableFuture
                  defaultDate={this.state.date.fromOrigin}
                  ref={e => {
                    this.fromCalendar = e
                  }}
                  onChangeDate={e => {
                    this.onChangeDate(e, 'from')
                  }}
                  label='from'
                  maxDate={this.state.date.toOrigin}
                />
              </CalendarWrapper>
              <CalendarWrapper>
                <Calendar
                  disableFuture
                  minDate={this.state.date.fromOrigin}
                  defaultDate={this.state.date.toOrigin}
                  ref={e => {
                    this.toCalendar = e
                  }}
                  onChangeDate={e => {
                    this.onChangeDate(e, 'to')
                  }}
                  label='to'
                />
              </CalendarWrapper>
            </Row>
            <WrapperContent>
              <Row>
                <span>Select preset filters:</span>
              </Row>
              <Row>
                <ChipWrapper>
                  <Chip
                    label='One week'
                    onClick={this.selectDatePreset.bind(this, 7, 'days')}
                    variant='outlined'
                  />
                </ChipWrapper>
                <ChipWrapper>
                  <Chip
                    label='One month'
                    variant='outlined'
                    onClick={this.selectDatePreset.bind(this, 1, 'months')}
                  />
                </ChipWrapper>
                <ChipWrapper>
                  <Chip
                    label='Three months'
                    variant='outlined'
                    onClick={this.selectDatePreset.bind(this, 3, 'months')}
                  />
                </ChipWrapper>
              </Row>
            </WrapperContent>
          </WrapperContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='primary'>
            Close
          </Button>
          <Button onClick={this.handleOk} color='primary' autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default enhanceWithClickOutside(MenuOverlay)
