import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'

import { ButtonStyled, LinkStyled, MenuListStyled } from './styles'
class MenuListAdmin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getMenuItems = this.getMenuItems.bind(this)
  }

  handleToggle () {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose (event) {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  getMenuItems (item) {
    const options = this.props.options
    const itemsMenu = options.map(object => {
      return (
        <MenuItem onClick={this.handleClose}>
          <LinkStyled to={object.route}>
            <p>{object.name}</p>
          </LinkStyled>
        </MenuItem>
      )
    })
    return itemsMenu
  }
  render () {
    const { open } = this.state
    return (
      <Fragment>
        <ButtonStyled
          buttonRef={node => {
            this.anchorEl = node
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={this.handleToggle}
        >
          {this.props.children}
          <ArrowIcon />
        </ButtonStyled>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuListStyled>{this.getMenuItems()}</MenuListStyled>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    )
  }
}

MenuListAdmin.propTypes = {
  padding: PropTypes.string
}

export default MenuListAdmin
