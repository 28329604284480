import React from 'react'

import { TextFieldStyled, FormControlStyled } from './styles'
class TextField extends React.Component {
  render () {
    return (
      <>
        <FormControlStyled
          error={this.props.error}
          helperText={this.props.helperText}
          required={this.props.required}
          fullWidth
        >
          <TextFieldStyled
            classes={this.props.classes}
            value={this.props.value}
            onChange={this.props.onChange}
            margin='dense'
            placeholder={this.props.placeholder}
            variant='outlined'
            label={this.props.label}
            autoComplete={this.props.autoComplete}
            defaultValue={this.props.defaultValue}
            type={this.props.type}
            error={this.props.error}
            inputRef={this.props.inputRef}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            helperText={this.props.helperText}
            required={this.props.required}
            InputProps={{
              classes: {
                focused: 'focused',
                root: this.props.classInputRoot
                  ? this.props.classInputRoot
                  : 'root',
                notchedOutline: 'outline'
              },
              startAdornment: this.props.startAdornment,
              endAdornment: this.props.endAdornment
            }}
          />
        </FormControlStyled>
      </>
    )
  }
}

export default TextField
