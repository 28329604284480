import axios from 'axios'
const { REACT_APP_API_URL } = process.env

export async function downloadFilesPublicURL (token, docsFiles) {
  let root = `/documents/temporary/download/zip?token=${token}`
  docsFiles.forEach(element => { root += `&ids[]=${element}` })

  try {
    const res = await axios.get(`${REACT_APP_API_URL}${root}`)
    return res.data.path
  } catch (error) {
    console.log('downloadFiles error', error)
  }
}
