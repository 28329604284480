import React from 'react'
import {} from './styles'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import StepTwo from '../../StepTwo'
import { injectIntl } from 'react-intl'
import FileList from '../../../../components/card-file-product/right-side/file-list'
import { removeFiles } from '../../../../modules/document'
import { uploadFile } from '../../requests'
// import { NoImage } from '../../../../assets/img'

class Files extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgNotFound: false
    }
  }

  render () {
    return (
      <>
        <span style={{ fontWeight: 'bold', float: 'left', fontSize: '18px' }}>
          Files included in this document
        </span>
        <Flex w={1} justify='center' column>
          <Box mb={4}>
            <FileList
              downloadFiles={null}
              files={this.props.document.files}
              noDownloadButtons
              removeFiles={id => {
                this.props.removeFiles(id)
                setTimeout(() => {
                  this.props.reload()
                }, 1000)
              }}
            />
          </Box>
          <StepTwo
            editMode
            onLoading={this.props.onLoading}
            reload={this.props.reload}
            uploadId={this.props.document.id}
            userId={this.props.userId}
            uploadFile={uploadFile}
          />
        </Flex>
      </>
    )
  }
}

export default connect(
  state => ({}),
  { removeFiles }
)(injectIntl(Files))
