import React from 'react'
import { FormattedMessage } from 'react-intl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiTextField from '@material-ui/core/TextField'
import { Flex, Box } from 'reflexbox'
import moment from 'moment'
import CreatableSelect from 'react-select/creatable'

import { request } from '../../utils/request'
import Select from '../../components/select'
import TextField from '../../components/textfield'
import CheckboxSelector from '../../components/checkboxGroup'
import Button from '../../components/button'
import SelectMultiple from '../../components/SelectMultiple'
import compareArrays from '../../utils/compareArrays'
import { getFirstStepData } from './requests'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Title, Subtitle } from './styles'

class StepOne extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      isSubmit: false,
      lang: 'es',
      brands: [],
      categories: [],
      subcategories: [],
      languages: [],
      types: [],
      tags: [],
      brand: null,
      category: null,
      subcategory: null,
      name: '',
      date: moment().format('YYYY-MM-DDTHH:mm'),
      description: '',
      references: [],
      selectedTypes: [],
      hashtags: [],
      selectedTags: [],
      lastData: {}
    }
    this.selectCategories = this.selectCategories.bind(this)
    this.selectSubcategories = this.selectSubcategories.bind(this)
    this.selectTypes = this.selectTypes.bind(this)
    this.loadTags = this.loadTags.bind(this)
    this.selectHashtag = this.selectHashtag.bind(this)
    this.checkedFiles = this.checkedFiles.bind(this)
    this.checkedSelects = this.checkedSelects.bind(this)
    this.checkedCheckbox = this.checkedCheckbox.bind(this)
    this.checkedSelectMultiple = this.checkedSelectMultiple.bind(this)
    this.handleMultiSelect = this.handleMultiSelect.bind(this)
  }

  async componentDidMount () {
    const brands = await request('brands')
    const categories = await request('categories')
    const subcategories = await request('subcategories')
    const types = await request('types')
    const languages = await request('languages')

    this.setState(
      {
        brands: brands.map((b) => ({
          value: b.id,
          label: b.name
        })),
        categories: categories.map((b) => ({
          brand: b.brand,
          value: b.id,
          label: b.name
        })),
        subcategories: subcategories.map((b) => ({
          category: b.category,
          value: b.id,
          label: b.name
        })),
        languages: languages.map((b) => ({
          value: b.id,
          label: b.name
        })),
        types: types.map((b) => ({
          subcategory: b.subcategory,
          value: b.id,
          label: b.name
        }))
      },
      async () => {
        if (this.props.edit || this.props.contentData) {
          const data =
            this.props.contentData || (await getFirstStepData(this.props.edit))
          data.languages = this.state.languages.map((l) => {
            if (data.languages && data.languages.includes(l.value)) {
              return {
                ...l,
                checked: true
              }
            }
            return {
              ...l,
              checked: false
            }
          })
          data.types = this.state.types.map((l) => {
            if (data.types && data.types.includes(l.value)) {
              return {
                ...l,
                checked: true
              }
            }
            return {
              ...l,
              checked: false
            }
          })

          data.references = data.reference ? data.reference.split(',') : []

          data.hashtags = data.hashtags
            ? data.hashtags.map((hashtag) => {
              return {
                label: hashtag.name || hashtag,
                value: hashtag.name || hashtag
              }
            })
            : []
          this.setState({ ...data, lastData: data }, () => {
            this.loadTags()
          })
        }
        this.setState({ loaded: true })
      }
    )
  }

  componentDidUpdate (props, state) {
    if (state.brand !== this.state.brand) {
      this.selectHashtag()
    }
  }

  async loadTags () {
    const tags = await request('tags/' + this.state.category)

    this.setState({
      tags: tags.map((b) => {
        if ((this.state.selectedTags || []).includes(b.id)) {
          return {
            value: b.id,
            label: b.name,
            checked: true
          }
        }
        return {
          value: b.id,
          label: b.name,
          checked: false
        }
      })
    })
  }

  selectCategories () {
    return this.state.categories.filter((c) => c.brand.id === this.state.brand)
  }

  selectSubcategories () {
    return this.state.subcategories.filter(
      (c) => c.category.id === this.state.category
    )
  }

  selectTypes () {
    return this.state.types.filter(
      (c) => c.subcategory.id === this.state.subcategory
    )
  }

  uploadField (field, value, required) {
    if (this.props.editMode) {
      const payload = {}
      payload[field] = value
      const lastData = this.state.lastData
      let update = false
      if (
        Array.isArray(value) &&
        !compareArrays(lastData[field], value) &&
        ((required && value && value.length) || !required)
      ) {
        update = true
      } else if (
        !Array.isArray(value) &&
        lastData[field] !== value &&
        ((required && value) || !required)
      ) {
        update = true
      }
      if (update) {
        lastData[field] = value
        this.setState({ lastData }, () => {
          this.props.next({ ...payload, userId: this.props.userId })
        })
      }
    }
  }

  uploadFieldCategory (categoryConfig) {
    if (this.props.editMode) {
      const payload = categoryConfig
      let lastData = this.state.lastData
      if (lastData['subcategory'] !== categoryConfig.subcategory) {
        lastData = { ...lastData, ...categoryConfig }
        this.setState({ lastData }, () => {
          this.props.next({ ...payload, userId: this.props.userId })
        })
      }
    }
  }
  checkedFiles () {
    const { brand, name, date, languages, category, subcategory, loaded } =
      this.state
    let validate = false
    if (!loaded) {
      return true
    }
    if (
      (brand &&
        name &&
        date &&
        this.isChecked(languages) &&
        category === 12 &&
        subcategory) ||
      (brand &&
        name &&
        date &&
        this.isChecked(languages) &&
        category &&
        category !== 12 &&
        subcategory)
    ) {
      validate = true
    }
    return validate
  }

  checkedSelects (data) {
    const isSubmit = this.state.isSubmit || this.props.editMode
    if (!data && isSubmit) {
      return (
        <FormHelperText>
          <FormattedMessage id={'forms.required'} />
        </FormHelperText>
      )
    }
    return null
  }

  checkedCheckbox (data) {
    const isSubmit = this.state.isSubmit || this.props.editMode
    if (!this.isChecked(data) && isSubmit) {
      return (
        <FormHelperText>
          <FormattedMessage id={'forms.required'} />
        </FormHelperText>
      )
    }
    return null
  }

  isChecked (data) {
    const itemChecked = data.find((x) => x.checked)
    return !!itemChecked
  }

  checkedSelectMultiple (data) {
    const isSubmit = this.state.isSubmit || this.props.editMode
    if (data.length === 0 && isSubmit) {
      return (
        <FormHelperText>
          <FormattedMessage id={'forms.required'} />
        </FormHelperText>
      )
    }
    return null
  }
  async selectHashtag () {
    const hashtagsBrand = await request('hashtags/brand/' + this.state.brand)
    const refactorHashtags = hashtagsBrand.map((hashtag) => {
      return { label: hashtag.name, value: hashtag.name }
    })
    this.setState({ hashtagsBrand: refactorHashtags })
  }

  handleMultiSelect (newValue, editMode) {
    const onlyNameHashtags = newValue.map((value) => value.label)
    this.setState({ hashtags: onlyNameHashtags })
    editMode && this.uploadField('hashtags', onlyNameHashtags, true)
  }

  render () {
    const {
      loaded,
      brands,
      brand,
      name,
      date,
      languages,
      category,
      categories,
      subcategory,
      types,
      tags,
      references,
      hashtags = [],
      hashtagsBrand = [],
      description
    } = this.state
    const intl = this.props.intl
    const isSubmit = (this.state.isSubmit || this.props.editMode) && loaded

    if (!loaded) {
      return <CircularProgress />
    }

    return (
      <Flex column style={{ opacity: loaded ? 1 : 0 }}>
        {!this.props.editMode && (
          <>
            <Box mb={2}>
              <Title>Upload Documents in 3 steps</Title>
            </Box>
            <Box mb={4}>
              <Subtitle>[Step 1]</Subtitle>
            </Box>
          </>
        )}
        <Flex style={{ textAlign: 'left' }}>
          <Flex w={1 / 3} column mr={3}>
            <Box mb={3}>
              <span style={{ fontWeight: 'bold' }}>
                Which brand is this content for? *
              </span>
              <Select
                options={brands}
                value={brand}
                onChange={(e, value) => {
                  this.setState({
                    brand: value,
                    category: null,
                    subcategory: null
                  })
                }}
                error={!brand && isSubmit}
              />
              {this.checkedSelects(brand)}
            </Box>
            <Box mb={3}>
              <span style={{ fontWeight: 'bold' }}>Name of this content *</span>
              <TextField
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                error={!name && isSubmit}
                helperText={
                  !name && isSubmit
                    ? intl.formatMessage({ id: 'forms.required' })
                    : ''
                }
                onBlur={(e) => {
                  this.uploadField('name', e.target.value, true)
                }}
              />
            </Box>
            <Box mb={3}>
              <span style={{ fontWeight: 'bold' }}>Upload date *</span>
              <TextField
                type='datetime-local'
                value={
                  this.props.editMode
                    ? moment(date, 'DD/M/YYYY hh:mm').format('YYYY-MM-DDTHH:mm')
                    : date
                }
                onChange={(e) => {
                  this.setState({
                    date: this.props.editMode
                      ? moment(e.target.value).format('DD/M/YYYY hh:mm')
                      : e.target.value
                  })
                }}
                onBlur={(e) => {
                  this.props.editMode &&
                    this.uploadField(
                      'created',
                      moment(e.target.value).format('YYYY-MM-DDTHH:mm'),
                      true
                    )
                }}
              />
            </Box>
            <Box mb={3}>
              <span style={{ fontWeight: 'bold' }}>
                Language of the document *
              </span>
              <CheckboxSelector
                options={languages}
                setOptions={(newOptions) => {
                  this.setState(
                    {
                      languages: newOptions
                    },
                    () => {
                      this.uploadField(
                        'languages',
                        newOptions.filter((l) => l.checked).map((l) => l.value),
                        true
                      )
                    }
                  )
                }}
              />
              {this.checkedCheckbox(languages)}
            </Box>
          </Flex>

          <Flex w={1 / 3} column mr={3}>
            {brand && (
              <>
                <Box mb={3}>
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='document.categoryupload' />*
                  </span>
                  <Select
                    options={this.selectCategories()}
                    value={category}
                    onChange={(e, value) => {
                      this.setState(
                        {
                          category: value,
                          subcategory: null
                        },
                        () => {
                          this.loadTags()
                        }
                      )
                    }}
                    error={!category && isSubmit}
                  />
                  {this.checkedSelects(category)}
                </Box>
                <Box mb={3} key={`${subcategory}_sc`}>
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id='document.subcategory' />*
                  </span>
                  <Select
                    options={this.selectSubcategories()}
                    value={subcategory}
                    onChange={(e, value) => {
                      this.setState(
                        {
                          subcategory: value
                        },
                        () => {
                          this.uploadFieldCategory({
                            subcategory: value,
                            category: this.state.category,
                            brand: this.state.subcategory
                          })
                        }
                      )
                    }}
                    error={!subcategory && isSubmit}
                  />
                  {this.checkedSelects(subcategory)}
                </Box>
                {subcategory && this.selectTypes().length >= 1 && (
                  <Box mb={3}>
                    <span style={{ fontWeight: 'bold' }}>Type</span>
                    <CheckboxSelector
                      options={this.selectTypes()}
                      setOptions={(newOptions) => {
                        this.setState(
                          {
                            types: newOptions
                          },
                          () => {
                            this.uploadField(
                              'types',
                              newOptions
                                .filter((l) => l.checked)
                                .map((l) => l.value),
                              true
                            )
                          }
                        )
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </Flex>
          {category && (
            <Flex w={1 / 3} column mr={3}>
              {category === 12 && subcategory === 59 && (
                <Box mb={3}>
                  <SelectMultiple
                    label='Reference number'
                    value={references}
                    setValue={(values) => {
                      this.setState({ references: values }, () => {
                        this.uploadField('references', values.join(', '), true)
                      })
                    }}
                  />
                </Box>
              )}
              <Flex mb={3} column>
                <Box mb={8}>
                  <span style={{ fontWeight: 'bold' }}>Hashtags</span>
                </Box>
                <CreatableSelect
                  isMulti
                  onChange={(newValue) =>
                    this.handleMultiSelect(newValue, this.props.editMode)
                  }
                  options={hashtagsBrand}
                  defaultValue={
                    this.props.edit || this.props.contentData ? hashtags : []
                  }
                />
              </Flex>
              <Flex mb={3} column>
                <Box mb={8}>
                  <span style={{ fontWeight: 'bold' }}>Description</span>
                </Box>
                <MuiTextField
                  onChange={(e) => {
                    this.setState({
                      description: e.target.value
                    })
                  }}
                  onBlur={(e) => {
                    this.uploadField('description', e.target.value)
                  }}
                  value={description}
                  multiline
                  rows='5'
                  variant='outlined'
                />
              </Flex>
              {tags.length > 0 && (
                <Box mb={3}>
                  <span style={{ fontWeight: 'bold' }}>
                    {categories.filter((c) => c.value === category)[0].label}
                  </span>
                  <CheckboxSelector
                    options={tags}
                    setOptions={(newOptions) => {
                      this.setState(
                        {
                          tags: newOptions
                        },
                        () => {
                          this.uploadField(
                            'tags',
                            newOptions
                              .filter((l) => l.checked)
                              .map((l) => l.value),
                            true
                          )
                        }
                      )
                    }}
                  />
                </Box>
              )}
            </Flex>
          )}
        </Flex>
        <Flex style={{ flexDirection: 'row-reverse' }}>
          <Box width={3}>
            {!this.props.editMode && (
              <Button
                onClick={() => {
                  this.setState({ isSubmit: true })
                  if (this.checkedFiles()) {
                    this.props.next({
                      brand: brand,
                      userId: this.props.userId,
                      category: category,
                      subcategory: subcategory,
                      name: name,
                      created: date,
                      description: description,
                      languages: languages
                        .filter((l) => l.checked)
                        .map((l) => l.value),
                      tags: tags.filter((l) => l.checked).map((l) => l.value),
                      references: references.join(', '),
                      hashtags: hashtags,
                      types: types.filter((l) => l.checked).map((l) => l.value)
                    })
                  }
                }}
                primary='true'
              >
                Next
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>
    )
  }
}

export default StepOne
