import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0;
  padding:0;
  color: white;
  font-size: 16px;
  font-weight: 500;
`

export const Icon = styled.img`
  color: white;
`
