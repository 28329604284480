import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  WrapperHeader,
  TopHeader,
  BottomHeader,
  ContainerTopMenu,
  SecondaryMenu,
  SecondaryMenuList,
  SecondaryMenuItems,
  ContainerBottomMenu,
  Logo,
  WrapperLogo,
  ContainerBottomLogin
} from './styles'

import { LogoNew } from '../../assets/img'
import SecondaryMenuComponent from './secondary-menu'
import PrimaryMenu from './primary-menu'
import MenuOverlay from './menu-overlay'
import { navigationHandleMenu } from '../../modules/navigation'
import CategoriesMenu from './categories'
import BrandsMenu from './brands'
import { logout } from '../../modules/user'
import { getCategories } from '../../modules/admin/content/categories'
import { getBrands, selectBrand } from '../../modules/admin/brands'
import { getRoles } from '../../modules/roles'
import AdminHeader from './adminheader'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.enterButton = this.enterButton.bind(this)
    this.leaveButton = this.leaveButton.bind(this)
    this.enterMenu = this.enterMenu.bind(this)
    this.leaveMenu = this.leaveMenu.bind(this)
    this.state = {
      typeMenu: 0,
      mouseOverButton: false,
      mouseOverMenu: false
    }
    this.selectBrand = this.selectBrand.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.user !== prevProps.user && !prevProps.user) {
      this.props.getCategories()
      this.props.getBrands()
      this.props.getRoles()
    }
  }

  handleClickOutside () {
    const { stateMenu, navigationHandleMenu } = this.props
    if (stateMenu) {
      navigationHandleMenu(false)
    }
  }

  enterButton = (cb) => {
    this.setState({ mouseOverButton: true }, () => {
      cb && cb()
    })
  }

  leaveButton = (cb) => {
    this.setState({ mouseOverButton: false }, () => {
      cb && cb()
    })
  }

  enterMenu = (cb) => {
    this.setState({ mouseOverMenu: true }, () => {
      cb && cb()
    })
  }

  leaveMenu = (cb) => {
    this.setState({ mouseOverMenu: false }, () => {
      cb && cb()
    })
  }

  showMenu (type) {
    const { stateMenu } = this.props
    const { mouseOverButton } = this.state
    if (!stateMenu && mouseOverButton) {
      this.handleShowMenu()
    }
    this.setState({ typeMenu: type })
  }

  handleShowMenu () {
    const { stateMenu, navigationHandleMenu } = this.props
    navigationHandleMenu(!stateMenu)
  }

  closeMenu () {
    const { stateMenu, navigationHandleMenu } = this.props
    const { mouseOverMenu, mouseOverButton } = this.state
    if (stateMenu && !mouseOverMenu && !mouseOverButton) {
      navigationHandleMenu(false)
      this.setState({ mouseOverMenu: false, mouseOverButton: false })
    }
    this.setState({ mouseOverMenu: false, mouseOverButton: false })
  }

  selectBrand (brand) {
    this.props.selectBrand(
      brand || JSON.parse(window.localStorage.getItem('selectedBrand'))
    )
    this.handleShowMenu()
  }

  getName () {
    const user = this.props.user
    if (user && this.props.roles.length) {
      const name = user.name
      const role = this.props.roles.find((x) => x.id === user.roles[0]).name
      return `Hi, ${name}! (${role})`
    }
    return null
  }

  render () {
    const { pathname } = this.props.location
    const { categories, brands, selectedBrand } = this.props
    if (selectedBrand) {
      window.localStorage.setItem(
        'selectedBrand',
        JSON.stringify(selectedBrand)
      )
    }
    if (
      pathname.indexOf('/login') !== -1 ||
      pathname.indexOf('/resetPassword') !== -1
    ) {
      return (
        <WrapperHeader>
          <TopHeader />
          <ContainerBottomLogin id='container-bottom'>
            <Link to='/'>
              <WrapperLogo>
                <Logo src={LogoNew} />
              </WrapperLogo>
            </Link>
          </ContainerBottomLogin>
        </WrapperHeader>
      )
    }
    if (pathname.indexOf('/admin') !== -1) {
      return (
        <WrapperHeader>
          <TopHeader>
            <ContainerTopMenu id='container'>
              <SecondaryMenu id='secondary-menu'>
                <SecondaryMenuList>
                  <SecondaryMenuItems>{this.getName()}</SecondaryMenuItems>
                </SecondaryMenuList>
              </SecondaryMenu>
            </ContainerTopMenu>
          </TopHeader>

          <BottomHeader>
            <ContainerBottomMenu id='container-bottom'>
              <Link to='/'>
                <WrapperLogo>
                  <Logo src={LogoNew} />
                </WrapperLogo>
              </Link>
              <AdminHeader logout={this.props.logout} />
            </ContainerBottomMenu>
          </BottomHeader>
        </WrapperHeader>
      )
    }

    return (
      <WrapperHeader>
        {!this.props.noBrands && (
          <TopHeader>
            <ContainerTopMenu id='container'>
              <SecondaryMenu id='secondary-menu'>
                <SecondaryMenuList>
                  <SecondaryMenuItems>{this.getName()}</SecondaryMenuItems>
                </SecondaryMenuList>
              </SecondaryMenu>
            </ContainerTopMenu>
          </TopHeader>
        )}
        <BottomHeader>
          <ContainerBottomMenu id='container-bottom'>
            <Link to='/'>
              <WrapperLogo>
                <Logo src={LogoNew} />
              </WrapperLogo>
            </Link>
            {!this.props.noBrands && (
              <>
                <PrimaryMenu
                  selectedBrand={JSON.parse(
                    window.localStorage.getItem('selectedBrand')
                  )}
                  showMenu={this.showMenu}
                  closeMenu={this.closeMenu}
                  enterButton={this.enterButton}
                  leaveButton={this.leaveButton}
                  pathname={pathname}
                />
                <SecondaryMenuComponent logout={this.props.logout} />
              </>
            )}
          </ContainerBottomMenu>
        </BottomHeader>
        {!this.props.noBrands && (
          <MenuOverlay
            opened={this.props.stateMenu}
            onMouseEnter={() => this.enterMenu()}
            onMouseLeave={() => {
              this.leaveMenu(() => {
                this.closeMenu()
              })
            }}
          >
            {this.state.typeMenu === 1 ? (
              <CategoriesMenu
                selectedBrand={JSON.parse(
                  window.localStorage.getItem('selectedBrand')
                )}
                categories={categories}
              />
            ) : this.state.typeMenu === 2 ? (
              <BrandsMenu
                categories={categories}
                selectedBrand={JSON.parse(
                  window.localStorage.getItem('selectedBrand')
                )}
                selectBrand={this.selectBrand}
                brands={brands}
              />
            ) : null}
          </MenuOverlay>
        )}
      </WrapperHeader>
    )
  }
}

export default connect(
  (state) => ({
    location: state.router.location,
    stateMenu: state.navigationReducer.showMenu,
    categories: state.categoriesReducer.categories,
    user: state.userReducer.user,
    brands: state.brandsReducer.brands,
    selectedBrand: state.brandsReducer.selectedBrand,
    roles: state.rolesReducer.roles
  }),
  {
    navigationHandleMenu,
    logout,
    getCategories,
    getBrands,
    selectBrand,
    getRoles
  }
)(enhanceWithClickOutside(Header))
