import React from 'react'
import { Container, Brand } from './styles'
import PropTypes from 'prop-types'
import { IconTick } from '../../../assets/img'
const PRODUCT_LIST_URL = '/list?category_id='

const renderBrands = (brands, selectBrand, categories, selectedBrand) => {
  const res = []
  brands &&
    brands.forEach((brand) => {
      res.push(
        <div key={`brand_${brand.id}`} style={{ display: 'flex' }}>
          <div style={{ width: 40, height: 40 }}>
            {selectedBrand && selectedBrand.id === brand.id ? (
              <img src={IconTick} alt='tick' />
            ) : (
              ''
            )}
          </div>
          <Brand
            disabled={!categories.filter((x) => x.brand.id === brand.id).length}
            onClick={() => {
              selectBrand(brand)
            }}
            to={`${PRODUCT_LIST_URL}${brand.id}`}
          >
            {brand.name}
          </Brand>
        </div>
      )
    })
  return res
}
const Brands = (props) => (
  <Container>
    {renderBrands(
      props.brands,
      props.selectBrand,
      props.categories,
      props.selectedBrand
    )}
  </Container>
)

Brands.propTypes = {
  padding: PropTypes.string
}

export default Brands
