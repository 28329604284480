import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import { getUserById, editUser, removeError } from '../../../../modules/admin/users'
import { getCountries } from '../../../../modules/admin/countries'
import { getRoles } from '../../../../modules/roles'
import { goToRoute } from '../../../../modules/navigation'
import Button from '../../../../components/button'
import BackButton from '../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../components/form'
import { Spinner } from '../../../../components/spinner'
import ErrorSpan from '../../../../components/errorSpan'
import getError from '../../../../utils/errors'

import { Form, Title, WrapperButton } from './styles'

class EditUser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      user: null,
      isSubmit: false
    }
    this.changeData = this.changeData.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getData = this.getData.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getUserId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getUserById(this.getUserId())
    this.props.getCountries()
    this.props.getRoles()
    this.props.removeError()
  }

  componentDidUpdate () {
    const { isFetching, user } = this.props
    if (!this.state.user && user && !isFetching) {
      this.setState({ user })
    }
  }

  changeData (e, data) {
    const { user } = this.state
    const value = e.target.value
    if (data === 'region_id') {
      user.region = { id: value }
    } else {
      user[data] = value
    }
    if (value) {
      this.setState({ user })
    }
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.user.name &&
      this.state.user.surname &&
      this.state.user.email &&
      this.state.user.region &&
      this.state.user.region.id &&
      this.state.user.username &&
      this.state.user.role
    ) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editUser(this.state.user)
    }
  }

  getData (datas) {
    if (this.props[datas]) {
      const newCountries = []
      newCountries.push({
        value: 0,
        label: '...select'
      })
      this.props[datas].forEach(data => {
        newCountries.push({
          value: data.id,
          label: data.name
        })
      })
      return newCountries
    }
  }

  render () {
    const { user, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (user !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.users.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/users'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {user.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={user.name}
                    onChange={event => this.changeData(event, 'name')}
                    placeholder='Ex. Juan'
                    type='text'
                    error={!user.name && isSubmit}
                    helperText={
                      !user.name && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Surname *'
                    defaultValue={user.surname}
                    onChange={event => this.changeData(event, 'surname')}
                    placeholder='Ex. García'
                    type='text'
                    error={!user.surname && isSubmit}
                    helperText={
                      !user.surname && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Email *'
                    defaultValue={user.email}
                    onChange={event => this.changeData(event, 'email')}
                    placeholder='Ex. juan.garcia@teka.com'
                    type='email'
                    error={!user.email && isSubmit}
                    helperText={
                      !user.email && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    label='Countries *'
                    options={this.getData('countries')}
                    defaultValue={user.region.id}
                    onChange={event => this.changeData(event, 'region_id')}
                    required
                    error={!user.region_id && isSubmit}
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Username *'
                    defaultValue={user.username}
                    onChange={event => this.changeData(event, 'username')}
                    placeholder='Ex. juangar'
                    type='text'
                    error={!user.username && isSubmit}
                    helperText={
                      !user.username && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    label='Roles *'
                    options={this.getData('roles')}
                    defaultValue={user.role}
                    onChange={event => this.changeData(event, 'role')}
                    required
                    error={!user.role && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/users`)}
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay user seleccionada</p>
  }
}

export default connect(
  state => ({
    user: state.usersReducer.user,
    countries: state.countriesReducer.countries,
    roles: state.rolesReducer.roles,
    isFetching: state.usersReducer.isFetching,
    error: state.usersReducer.error
  }),
  { getUserById, editUser, getCountries, getRoles, goToRoute, removeError }
)(injectIntl(EditUser))
