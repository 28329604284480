import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'
import { IconVisibilityBlack, IconVisibilityOffBlack } from '../../../assets/img/index'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import Button from '../../../components/button'
import { FormBoxInput, FormBoxSelect } from '../../../components/form'
import { Spinner } from '../../../components/spinner'
import ErrorSpan from '../../../components/errorSpan'

import { getRoles } from '../../../modules/roles'
import { goToRoute } from '../../../modules/navigation'
import { getUserById, editUser, removeError } from '../../../modules/admin/users'
import { Form, Title, Icon } from './styles'
import getError from '../../../utils/errors'

class Useredit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      showPassword: false,
      showConfirmPassword: false,
      userid: null,
      error: null,
      user: {},
      isSubmit: false
    }
    this.changeData = this.changeData.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getData = this.getData.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }
  getUserId () {
    const { userlogin } = this.props
    return userlogin.id
  }

  componentDidMount () {
    this.props.getUserById(this.getUserId())
    this.props.getRoles()
    this.props.removeError()
  }

  componentDidUpdate () {
    const { isFetching, user } = this.props
    if (!this.state.user.id && user && !isFetching) {
      this.setState({ user })
    }
  }

  changeData (e, data) {
    const { user } = this.state
    const value = e.target.value
    user[data] = value
    if (value) {
      this.setState({ user })
    }
  }

  handleClickShowPassword = (e, data) => {
    this.setState(state => ({ [data]: !state[data] }))
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.user.name &&
      this.state.user.surname &&
      this.state.user.email &&
      this.state.user.role
    ) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    const { user } = this.state
    if (this.chekedFiles()) {
      if (
        (user.password !== undefined || user.confirmpassword !== undefined) &&
        user.password !== user.confirmpassword
      ) {
        this.setState({ error: 'error.user.edit.password' })
      } else {
        this.props.editUser(this.state.user, true)
      }
    }
  }

  getData (datas) {
    if (this.props[datas]) {
      const newData = []
      newData.push({
        value: 0,
        label: '...select'
      })
      this.props[datas].forEach(data => {
        newData.push({
          value: data.id,
          label: data.name
        })
      })
      return newData
    }
  }

  render () {
    const newUser = this.state.user
    const { isSubmit } = this.state
    const { user, intl, isFetching } = this.props
    const error = this.state.error || this.props.error

    if (isFetching) {
      return <Spinner />
    }

    if (user !== null) {
      return (
        <Form onSubmit={this.onSubmit} key={user.id}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'your.user'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}{' '}
              </ErrorSpan>
            ) : (
              ''
            )}
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {user.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={user.name}
                    onChange={event => this.changeData(event, 'name')}
                    placeholder='Ex. Juan'
                    type='text'
                    error={!newUser.name && isSubmit}
                    helperText={
                      !newUser.name && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Surname'
                    defaultValue={user.surname}
                    onChange={event => this.changeData(event, 'surname')}
                    placeholder='Ex. García'
                    type='text'
                    error={!newUser.surname && isSubmit}
                    helperText={
                      !newUser.surname && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Email'
                    defaultValue={user.email}
                    onChange={event => this.changeData(event, 'email')}
                    placeholder='Ex. juan.garcia@teka.com'
                    type='email'
                    error={!newUser.email && isSubmit}
                    helperText={
                      !newUser.email && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    label='Role *'
                    options={this.getData('roles')}
                    defaultValue={user.role}
                    onChange={event => this.changeData(event, 'role')}
                    required
                    error={!user.role && isSubmit}
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Password'
                    onChange={event => this.changeData(event, 'password')}
                    placeholder='Ex. 123df34sa567'
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Toggle password visibility'
                          onClick={event =>
                            this.handleClickShowPassword(event, 'showPassword')
                          }
                        >
                          {this.state.showPassword ? (
                            <Icon src={IconVisibilityOffBlack} />
                          ) : (
                            <Icon src={IconVisibilityBlack} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxInput
                    label='Confirm Password'
                    onChange={event =>
                      this.changeData(event, 'confirmpassword')
                    }
                    placeholder='Ex. 123df34sa567'
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Toggle password visibility'
                          onClick={event =>
                            this.handleClickShowPassword(
                              event,
                              'showConfirmPassword'
                            )
                          }
                        >
                          {this.state.showConfirmPassword ? (
                            <Icon src={IconVisibilityOffBlack} />
                          ) : (
                            <Icon src={IconVisibilityBlack} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/users`)}
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay user seleccionada</p>
  }
}

export default connect(
  state => ({
    userlogin: state.userReducer.user,
    user: state.usersReducer.user,
    isFetching: state.usersReducer.isFetching,
    roles: state.rolesReducer.roles,
    error: state.userReducer.error
  }),
  { getRoles, getUserById, editUser, goToRoute, removeError }
)(injectIntl(Useredit))
