import React from 'react'
import DateMomentUtils from '@date-io/moment' // choose your lib
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'

export default class Calendar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      date: this.props.date || this.props.defaultDate
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  handleDateChange (date) {
    this.setState({ date })
    this.props.onChangeDate && this.props.onChangeDate(date)
  }
  render () {
    return (
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <div className='pickers'>
          <div className='picker'>
            <InlineDatePicker
              label={this.props.label || 'Calendar'}
              value={this.state.date}
              onChange={this.handleDateChange}
              keyboard
              onlyCalendar
              clearable
              placeholder='10/10/2018'
              format={'DD/MM/YYYY'}
              mask={value =>
                // handle clearing outside if value can be changed outside of the component
                value
                  ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              {...this.props}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}
