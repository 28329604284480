import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditType from '../../containers/admin/content/types/edittype'
import AddType from '../../containers/admin/content/types/addtype'
import Types from '../../containers/admin/content/types'

class RouterTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/content/types`}
          component={Types}
        />
        <Route
          path={`${this.props.match.path}/content/type/edit/:id`}
          component={EditType}
        />
        <Route
          path={`${this.props.match.path}/content/type/add`}
          component={AddType}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterTypes)
