import React from 'react'
import {
  Container,
  ImgContainer,
  ImgStyled,
  ThumbContainer,
  WrapperContainer,
  New,
  SpanNew
} from './styles'
import { NoImage } from '../../../assets/img'
import Lightbox from 'lightbox-react'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import PdfReader from '../../pdf-reader'
import Video from '../../video'
import { FormattedMessage } from 'react-intl'
import Thumb from '../../../components/thumb'
import formats from '../../../utils/formats'
const isNewContent = document => {
  if (document.created) {
    const timestamp = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
    const dateCreated = new Date(document.created.date)
    return dateCreated > timestamp
  }
  return false
}

export default class LeftSide extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }
  componentDidMount () {
    this.getStateImagen()
  }

  getStateImagen () {
    var img = new window.Image()
    const imgUrl = this.getUrlMainImage()
    img.src = imgUrl
    img.onerror = () => {
      this.setState({
        imgNotFound: NoImage
      })
    }
  }

  getImgThumbnail () {
    const img = this.getFileAllow()
    const fileThumbnail = []

    img.forEach(file => {
      const extension = file.extension.toLowerCase()
      if (extension === 'pdf') {
        fileThumbnail.push({
          url: (
            <PdfReader
              src={`${process.env.REACT_APP_ROOT_URL}${file.folder}${
                file.file
              }`}
            />
          ),
          title: file.file,
          preview: `${process.env.REACT_APP_ROOT_URL}${file.preview}`
        })
      } else if (formats.video.includes(extension)) {
        fileThumbnail.push({
          url: (
            <Video
              src={`${process.env.REACT_APP_ROOT_URL}${file.folder}${
                file.file
              }`}
            />
          ),
          title: file.file,
          preview: `${process.env.REACT_APP_ROOT_URL}${file.preview}`
        })
      } else {
        fileThumbnail.push({
          url: `${process.env.REACT_APP_ROOT_URL}${file.folder}${file.file}`,
          title: file.file
        })
      }
    })
    return fileThumbnail
  }

  getUrlMainImage () {
    // const imgs = this.getImgThumbnail()
    const document = this.props.document
    return `${process.env.REACT_APP_ROOT_URL}${document.main_image}`
  }

  getMainImage () {
    return this.state.imgNotFound || this.getUrlMainImage()
  }
  getFileAllow () {
    const notAllow = ['zip', 'pptx', 'docx']
    const img = this.props.document.files.filter(
      x =>
        notAllow.findIndex(y => y === x.extension) === -1 &&
        x.preview &&
        x.preview !== '-'
    )
    return img
  }
  renderImg () {
    // const img = 'https://tekabrandhub.com/products/mainpics/mp_tkd1280.jpg'
    const res = []
    const img = this.getFileAllow()
    img.forEach((element, i) => {
      res.push(
        <Thumb
          src={`${process.env.REACT_APP_ROOT_URL}/${element.folder}/${
            element.preview
          }`}
          index={i}
          extension={element.extension}
          onClick={() => this.setState({ isOpen: true, photoIndex: i })}
          key={i}
        />
      )
    })
    return res
  }
  render () {
    const { document } = this.props
    const { photoIndex, isOpen } = this.state
    const imgs = this.getImgThumbnail()
    return (
      <Container>
        <ImgContainer>
          <WrapperContainer>
            {isNewContent(document) ? (
              <New>
                <SpanNew>
                  <FormattedMessage id={'card.new'} />
                </SpanNew>
              </New>
            ) : null}
            <ImgStyled src={this.getMainImage()} />
          </WrapperContainer>
          <ThumbContainer>{this.renderImg()}</ThumbContainer>
        </ImgContainer>
        {isOpen && (
          <Lightbox
            imageTitle={imgs[photoIndex].title}
            mainSrc={imgs[photoIndex].url}
            nextSrc={imgs[(photoIndex + 1) % imgs.length].url}
            prevSrc={imgs[(photoIndex + imgs.length - 1) % imgs.length].url}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + imgs.length - 1) % imgs.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imgs.length
              })
            }
          />
        )}
      </Container>
    )
  }
}
