import React, { useState } from 'react'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import {
  sendFirstStep,
  uploadFile,
  sendSecondStep,
  sendThirdStep
} from './requests'
import { withRouter } from 'react-router-dom'
import PreventLeave from '../../components/preventLeave'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { goToRoute } from '../../modules/navigation'
import Edit from './edit'
import SimpleDialog from '../../components/simpleDialog'

const Component = ({ userId, goToRoute, match, userLogin, intl }) => {
  const [step, setStep] = useState('one')
  const [uploadId, setUploadId] = useState(null)
  const [s3Data, setS3Data] = useState(null)
  const [statusModal, setStatusModal] = useState(false)
  const [prevent, setPrevent] = useState(true)
  const [contentData, setContentData] = useState({})
  if (match && match.params && match.params.id) {
    if (userLogin.roles[0] === 'ROLE_ADMIN_GEN') {
      return <Edit id={match.params.id} userId={userId} />
    }
    goToRoute('/')
  }
  const renderModalExit = () => {
    return (
      prevent && (
        <PreventLeave
          idIntlTitle='upload.leave.title'
          idIntlText='upload.leave.text'
        />
      )
    )
  }

  switch (step) {
    case 'one':
      return (
        <>
          {renderModalExit()}
          <StepOne
            userId={userId}
            intl={intl}
            contentData={contentData}
            edit={match && match.params ? match.params.id : null}
            next={async (data) => {
              setContentData({
                ...data,
                reference: data.references,
                hashtags: data.hashtags,
                selectedTags: data.tags
              })
              setUploadId(await sendFirstStep(data))
              setStep('two')
            }}
          />
        </>
      )
    case 'two':
      return (
        <>
          {renderModalExit()}
          <StepTwo
            userId={userId}
            uploadId={uploadId}
            uploadFile={uploadFile}
            back={() => {
              setStep('one')
            }}
            next={async (data) => {
              setS3Data(await sendSecondStep(data))
              setStep('three')
            }}
          />
        </>
      )
    case 'three':
      return (
        <>
          {renderModalExit()}
          <StepThree
            data={s3Data}
            uploadId={uploadId}
            next={async (previewId) => {
              await sendThirdStep({
                previewId,
                userId,
                uploadId,
                completed: true
              })
              setPrevent(false)
              setStatusModal(true)
            }}
            setpreview={async (files, cb) => {
              setS3Data(
                await sendThirdStep({
                  userId,
                  uploadId,
                  preview: files[0],
                  completed: true
                })
              )
              setPrevent(false)
              setStatusModal(true)
              cb && cb()
            }}
          />
          <SimpleDialog
            handleClose={() => {
              setStatusModal(false)
              goToRoute('/')
            }}
            handleOk={async () => {
              setStatusModal(false)
              // hacemos el reject
              goToRoute('/')
            }}
            textHandleOk={'Ok'}
            noCancel
            open={statusModal}
          >
            <div>
              <span style={{ display: 'block', fontSize: '17px' }}>
                <FormattedMessage id='upload.success.upload' />
              </span>
              <span style={{ display: 'block', fontSize: '13px' }}>
                <FormattedMessage id='upload.success.uploadPending' />
              </span>
            </div>
          </SimpleDialog>
        </>
      )
    default:
      return null
  }
}

export default withRouter(
  connect(
    (state) => ({
      userId: state.userReducer.user.id,
      userLogin: state.userReducer.user
    }),
    { goToRoute }
  )(injectIntl(Component))
)
