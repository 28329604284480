import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../components/button'
import BackButton from '../../../../components/back-button'
import { FormBoxInput } from '../../../../components/form'
import { Spinner } from '../../../../components/spinner'
import ErrorSpan from '../../../../components/errorSpan'
import getError from '../../../../utils/errors'
import {
  getCountryById,
  editCountry,
  removeError
} from '../../../../modules/admin/countries'
import { goToRoute } from '../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class EditCountry extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      country: null
    }
    this.changeName = this.changeName.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getCountryId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getCountryById(this.getCountryId())
  }

  componentDidUpdate () {
    const { isFetching, country } = this.props
    if (!this.state.country && country && !isFetching) {
      this.setState({ country })
    }
    this.props.removeError()
  }

  changeName (e) {
    const { country } = this.state
    country.name = e.target.value
    this.setState({
      country
    })
  }

  chekedFiles () {
    let validate = false
    if (this.state.country.name) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editCountry(this.state.country)
    }
  }

  render () {
    const { country, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (country !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.country.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/countries'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {country.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={country.name}
                    onChange={this.changeName}
                    placeholder='Ex. Spain'
                    type='text'
                    error={!country.name && isSubmit}
                    helperText={
                      !country.name && isSubmit ? 'El campo es obligatorio' : ''
                    }
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/countries`)}
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay countries seleccionada</p>
  }
}

export default connect(
  state => ({
    country: state.countriesReducer.country,
    isFetching: state.countriesReducer.isFetching,
    error: state.countriesReducer.error
  }),
  { getCountryById, editCountry, goToRoute, removeError }
)(injectIntl(EditCountry))
