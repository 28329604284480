import React from 'react'
import GestorList from '../../components/gestor-list'
import Title from '../../components/basics/title'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

const getCategories = ({ match, categories }) => {
  const currentCategory = match.params.id_category
  const category = (categories || []).find(x => x.id + '' === currentCategory)
  return category ? `CONTENT/${category.name}` : 'List'
}
const List = props => (
  <div>
    <Title>{`${getCategories(props)}`}</Title>
    <GestorList category {...props} />
  </div>
)

export default withRouter(
  connect(
    state => ({
      categories: state.categoriesReducer.categories
    }),
    {}
  )(injectIntl(List))
)
