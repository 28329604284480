import FormControl from '@material-ui/core/FormControl'

import OutlinedInput from '@material-ui/core/OutlinedInput'

import React from 'react'
import { SelectStyled, MenuItemStyled } from './styles'
import PropTypes from 'prop-types'

const MockOptions = [
  {
    value: 10,
    label: 'ten'
  },
  {
    value: 20,
    label: 'twenty'
  },
  {
    value: 30,
    label: 'thirty'
  }
]

const buildOptions = props => {
  const res = []
  const options = props.options || MockOptions || []
  res.push(
    <MenuItemStyled className='menuItem' value={0}>
      {props.placeholder || 'Select'}
    </MenuItemStyled>
  )
  options.forEach(element => {
    res.push(
      <MenuItemStyled value={element.value}>{element.label}</MenuItemStyled>
    )
  })
  return res
}
class FilterSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultValue: this.props.defaultValue || 0,
      value: this.props.value
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    this.checkSelect()
  }

  checkSelect () {
    if (
      this.state.value &&
      this.state.value !== (this.props.defaultValue || 0)
    ) {
      this.setState({ selected: true })
    } else if (
      !this.state.value ||
      this.state.value === (this.props.defaultValue || 0)
    ) {
      this.setState({ selected: false })
    }
  }
  componentDidUpdate () {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value }, () => {
        this.checkSelect()
      })
    }
  }

  handleChange (event) {
    let selected = this.state.select
    const newValue = event.target.value
    if (!selected && newValue !== (this.props.defaultValue || 0)) {
      selected = true
    }
    this.setState({ value: newValue, selected })
    if (this.props.onChange) {
      this.props.onChange(event, newValue)
    }
  }

  setValue (value) {
    let selected = this.state.selected
    if (value === (this.props.defaultValue || 0)) {
      selected = false
    }
    this.setState({ value, selected })
  }

  render () {
    const props = this.props
    return (
      <FormControl fullWidth variant='outlined' className='form-control'>
        <SelectStyled
          disabled={this.props.disabled}
          value={this.state.value || this.state.defaultValue}
          onChange={this.handleChange}
          selected={this.state.selected}
          classes={{ root: 'rooting' }}
          className='select'
          InputProps={{
            classes: {
              focused: 'focused',
              root: 'rootBa'
            }
          }}
          input={
            <OutlinedInput
              classes={{
                focused: 'focused',
                root: 'selectroot',
                notchedOutline: 'outline'
              }}
              labelWidth={0}
              name={props.label}
              id='outlined-age-simple'
            />
          }
        >
          {buildOptions(props)}
        </SelectStyled>
      </FormControl>
    )
  }
}

FilterSelect.propTypes = {
  padding: PropTypes.string
}

export default FilterSelect
