import React from 'react'
import { connect } from 'react-redux'

import { goToRoute } from '../../../../modules/navigation'

import { UserIcon } from '../../../../assets/img'
import Menulist from '../../../../components/menulist'
import { Wrapper, Icon } from './styles'

const RightMenu = props => (
  <Wrapper>
    <Menulist logout={props.logout} padding='0px'>
      <Icon src={UserIcon} />
    </Menulist>
  </Wrapper>
)

export default connect(
  state => ({}),
  { goToRoute }
)(RightMenu)
