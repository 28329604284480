import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { getDashboard } from '../../../modules/admin/dashboard'
import { goToRoute } from '../../../modules/navigation'

import { IconNotifications, IconCopy } from '../../../assets/img'

import {
  Title,
  Notifications,
  Icon,
  IconNotification,
  Text,
  Wrapper,
  Content,
  LinkStyled
} from './styles'

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
    this.getData = this.getData.bind(this)
  }
  componentDidMount () {
    this.props.getDashboard()
  }

  getData () {
    const { dashboard } = this.props
    const data = dashboard.reverse().map(notification => {
      return (
        <Wrapper>
          <Icon src={IconCopy} />
          <Text>New Content uploaded:</Text>
          <LinkStyled to={`upload/${notification.id_document}`}>
            <Content>{notification.document}</Content>
          </LinkStyled>
        </Wrapper>
      )
    })
    return data
  }

  render () {
    const { dashboard } = this.props
    return (
      <Fragment>
        <Title>Dashboard</Title>
        <Notifications>
          <IconNotification src={IconNotifications} />
          <Text>{`${dashboard.length} new contents to approve or deny`}</Text>
        </Notifications>
        {this.getData()}
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    dashboard: state.dashboardReducer.dashboard
  }),
  { getDashboard, goToRoute }
)(injectIntl(Dashboard))
