import styled from 'styled-components'

export const Body = styled.body`
  box-shadow: none;
`
export const Wrapper = styled.div`
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  padding-top: 150px;
`

export const Title = styled.h1`
  color: #000000;
  font-size: 34px;
  font-weight: bold;
  line-height: 40px;
`

export const WrapperText = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 56px;
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
`
