import styled from 'styled-components'
import Button from '../../components/button'

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-around;
  }
`
export const WrapperHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 460px;
`

export const WrapperBackButton = styled.div`
  white-space: nowrap;
  padding-right: 10px;
`

export const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 0;
  white-space: nowrap;
`

export const WrapperButtons = styled.div`
  display: flex;
  align-items: baseline;
`
export const WrapperShare = styled.div`
  padding: 0 10px;
`

export const Icon = styled.img`
  margin-right: 10px;
`

export const ButtonStyled = styled(Button)`
  white-space: nowrap;
`
