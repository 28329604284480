import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TitleBold = styled.p`
  font-size: 32px;
  font-weight: normal;
  letter-spacing: -0.64px;
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  margin-bottom: 65px;
`
