import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditBrand from '../../containers/admin/brands/editbrand'
import AddBrand from '../../containers/admin/brands/addbrand'
import Brands from '../../containers/admin/brands'

class RouterBrands extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/brands`}
          component={Brands}
        />
        <Route
          path={`${this.props.match.path}/brand/edit/:id`}
          component={EditBrand}
        />
        <Route
          path={`${this.props.match.path}/brand/add`}
          component={AddBrand}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterBrands)
