import React, { Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import EditHashtag from '../../containers/admin/content/hashtags/edithashtag'
import AddHashtag from '../../containers/admin/content/hashtags/addhashtag'
import Hashtags from '../../containers/admin/content/hashtags'

class RouterHashtags extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es'
    }
  }

  render () {
    return (
      <Fragment>
        <Route
          path={`${this.props.match.path}/content/hashtags`}
          component={Hashtags}
        />
        <Route
          path={`${this.props.match.path}/content/hashtag/edit/:id`}
          component={EditHashtag}
        />
        <Route
          path={`${this.props.match.path}/content/hashtag/add`}
          component={AddHashtag}
        />
      </Fragment>
    )
  }
}

export default withRouter(RouterHashtags)
