import { push } from 'connected-react-router'
import { request, getIdToken } from '../../utils/request'

const loginRequest = () => ({ type: 'USER/LOGIN_REQUEST' })

const loginSuccess = token => ({
  type: 'USER/LOGIN_SUCCESS',
  token
})

const loginFailure = error => ({
  type: 'USER/LOGIN_FAILURE',
  error
})

const logoutRequest = () => ({ type: 'USER/LOGOUT' })

const verifyRequest = () => ({ type: 'USER/VERIFY_REQUEST' })

export const logout = () => dispatch => {
  dispatch(logoutRequest())
  dispatch(push('/login'))
}

export const verifyFailure = error => ({
  type: 'USER/VERIFY_FAILURE',
  error
})

export const verifySuccess = data => ({
  type: 'USER/VERIFY_SUCCESS',
  data
})

const forgetUserRequest = email => ({ type: 'USER/FORGET_REQUEST', email })

const forgetUserSuccess = () => ({
  type: 'USER/FORGET_SUCCESS'
})

const forgetUserFailure = error => ({
  type: 'USER/FORGET_FAILURE',
  error
})

const verifyTokenUserForgetRequest = token => ({
  type: 'USER/VERIFY_TOKEN_FORGET_REQUEST',
  token
})

const verifyTokenUserForgetSuccess = token => ({
  type: 'USER/VERIFY_TOKEN_FORGET_SUCCESS',
  token
})

const verifyTokenUserForgetFailure = error => ({
  type: 'USER/VERIFY_TOKEN_FORGET_FAILURE',
  error
})

const setNewPasswordRequest = email => ({
  type: 'USER/SET_PASSWORD_REQUEST',
  email
})

const setNewPasswordSuccess = token => ({
  type: 'USER/SET_PASSWORD_SUCCESS',
  token
})

const setNewPasswordFailure = error => ({
  type: 'USER/SET_PASSWORD_FAILURE',
  error
})

export const login = data => {
  return (dispatch, getState, extraArguments) => {
    dispatch(loginRequest())
    // login
    const body = {
      username: data.username,
      password: data.password
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }

    return request(`auth/login`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(loginFailure(res.error || res.code === 401))
          return false
        }
        dispatch(loginSuccess(res.token))
        dispatch(verify())
        return true
      })
      .catch(err => {
        dispatch(loginFailure(err))
      })
  }
}

export const verify = () => {
  return async (dispatch, getState, extraArguments) => {
    const idToken = getIdToken()
    dispatch(verifyRequest())
    if (!idToken) {
      if (getState().router.location.pathname.indexOf('resetPassword') === -1) {
        dispatch(push('/login'))
      }
      return dispatch(verifyFailure('NO_TOKEN'))
    }

    return request(`auth/verify`, {})
      .then(async res => {
        if (res.error || res.code === 401) {
          dispatch(verifyFailure('NO_TOKEN'))
          dispatch(push('/login'))
          return false
        }
        await firstLoad(dispatch, getState, extraArguments)
        dispatch(verifySuccess(res))
        return true
      })
      .catch(err => {
        dispatch(verifyFailure(err))
        dispatch(push('/login'))
      })
  }
}

const firstLoad = async (dispatch, getState, extraArguments) => {
  try {
    // First load if necesary
    const currentPath = getState().router.location.pathname
    if (currentPath === '/login') {
      dispatch(push('/'))
    }
  } catch (error) {
    throw error
  }
}

export const forgetPassword = email => {
  return dispatch => {
    forgetUserRequest(email)
    // change password if necesary
    const body = {
      email: email
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`auth/resetpassword`, requestOptions)
      .then(async res => {
        if (res.error || res.code === 401) {
          dispatch(forgetUserFailure(res.error || res.message))
        }
        dispatch(forgetUserSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(forgetUserFailure(err))
      })
  }
}

export const verifyToken = token => {
  return dispatch => {
    verifyTokenUserForgetRequest(token)
    // change password if necesary
    const body = {
      token: token
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`auth/verifyToken`, requestOptions)
      .then(async res => {
        if (res.error || res.code === 401) {
          dispatch(verifyTokenUserForgetFailure((res.error || res.message)))
          return false
        }
        dispatch(verifyTokenUserForgetSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(verifyTokenUserForgetFailure(err))
      })
  }
}

export const setNewPassword = password => {
  return dispatch => {
    setNewPasswordRequest(password)
    // change password if necesary
    const body = {
      password
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`auth/setNewPassword`, requestOptions)
      .then(async res => {
        if (res.error || res.code === 401) {
          dispatch(setNewPasswordFailure(res.error || res.message))
          return false
        }
        dispatch(setNewPasswordSuccess(res))
        dispatch(push('/login'))
        return true
      })
      .catch(err => {
        dispatch(setNewPasswordFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'USER/REMOVE_ERROR'
})
