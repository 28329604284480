import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import { downloadFilesPublicURL } from './requests'
import { requestDownload } from '../../../../utils/request'

import {
  DescriptionIcon,
  MovieIcon,
  PhotoIcon,
  ZipIcon,
  IconDelete
} from '../../../../assets/img'
import IButton from '../../../iconbutton'

import {
  ButtonStyled,
  CheckboxStyled,
  ButtonWrapper,
  Container,
  CellFile,
  Ellipsis,
  ContainerFiles,
  ItemFiles,
  WrapperLinear,
  WrapperProgress
} from './styles'

function compare (a, b) {
  const fileA = a.file.toUpperCase()
  const fileB = b.file.toUpperCase()

  let comparison = 0
  if (fileA > fileB) {
    comparison = 1
  } else if (fileA < fileB) {
    comparison = -1
  }
  return comparison
}
class FileList extends React.Component {
  constructor (props) {
    super(props)
    this.downloadAll = this.downloadAll.bind(this)
    this.downloadSelect = this.downloadSelect.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      filesSelected: []
    }
  }
  renderIcon (type) {
    type = type.toLowerCase()
    switch (type) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'img': {
        return <img src={PhotoIcon} alt='elements' />
      }
      case 'mov':
      case 'mp4': {
        return <img src={MovieIcon} alt='movie' />
      }
      case 'zip': {
        return <img src={ZipIcon} alt='zip' />
      }
      case 'pdf':
      case 'doc': {
        return <img src={DescriptionIcon} alt='document' />
      }
      default: {
        return <img src={DescriptionIcon} alt='document' />
      }
    }
  }

  buildFiles () {
    const res = []
    const { files } = this.props
    const filesProps = files
    filesProps.sort(compare)
    if (!files || !files.length) {
      return (
        <p>
          <FormattedMessage id={'document.noFiles'} />
        </p>
      )
    }
    res.push(
      <ItemFiles key={`th_type`}>
        <CellFile>Type</CellFile>
      </ItemFiles>,
      <ItemFiles key={`th_name`}>
        <CellFile>Name</CellFile>
      </ItemFiles>,
      <ItemFiles key={`th_format`}>
        <CellFile>Format</CellFile>
      </ItemFiles>,
      <ItemFiles key={`th_quality`}>
        <CellFile>Quality</CellFile>
      </ItemFiles>,
      <ItemFiles key={`th_size`}>
        <CellFile>Size</CellFile>
      </ItemFiles>,
      !this.props.noDownloadButtons ? (
        <ItemFiles key={`th_select`}>
          <CellFile>Select</CellFile>
        </ItemFiles>
      ) : (
        <ItemFiles key={`th_select`}>
          <CellFile />
        </ItemFiles>
      )
    )

    filesProps.forEach((file, index) => {
      res.push(
        <ItemFiles key={`td_image_${index}_${file.file}`}>
          <CellFile>{this.renderIcon(file.extension)}</CellFile>
        </ItemFiles>
      )
      res.push(
        <ItemFiles key={`td_file_${index}_${file.file}`}>
          <Ellipsis>{file.fileToShow}</Ellipsis>
        </ItemFiles>
      )
      res.push(
        <ItemFiles key={`td_extension_${index}_${file.file}`}>
          <CellFile>{file.extension}</CellFile>
        </ItemFiles>
      )
      res.push(
        <ItemFiles key={`td_quality_${index}_${file.file}`}>
          <CellFile>{file.quality || '—'}</CellFile>
        </ItemFiles>
      )
      res.push(
        <ItemFiles key={`th_size_${index}_${file.file}`}>
          <CellFile>{file.size || '—'}</CellFile>
        </ItemFiles>
      )
      if (!this.props.noDownloadButtons) {
        res.push(
          <ItemFiles key={`th_checkbox_${index}_${file.file}`} end>
            <CheckboxStyled
              onChange={this.onChange}
              color='default'
              value={`${file.id}`}
            />
          </ItemFiles>
        )
      } else {
        res.push(
          <ItemFiles key={`th_checkbox_${index}_${file.file}`} end>
            <IButton
              onClick={() => {
                this.props.removeFiles(file.id)
              }}
            >
              <img src={IconDelete} alt='icon delete' />
            </IButton>
          </ItemFiles>
        )
      }
    })
    return res
  }

  async downloadAll () {
    const docsFiles = this.props.files.map((x) => x.id)
    if (window.location.href.includes('?token=')) {
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      const token = params.get('token')
      const path = await downloadFilesPublicURL(token, docsFiles)
      requestDownload(path)
    } else {
      this.props.downloadFiles(docsFiles)
    }
  }

  async downloadSelect () {
    const filesSelected = this.state.filesSelected
    if (window.location.href.includes('?token=')) {
      const queryString = window.location.search
      const params = new URLSearchParams(queryString)
      const token = params.get('token')
      const path = await downloadFilesPublicURL(token, filesSelected)
      requestDownload(path)
    } else {
      this.props.downloadFiles(filesSelected)
    }
  }

  onChange (event) {
    const checked = event.target.checked
    const value = event.target.value
    let filesSelected = this.state.filesSelected
    if (!checked) {
      filesSelected = filesSelected.filter((x) => x !== value)
    } else {
      filesSelected.push(value)
    }
    this.setState({ filesSelected })
  }

  render () {
    const {
      files,
      isDownloading,
      downloaded,
      intl,
      noDownloadButtons
    } = this.props
    return (
      <Container>
        <ContainerFiles>{this.buildFiles()}</ContainerFiles>
        {files && files.length ? (
          <>
            {!noDownloadButtons ? (
              <ButtonWrapper id='botonera'>
                <ButtonStyled nohover primary='true' onClick={this.downloadAll}>
                  <FormattedMessage id={'document.downloadAll'} />
                </ButtonStyled>
                <ButtonStyled
                  primary='true'
                  nohover
                  disabled={!this.state.filesSelected.length}
                  onClick={this.downloadSelect}
                >
                  <FormattedMessage id={'document.dounaloadSelected'} />
                </ButtonStyled>
              </ButtonWrapper>
            ) : null}
            {isDownloading || downloaded ? (
              <WrapperProgress column mt={10} w={1}>
                <Box p={3}>
                  <Flex justify={'center'} align={'center'} w={1}>
                    <p>
                      {isDownloading
                        ? intl.formatMessage({
                          id: 'document.download.preparing'
                        })
                        : intl.formatMessage({
                          id: 'document.download.finished'
                        })}
                    </p>
                  </Flex>

                  {isDownloading ? (
                    <WrapperLinear w={1}>
                      <LinearProgress secondary />
                    </WrapperLinear>
                  ) : null}
                </Box>
              </WrapperProgress>
            ) : null}
          </>
        ) : null}
      </Container>
    )
  }
}

export default connect((state) => ({
  isDownloading: state.documentReducer.isDownloading,
  downloaded: state.documentReducer.downloaded
}))(injectIntl(FileList))
