import React from 'react'
import { ButtonStyled } from './styles'
import PropTypes from 'prop-types'

const Button = props => <ButtonStyled {...props} />

Button.propTypes = {
  padding: PropTypes.string,
  secondary: PropTypes.string
}

export default Button
