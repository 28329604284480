import React from 'react'

import LeftHeader from './left-menu'
import RightHeader from './right-menu'
import { Wrapper } from './styles'

const AdminHeader = props => (
  <Wrapper>
    <LeftHeader />
    <RightHeader logout={props.logout} />
  </Wrapper>
)

export default AdminHeader
