import styled from 'styled-components'

export const Container = styled.div`
  @media (min-width: 900px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ImgContainer = styled.div``

export const ImgStyled = styled.img`

max-width: 100%
max-height: 100%;
`

export const ThumbContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const WrapperContainer = styled.div`
  margin: 0 auto;
  padding-right: 15px;
  position: relative;
  max-width: 600px;
`

export const New = styled.div`
  position: absolute;
  top: 6px;
  left: 0px;
`

export const SpanNew = styled.span`
  background: ${({ theme }) => theme.colors.colorTekaRed};
  padding: 5px 10px 5px 10px;
  color: #fff;
`

export const WrapperProp = styled.div`
  margin: 20px 0
  display: flex;
`

export const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  min-width: 30%;
`

export const WrapperDescription = styled.div`
  min-width: 70%;
  text-align: left;
  margin-left: 30px;
`
export const Label = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 5px 0;
  text-align: left;
`

export const Img = styled.img`
  height: 100%;
  max-height: 32px;
  opacity: 0.6;
  padding: 0px;
  :hover {
    opacity: 1;
  }
  .brands {
    opacity: 1;
  }
`

export const StatusText = styled.p`
  padding-right: 12px;
`
export const WrapperWarning = styled.div`
  display: flex;
`

export const ImgInfo = styled.img`
  padding-right: 5px;
`

export const WarningText = styled.p`
  color: #C7102D;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 16px;
`
