const initialState = {
  isFetching: false,
  countries: [],
  country: null
}

export const Countries = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'COUNTRIES/DATA_REQUEST':
    case 'COUNTRY/DATA_REQUEST_DELETE':
    case 'COUNTRY_ID/DATA_REQUEST':
    case 'COUNTRY/DATA_REQUEST_EDIT':
    case 'COUNTRY/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'COUNTRIES/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        countries: []
      }

    case 'COUNTRY_ID/DATA_FAILURE':
    case 'COUNTRY/DATA_FAILURE_EDIT':
    case 'COUNTRY/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        country: null,
        error: action.error
      }
    }
    case 'COUNTRY/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        country: null,
        errorModal: action.error
      }
    }
    // success
    case 'COUNTRY_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        country: action.country,
        error: null
      }
    case 'COUNTRIES/DATA_SUCCESS':
    case 'COUNTRY/DATA_SUCCESS_DELETE':
    case 'COUNTRY/DATA_SUCCESS_EDIT':
    case 'COUNTRY/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        countries: action.countries,
        error: null
      }
    case 'COUNTRY/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
