import formats from '../../utils/formats'
const setBrandFields = (doc, brands) => {
  if (!doc.brand) {
    return null
  }
  const brandDoc = doc.brand.id

  const isBrandInsert = !!brands.find(x => x === brandDoc)
  if (!isBrandInsert) {
    brands.push(brandDoc)
  }
  return brands
}

const setCategoryFields = (doc, categories) => {
  if (!doc.category) {
    return null
  }
  const categoryDoc = doc.category.id

  const isCategoryInsert = !!categories.find(x => x === categoryDoc)
  if (!isCategoryInsert) {
    categories.push(categoryDoc)
  }
  return categories
}

const setSubcategoryFields = (doc, subcategories) => {
  if (!doc.subcategory) {
    return null
  }
  const subcategoryDoc = doc.subcategory.id

  const isBrandInsert = !!subcategories.find(x => x === subcategoryDoc)
  if (!isBrandInsert) {
    subcategories.push(subcategoryDoc)
  }
  return subcategories
}

const setTypesFields = (doc, elements) => {
  if (!doc.types) {
    return null
  }
  const typesDoc = doc.types
  typesDoc.forEach(type => {
    const isTypesInsert = !!elements.find(x => x === type.id)
    if (!isTypesInsert) {
      elements.push(type.id)
    }
    return elements
  })
}

const setFormatFields = (doc, format) => {
  if (!doc.files || doc.files.length === 0 || format.length === 3) {
    return null
  }
  const filesDoc = doc.files
  const existImage = filesDoc.find(x => formats.img.includes(x))
  if (existImage) {
    const isImageInsert = !!format.find(x => x === 'image')
    if (!isImageInsert) {
      format.push('image')
    }
  }
  const existVideo = filesDoc.find(x => formats.video.includes(x))
  if (existVideo) {
    const isVideoInsert = !!format.find(x => x === 'video')
    if (!isVideoInsert) {
      format.push('video')
    }
  }
  const existDocument = filesDoc.find(x => formats.doc.includes(x))
  if (existDocument) {
    const isDocumentInsert = !!format.find(x => x === 'doc')
    if (!isDocumentInsert) {
      format.push('doc')
    }
  }
  return format
}

const setLanguageFields = (doc, languages) => {
  if (!doc.languages) {
    return null
  }
  const languagesDoc = doc.languages
  languagesDoc.forEach(lang => {
    const isLanguageInsert = !!languages.find(x => x === lang.id)
    if (!isLanguageInsert) {
      languages.push(lang.id)
    }
    return languages
  })
}

const setDateFields = (doc, dates) => {
  if (!doc.created) {
    return null
  }

  const createdDoc = doc.created
  const from = dates.from
  const to = dates.to
  if (createdDoc.date) {
    const dateDoc = new Date(createdDoc.date)
    const modDate = new Date(dateDoc.setDate(dateDoc.getDate() + 1))
    if (from) {
      const fromDate = new Date(from)
      if (fromDate > new Date(modDate)) {
        dates.from = new Date(modDate)
      }
    } else {
      dates.from = new Date(modDate)
    }
    if (to) {
      const toDate = new Date(to)
      if (toDate < new Date(modDate)) {
        dates.to = new Date(modDate)
      }
    } else {
      dates.to = new Date(modDate)
    }
  }
}

export const selectFieldsAllows = state => {
  const totalResults = state.listReducer.totalResultsRaw
  const propertiesAllows = {
    brand: [],
    category: [],
    subcategory: [],
    format: [],
    language: [],
    type: [],
    date: {}
  }
  if (!totalResults) {
    return propertiesAllows
  }

  totalResults.forEach(doc => {
    setBrandFields(doc, propertiesAllows['brand'])
    setCategoryFields(doc, propertiesAllows['category'])
    setSubcategoryFields(doc, propertiesAllows['subcategory'])
    setFormatFields(doc, propertiesAllows['format'])
    setLanguageFields(doc, propertiesAllows['language'])
    setDateFields(doc, propertiesAllows['date'])
    setTypesFields(doc, propertiesAllows['type'])
  })
  return propertiesAllows
}
