import React from 'react'
import PropTypes from 'prop-types'

import { IconArrowBlack } from '../../assets/img/index.js'

import { ButtonStyled, LinkStyled, Img } from './styles'

const BackButton = props =>
  !props.button ? (
    <LinkStyled to={props.to}>
      <ButtonStyled {...props}>
        <Img src={IconArrowBlack} alt='arrow' />
        {(props.text || 'back')}
      </ButtonStyled>
    </LinkStyled>
  ) : (
    <ButtonStyled {...props}>
      <Img src={IconArrowBlack} alt='arrow' />
      {(props.text || 'back')}
    </ButtonStyled>
  )

BackButton.propTypes = {
  padding: PropTypes.string
}

export default BackButton
