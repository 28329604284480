import styled from 'styled-components'
export const MenuOverlay = styled.div`
  height: 100%;
  min-height: 300px;
  display: flex;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  background: grey;
  width: 80vw;
  z-index: 1000000;
`

export const ContentMenuOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  margin: 5px 20px;
  
`

export const CalendarWrapper = styled.div`
  padding: 10px;
`
export const ChipWrapper = styled.div`
  margin: 0 5px;
`
