import styled from 'styled-components'

export const Span = styled.span`
  ${({ bold, theme }) => bold && `font-weight ${theme.fontWeights.bold}`};
`

export const SpanMargin = styled.span`
  ${({ bold, theme }) => bold && `font-weight ${theme.fontWeights.bold}`};
  margin-bottom: 7px;
  display: block;
`
