import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import {
  addType,
  removeError
} from '../../../../../modules/admin/content/types'
import { goToRoute } from '../../../../../modules/navigation'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { Spinner } from '../../../../../components/spinner'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import ErrorSpan from '../../../../../components/errorSpan'
import { getSubcategories } from '../../../../../modules/admin/content/subcategories'
import { getBrands } from '../../../../../modules/admin/brands'
import { getCategories } from '../../../../../modules/admin/content/categories'
import getError from '../../../../../utils/errors'

import { Form, Title, WrapperButton } from './styles'

class AddType extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      typec: {},
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.changeBrand = this.changeBrand.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }
  componentDidMount () {
    this.props.getBrands()
    this.props.getCategories()
    this.props.getSubcategories()
    this.props.removeError()
  }

  changeName (e) {
    const { typec } = this.state
    typec.name = e.target.value
    this.setState({
      typec
    })
  }

  changeBrand (e, value) {
    this.setState({
      brand: value
    })
  }

  getOptionsBrands () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  changeSelect (e, value, element) {
    const { typec } = this.state
    typec[element] = { id: value }
    this.setState({
      typec
    })
  }

  getOptions (elements) {
    if (this.props[elements]) {
      const newOptions = []
      let type = elements === 'categories' ? 'brand' : 'category'
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props[elements]
        .filter(x =>
          type === 'brand'
            ? x[type].id === this.state.brand
            : x[type].id ===
              (this.state.typec.category && this.state.typec.category.id)
        )
        .forEach(e => {
          newOptions.push({
            value: e.id,
            label: e.name
          })
        })
      return newOptions
    }
  }

  chekedFiles () {
    let validate = false
    if (
      this.state.typec.name &&
      this.state.typec.category.id &&
      this.state.typec.subcategory.id
    ) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.addType(this.state.typec)
    }
  }

  render () {
    const { typec, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    return (
      <Form onSubmit={this.onSubmit}>
        <Flex column style={{ textAlign: 'center' }}>
          <Title>
            <FormattedMessage id={'admin.type.add'} />
          </Title>
          {error ? (
            <ErrorSpan>{intl.formatMessage({ id: getError(error) })}</ErrorSpan>
          ) : (
            ''
          )}
          <WrapperButton>
            <BackButton
              to='/admin/content/types'
              text={intl.formatMessage({ id: 'admin.back' })}
            />
          </WrapperButton>
          <Flex justify='center' style={{ textAlign: 'left' }}>
            <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
              <Box mb={3} w={1}>
                <FormBoxInput
                  label='Name *'
                  defaultValue={typec.name}
                  onChange={this.changeName}
                  placeholder='Ex. Logos'
                  type='text'
                  error={!typec.name && isSubmit}
                  helperText={
                    !typec.name && isSubmit ? 'El campo es obligatorio' : ''
                  }
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  options={this.getOptionsBrands()}
                  value={this.state.brand}
                  onChange={this.changeBrand}
                  required
                  label='Brand *'
                  error={!this.state.brand && isSubmit}
                  defaultValue={''}
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  options={this.getOptions('categories')}
                  onChange={(e, value) =>
                    this.changeSelect(e, value, 'category')
                  }
                  required
                  label='Category *'
                  error={typec.category && !typec.category.id && isSubmit}
                  defaultValue={''}
                />
              </Box>
              <Box mb={3}>
                <FormBoxSelect
                  options={this.getOptions('subcategories')}
                  onChange={(e, value) =>
                    this.changeSelect(e, value, 'subcategory')
                  }
                  required
                  label='Subcategory *'
                  error={typec.subcategory && !typec.subcategory.id && isSubmit}
                  defaultValue={''}
                />
              </Box>
              <Flex justify='space-between'>
                <Button
                  secundary
                  onClick={() => this.props.goToRoute(`/admin/content/types`)}
                  padding='5px 40px'
                >
                  <FormattedMessage id={'admin.cancel'} />
                </Button>
                <Button primary='true' type='submit' padding='5px 40px'>
                  <FormattedMessage id={'admin.submit'} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    )
  }
}

export default connect(
  state => ({
    typec: state.typesReducer.typec,
    subcategories: state.subcategoriesReducer.subcategories,
    categories: state.categoriesReducer.categories,
    isFetching: state.typesReducer.isFetching,
    brands: state.brandsReducer.brands,
    error: state.typesReducer.error
  }),
  {
    addType,
    goToRoute,
    getSubcategories,
    getCategories,
    getBrands,
    removeError
  }
)(injectIntl(AddType))
