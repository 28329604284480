export const getCountriesByCountry = state => {
  if (state.usersReducer.users.length !== 0 && state.userReducer.user) {
    const idUserLogin = state.userReducer.user.id
    const userLogin = state.usersReducer.users.find(user => idUserLogin === user.id)
    const countryUser = userLogin.region.name
    let countries = state.countriesReducer.countries
    if (userLogin.role === 'ROLE_ADMIN_REG') {
      const filterCountries = countries.filter(country => country.name === countryUser)
      countries = filterCountries
    }
    return countries
  }
  return []
}
