import { request } from '../../../../utils/request'
import { push } from 'react-router-redux'

const getSubcategoriesRequest = () => ({ type: 'SUBCATEGORIES/DATA_REQUEST' })
const getSubcategoriesSuccess = subcategories => ({
  type: 'SUBCATEGORIES/DATA_SUCCESS',
  subcategories
})
const getSubcategoriesFailure = error => ({
  type: 'SUBCATEGORIES/DATA_FAILURE',
  error
})

export const getSubcategories = () => {
  return dispatch => {
    dispatch(getSubcategoriesRequest())
    // Subcategories
    const requestOptions = {
      method: 'GET'
    }

    return request(`subcategories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getSubcategoriesFailure(res.error || res.message))
          return false
        }
        dispatch(getSubcategoriesSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getSubcategoriesFailure(err))
      })
  }
}

const deleteSubcategoryRequest = () => ({ type: 'SUBCATEGORY/DATA_REQUEST_DELETE' })
const deleteSubcategorySuccess = subcategories => ({
  type: 'SUBCATEGORY/DATA_SUCCESS_DELETE',
  subcategories
})
const deleteSubcategoryFailure = error => ({
  type: 'SUBCATEGORY/DATA_FAILURE_DELETE',
  error
})

export const deleteSubcategory = id => {
  return dispatch => {
    dispatch(deleteSubcategoryRequest())
    // Subcategory-Delete
    const body = {
      id
    }
    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    return request(`subcategories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(deleteSubcategoryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(deleteSubcategorySuccess(res))
          return true
        }
      })
      .catch(err => {
        dispatch(deleteSubcategoryFailure(err))
      })
  }
}

export const getSubcategoriesByCategoryRequest = categoryId => dispatch => {
  dispatch({ type: 'SUBCATEGORIESBYCATEGORY/DATA_REQUEST' })
  dispatch(getSubcategoriesByCategory(categoryId))
}
const getSubcategoriesByCategorySuccess = subcategories => ({
  type: 'SUBCATEGORIESBYCATEGORY/DATA_SUCCESS',
  subcategories
})
const getSubcategoriesByCategoryFailure = error => ({
  type: 'SUBCATEGORIESBYCATEGORY/DATA_FAILURE',
  error
})

export const getSubcategoriesByCategory = categoryId => {
  return dispatch => {
    // Subcategories
    const requestOptions = {
      method: 'GET'
    }

    return request(`subcategories?category_id=${categoryId}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getSubcategoriesByCategoryFailure(res.error || res.message))
          return false
        }
        dispatch(getSubcategoriesByCategorySuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getSubcategoriesByCategoryFailure(err))
      })
  }
}

const getSubcategoryByIdRequest = () => ({ type: 'SUBCATEGORY_ID/DATA_REQUEST' })
const getSubcategoryByIdSuccess = subcategory => ({
  type: 'SUBCATEGORY_ID/DATA_SUCCESS',
  subcategory
})
const getSubcategoryByIdFailure = error => ({
  type: 'SUBCATEGORY_ID/DATA_FAILURE',
  error
})

export const getSubcategoryById = id => {
  return dispatch => {
    dispatch(getSubcategoryByIdRequest())
    // Subcategory-ID
    const requestOptions = {
      method: 'GET'
    }

    return request(`subcategories/${id}`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getSubcategoryByIdFailure(res.error || res.message))
          return false
        }
        dispatch(getSubcategoryByIdSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getSubcategoryByIdFailure(err))
      })
  }
}

const editSubcategoryRequest = subcategory => ({ type: 'SUBCATEGORY/DATA_REQUEST_EDIT', subcategory })
const editSubcategorySuccess = subcategories => ({
  type: 'SUBCATEGORY/DATA_SUCCESS_EDIT',
  subcategories
})
const editSubcategoryFailure = error => ({
  type: 'SUBCATEGORY/DATA_FAILURE_EDIT',
  error
})

export const editSubcategory = subcategory => {
  return dispatch => {
    dispatch(editSubcategoryRequest(subcategory))
    // Category-Edit
    const body = {
      id: subcategory.id,
      name: subcategory.name,
      category_id: subcategory.category.id
    }
    const requestOptions = {
      method: 'PUT',
      body: JSON.stringify(body)
    }
    return request(`subcategories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(editSubcategoryFailure(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(editSubcategorySuccess(res))
          dispatch(push(`/admin/content/subareas`))
          return true
        }
      })
      .catch(err => {
        dispatch(editSubcategoryFailure(err))
      })
  }
}

const addSubcategoryRequest = subcategory => ({ type: 'SUBCATEGORY/DATA_REQUEST_ADD', subcategory })
const addSubcategorySuccess = subcategories => ({
  type: 'SUBCATEGORY/DATA_SUCCESS_ADD',
  subcategories
})
const addSubcategoryFailure = error => ({
  type: 'SUBCATEGORY/DATA_FAILURE_ADD',
  error
})

export const addSubcategory = subcategory => {
  return dispatch => {
    dispatch(addSubcategoryRequest())
    // Subcategory-Add
    const body = {
      name: subcategory.name,
      category_id: subcategory.category_id
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body)
    }
    return request(`subcategories`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(addSubcategorySuccess(res.error || res.message))
          return false
        }
        if (res.length !== 0) {
          dispatch(addSubcategorySuccess(res))
          dispatch(push(`/admin/content/subareas`))
          return true
        }
      })
      .catch(err => {
        dispatch(addSubcategoryFailure(err))
      })
  }
}

export const removeError = () => ({
  type: 'SUBCATEGORY/REMOVE_ERROR'
})
