import styled from 'styled-components'
import MUIDataTable from 'mui-datatables'
import CircularProgress from '@material-ui/core/CircularProgress'

const minWidth = ({ minWidth }) => {
  if (minWidth) {
    return `min-width: ${minWidth}`
  }
}

export const Container = styled.div`
  .react-bootstrap-table {
  }
  .table {
    border: 2px solid blue;
    :hover {
      background: none;
    }
  }
`
export const TableStyled = styled(MUIDataTable)`
  table {
    ${minWidth}
    word-wrap: break-word;
  }
`

export const Spinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.primaryColor};
`

export const Warnning = styled.p`
  color: ${({ theme }) => theme.colors.colorTekaRed};
`
