import Button from '../../../button'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Flex, Box } from 'reflexbox'

export const ButtonStyled = styled(Button)`
  && {
  }
`
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled(FlexBetween)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`
export const CheckboxStyled = styled(Checkbox)``

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const Header = styled(FlexBetween)``

export const TitleHeader = styled.span`
  font-weight: bold;
  font-size: 17px;
`
export const RowFile = styled(FlexBetween)`
  border-bottom: 1px dotted #ddd;
  height: 40px;
  align-items: center;
`
export const CellFile = styled.div`
  margin: 0 5px;
  max-width: 300px;
  height: 25px;
`
export const Ellipsis = styled.div`
  margin: 0 5px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const ContainerFiles = styled.div`
  display: grid;
  word-wrap: break-word;
  grid-template-columns: auto auto auto auto auto auto;
`

export const ItemFiles = styled.div`
  display: grid;
  justify-content: ${({ end }) => (end ? 'end' : 'left')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  align-items: center;
  font-size: 14px;
  width: 100%;
`

export const LinearProg = styled(LinearProgress)``

export const WrapperLinear = styled(Box)`
  width: 100%;
  height: 10px;
`

export const WrapperProgress = styled(Flex)`
  background: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 5px;
`
