const initialState = {
  isFetching: false,
  types: [],
  typec: null
}

export const Types = (state = initialState, action) => {
  switch (action.type) {
    // request
    case 'TYPES/DATA_REQUEST':
    case 'TYPE/DATA_REQUEST_DELETE':
    case 'TYPE_ID/DATA_REQUEST':
    case 'TYPE/DATA_REQUEST_EDIT':
    case 'TYPE/DATA_REQUEST_ADD':
      return {
        ...state,
        isFetching: true
      }

    // failure
    case 'TYPES/DATA_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
        types: []
      }

    case 'TYPE_ID/DATA_FAILURE':
    case 'TYPE/DATA_FAILURE_EDIT':
    case 'TYPE/DATA_FAILURE_ADD': {
      return {
        ...state,
        isFetching: false,
        typec: null,
        error: action.error
      }
    }

    case 'TYPE/DATA_FAILURE_DELETE': {
      return {
        ...state,
        isFetching: false,
        typec: null,
        errorModal: action.error
      }
    }

    // success
    case 'TYPE_ID/DATA_SUCCESS':
      return {
        ...state,
        isFetching: false,
        typec: action.typec,
        error: null
      }
    case 'TYPES/DATA_SUCCESS':
    case 'TYPE/DATA_SUCCESS_DELETE':
    case 'TYPE/DATA_SUCCESS_EDIT':
    case 'TYPE/DATA_SUCCESS_ADD':
      return {
        ...state,
        isFetching: false,
        types: action.types,
        error: null
      }
    case 'TYPE/REMOVE_ERROR': {
      return {
        ...state,
        error: null
      }
    }
    default:
      return state
  }
}
