import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import Button from '../../../../../components/button'
import BackButton from '../../../../../components/back-button'
import { FormBoxInput, FormBoxSelect } from '../../../../../components/form'
import { Spinner } from '../../../../../components/spinner'
import ErrorSpan from '../../../../../components/errorSpan'
import getError from '../../../../../utils/errors'
import {
  getCategoryById,
  editCategory,
  removeError
} from '../../../../../modules/admin/content/categories'
import { getBrands } from '../../../../../modules/admin/brands'
import { goToRoute } from '../../../../../modules/navigation'

import { Form, Title, WrapperButton } from './styles'

class EditCategory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      lang: 'es',
      category: null,
      isSubmit: false
    }
    this.changeName = this.changeName.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.chekedFiles = this.chekedFiles.bind(this)
  }

  getCategoryId () {
    const { match } = this.props
    return match.params.id
  }

  componentDidMount () {
    this.props.getCategoryById(this.getCategoryId())
    this.props.getBrands()
  }

  componentDidUpdate () {
    const { isFetching, category } = this.props
    if (!this.state.category && category && !isFetching) {
      this.setState({ category })
    }
    this.props.removeError()
  }

  changeName (e) {
    const { category } = this.state
    category.name = e.target.value
    this.setState({
      category
    })
  }

  changeSelect (e, value) {
    const { category } = this.state
    category.brand = { id: value }
    this.setState({
      category
    })
  }

  chekedFiles () {
    let validate = false
    if (this.state.category.name && this.state.category.brand.id) {
      validate = true
    }
    return validate
  }

  onSubmit (e) {
    e.preventDefault()
    this.setState({ isSubmit: true })
    if (this.chekedFiles()) {
      this.props.editCategory(this.state.category)
    }
  }

  getOptions () {
    if (this.props.brands) {
      const newOptions = []
      newOptions.push({
        value: 0,
        label: '...select'
      })
      this.props.brands.forEach(brand => {
        newOptions.push({
          value: brand.id,
          label: brand.name
        })
      })
      return newOptions
    }
  }

  render () {
    const { category, isSubmit } = this.state
    const { intl, isFetching, error } = this.props
    if (isFetching) {
      return <Spinner />
    }
    if (category !== null) {
      return (
        <Form onSubmit={this.onSubmit}>
          <Flex column style={{ textAlign: 'center' }}>
            <Box mb={2} w={1}>
              <Title>
                <FormattedMessage id={'admin.area.edit'} />
              </Title>
            </Box>
            {error ? (
              <ErrorSpan>
                {intl.formatMessage({ id: getError(error) })}
              </ErrorSpan>
            ) : (
              ''
            )}
            <WrapperButton>
              <BackButton
                to='/admin/content/areas'
                text={intl.formatMessage({ id: 'admin.back' })}
              />
            </WrapperButton>
            {/* <Box mb={4} w={1}>
              <Subtitle>[id: {category.id}]</Subtitle>
            </Box> */}
            <Flex justify='center' style={{ textAlign: 'left' }}>
              <Flex column w={1} mr={3} style={{ maxWidth: '600px' }}>
                <Box mb={3} w={1}>
                  <FormBoxInput
                    label='Name *'
                    defaultValue={category.name}
                    onChange={this.changeName}
                    placeholder='Ex. Logos'
                    type='text'
                    error={!category.name && isSubmit}
                    helperText={
                      !category.name && isSubmit
                        ? 'El campo es obligatorio'
                        : ''
                    }
                  />
                </Box>
                <Box mb={3}>
                  <FormBoxSelect
                    options={this.getOptions()}
                    defaultValue={category.brand.id}
                    onChange={this.changeSelect}
                    required
                    label='Brand *'
                    error={!category.brand.id && isSubmit}
                  />
                </Box>
                <Flex justify='space-between'>
                  <Button
                    secundary
                    onClick={() => this.props.goToRoute(`/admin/content/areas`)}
                    padding='5px 40px'
                  >
                    <FormattedMessage id={'admin.cancel'} />
                  </Button>
                  <Button primary='true' type='submit' padding='5px 40px'>
                    <FormattedMessage id={'admin.submit'} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      )
    }
    return <p>No hay area seleccionada</p>
  }
}

export default connect(
  state => ({
    brands: state.brandsReducer.brands,
    category: state.categoriesReducer.category,
    isFetching: state.categoriesReducer.isFetching,
    error: state.categoriesReducer.error
  }),
  { getCategoryById, editCategory, goToRoute, getBrands, removeError }
)(injectIntl(EditCategory))
