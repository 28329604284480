import { request } from '../../../utils/request'

const getDashboardRequest = () => ({ type: 'DASHBOARD/DATA_REQUEST' })
const getDashboardSuccess = dashboard => ({
  type: 'DASHBOARD/DATA_SUCCESS',
  dashboard
})
const getDashboardFailure = error => ({
  type: 'DASHBOARD/DATA_FAILURE',
  error
})

export const getDashboard = () => {
  return dispatch => {
    dispatch(getDashboardRequest())
    // Dashboard
    const requestOptions = {
      method: 'GET'
    }

    return request(`notifications/dashboard`, requestOptions)
      .then(res => {
        if (res.error || res.code === 401) {
          dispatch(getDashboardFailure(res.error))
          return false
        }
        dispatch(getDashboardSuccess(res))
        return true
      })
      .catch(err => {
        dispatch(getDashboardFailure(err))
      })
  }
}
