const languages = [
  {
    value: 1,
    label: 'Spanish'
  },
  {
    value: 2,
    label: 'English'
  },
  {
    value: 3,
    label: 'French'
  },
  {
    value: 4,
    label: 'German'
  },
  {
    value: 5,
    label: 'Italian'
  },
  {
    value: 6,
    label: 'Portuguese'
  },
  {
    value: 7,
    label: 'Turkish'
  },
  {
    value: 8,
    label: 'Greek'
  },
  {
    value: 9,
    label: 'Hungarian'
  },
  {
    value: 10,
    label: 'Polish'
  },
  {
    value: 11,
    label: 'Russian'
  },
  {
    value: 12,
    label: 'Thai'
  },
  {
    value: 13,
    label: 'Others'
  },
  {
    value: 14,
    label: 'Bulgarian'
  }
]

export default languages
