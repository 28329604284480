import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Button from '../../components/button'
import ErrorSpan from '../../components/errorSpan'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
`

export const TextFieldStyled = styled(TextField)``

export const ButtonStyled = styled(Button)`
  && {
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryColor};
    :hover {
      background-color: ${({ theme }) => theme.colors.primaryColor};
    }
    text-transform: none;
    width: 200px;
    margin-bottom: 30px;
  }
`
export const ErrorSpanStyled = styled(ErrorSpan)`
  margin: 5px 0;
`
export const Span = styled.span``

export const SpanLink = styled.span`
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`

export const OKSpan = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin: 5px 0;
  font-weight: bold;
`
